import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NotFoundPage from "../pages/notFoundPage/NotFoundPage";
import Login from "../pages/login/Login";
import Home from "../pages/home/Home";
import ProcessFile from "../pages/processFile/ProcessFile";
import ViewProcess from "../pages/view_Process/ViewProcess";
import LinkCollectionToFile from "../pages/linkCollectionToFile/LinkCollectionToFile";
import Validation from "../pages/validationPage/Validation";
import ProcessCollection from "../pages/processCollection/ProcessCollection";
import InputFiles from "../pages/inputFiles/InputFiles";
import SuperAdmin from "../pages/super_admin/SuperAdmin";
import MiddlewareLogin from "../common/middlewares/MiddlewareLogin";
import InputStrategy from "../pages/input_Strategy/InputStrategy";
import CriteriaStrategy from "../pages/criteria_Strategy/CriteriaStrategy";
import ChannelSelector from "../pages/channelSelector/channelSelector";
import ChartsView from "../pages/charts/Charts";
import Reports from "../pages/reports/Reports";
import { ReactFlowTest } from "../pages/reactFlowTest/reactFlowTest";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles"; 
const theme = createTheme();

export const routes = (
  <ThemeProvider theme={theme}>
    <BrowserRouter basename="/collections">
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route element={<Home />} path="/home" exact />
        <Route element={<InputFiles />} path="/inputfile/:uid" exact />
        <Route element={<InputStrategy />} path="/inputStrategy/" exact />
        <Route element={<CriteriaStrategy />} path="/criteriaStrategy/" exact />
        <Route element={<LinkCollectionToFile />} path="/link/:uid" exact />
        <Route element={<ChannelSelector />} path="/channels/:uid" exact />
        <Route element={<SuperAdmin />} path="/super-admin" exact />
        <Route element={<Validation />} path="/validation/:uid" exact />
        <Route element={<ViewProcess />} path="/view/:uid" exact />
        <Route element={<ProcessCollection />} path="/process/:uid" exact />
        <Route element={<ProcessFile />} path="/processfile/:uid" exact />
        <Route element={<ChartsView />} path="/charts/:uid" exact />
        <Route element={<Reports />} path="/reports/:uid" exact />
        <Route element={<NotFoundPage />} path="/*" exact />
        <Route element={<MiddlewareLogin />} path="/auth-session" exact />
        <Route element={<Login />} path="/login" exact />
        <Route element={<Login />} path="/logout" exact />
        <Route element={<ReactFlowTest />} path="/reactFlowTest" exact />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);
