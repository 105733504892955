import { useEffect } from "react";
import { useNavigate } from "react-router";
import "../../pages/login/Login.css";
import { _get } from "../generalRequests";
import Loader from "../../common/tools/loader/Loader";
import Cookies from "js-cookie";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { ClearSesion } from "../helpers/ClearSesion";

export default function MiddlewareLogin() {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getTokenFromCookie();
  });

  const getTokenFromCookie = () => {
    if (Cookies.get("XSRF-TOKEN")  && isAuthenticated) {
      validateToken().then((result) => {
        if (result) {
          navigate("/home");
        } else {
          ClearSesion();
        }
      });
    } else {
      ClearSesion();
    }
  };

  const validateToken = async () => {
    let result;
    await _get(
      `/validatetoken`,
      (response) => {
        result = response.data.ok;
      },
      () => {
        result = false;
      }
    );
    return result;
  };

  return (
    <div>
      <Loader />
    </div>
  );
}
