import { useState,useEffect} from "react";
import TextField from "@mui/material/TextField";
import "./scriptMenu.css";
import { _post,_get } from "../../../../common/generalRequests";
import swal from "sweetalert";

function ScriptMenu({ modelMenu, width, setIsLoading }) {
  const [file, setFile] = useState(null);
  const [allScripts, setAllScripts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toggleUpload, setToggleUpload] = useState(false);
  const accountName = process.env.REACT_APP_ACCOUNT_NAME_SCRIPT_STORAGE;
  const containerName = process.env.REACT_APP_CONTAINER_NAME_SCRIPT_STORAGE;

  useEffect(() => {
    _get(
      `/scripts/${accountName}/${containerName}`,
      (res) => {
        if (res.data.ok) {
          setAllScripts(res.data.scripts);
          setLoading(false);
        }
      },
      (error) => {
        swal("Error", `${error}`, "error");
        setLoading(false);
      }
    );
  }, [toggleUpload]);

  const handleFileUpload = async (event) => {
    if (event) {
      setFile(event.target.files[0]);
    }
  };

  const uploadOneFile = async () => {
    setIsLoading(true);
    const [accountName, containerName] = [
      process.env.REACT_APP_ACCOUNT_NAME_SCRIPT_STORAGE,
      process.env.REACT_APP_CONTAINER_NAME_SCRIPT_STORAGE,
    ];

    if (file && file.name.slice(-3) == ".py") {
      const formData = new FormData();
      formData.append("accountName", accountName);
      formData.append("containerName", containerName);
      formData.append("modelVersion", 0);
      formData.append("file", file);
      _post(
        "/scripts/",
        formData,
        (res) => {
          if (res.data.ok) {
            setIsLoading(false);
            setToggleUpload(!toggleUpload);
          } else {
            swal("Error", `${res.data.error}`, "error");
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
          swal("Error", `${error}`, "error");
        }
      );
    } else {
      swal("Warning", "You must upload a .py file", "warning");
      setIsLoading(false);
    }
  };

  return modelMenu == "scripts" ? (
    <>
      
      <div className="formContainerScript">
      <p className="panelTitle">Upload Model Script</p>
        <TextField
          className="inputFile"
          name="file"
          margin="dense"
          id="file"
          type="file"
          variant="outlined"
          inputProps={{
            accept: ".py,text/x-python",
          }}
          onChange={(event) => {
            handleFileUpload(event);
          }}
        />

        <p className="panelTitle">List of Scripts</p>
        <table className="columsTable">
          <thead>
            <tr className="tableHeader">
              <td>Scripts</td>
            </tr>
          </thead>
          <tbody>
            {allScripts
              ? allScripts.map((script, index) => (
                  <tr key={index}>
                    <td>{script}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>

        <button
          className="uploadScriptButton"
          onClick={() => {
            uploadOneFile();
          }}
        >
          Upload Script
        </button>
      </div>
    </>
  ) : null;
}

export default ScriptMenu;
