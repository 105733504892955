import React from "react";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import "./headerBox.css";

function ChipTemplate({ column, remove, getLabel,value,classStyle }) {
  return (
    <div className="chipContainerRow">
      <Tooltip title={getLabel(value)} placement="bottom">
        <Chip
          className={classStyle}
          label={getLabel(value)}
          onDelete={() => remove(column.id)}
        />
      </Tooltip>
    </div>
  );
}

export default ChipTemplate;
