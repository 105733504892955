import { useState, useReducer } from "react";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import deletePurple from "../../../assets/svg/DeletePurple.svg";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";
import "./modalAddExcluding.css";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddExcluding = ({
  open,
  handleClose,
  inputHeaders,
  addExcludingHeader,
  excludingColumn,
}) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [headers, setHeaders] = useState([]);
  const [headerSelected, setHeaderSelected] = useState([]);
  const [fileName, setFileName] = useState("");
  const [options, setOptions] = useState([]);

  const getHeadersByFile = (value) => {
    if (value) {
      setFileName(value.name);
      setHeaders(value.headersByFile);
    } else {
      setHeaderSelected([]);
      setHeaders([]);
    }
  };

  const handleChangeHeader = (value) => {
    if (value) {
      setHeaderSelected(value);
    }
  };

  const handleFilterHeaderByOption = (value, index) => {
    let newOptions = options;
    if (value) {
      newOptions[index].file = value.name;
      newOptions[index].headersByFile = value.headersByFile;
    } else {
      newOptions[index].file = null;
      newOptions[index].headersByFile = [];
    }
    setOptions(newOptions);
    forceUpdate();
  };

  const handleChangeOptionHeader = (value, index) => {
    let newOptions = options;
    if (value) {
      newOptions[index].Header = value.Header;
    } else {
      newOptions[index].Header = null;
    }
    setOptions(newOptions);
  };

  const handleAddHeader = () => {
    if (headerSelected !== [] && options.length > 0) {
      let addHeader = true;
      options.forEach((option) => {
        if (!option.Header) addHeader = false;
      });
      if (addHeader) {
        let headerWithFileName = headerSelected;
        headerWithFileName["file"] = fileName;
        let headerToSave = options;
        headerToSave.unshift(headerWithFileName);
        addExcludingHeader(excludingColumn.id, headerToSave);
        setHeaders([]);
        setHeaderSelected([]);
        setFileName("");
        setOptions([]);
      } else {
        swal("Warning", "Please fill in all fields", "warning");
      }
    }
  };

  const handleAddExcluding = () => {
    let newOptions = options;
    newOptions.push({ file: null, Header: null, headersByFile: [] });
    setOptions(newOptions);
    forceUpdate();
  };

  const deleteOption = (index) => {
    let newOptions = options;
    newOptions.splice(index, 1);
    setOptions(newOptions);
    forceUpdate();
  };

  const getElseIfs = (option, index) => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControl required={true}>
            <Autocomplete
              className="inputAddExcluding"
              onChange={(event, newValue) => {
                handleFilterHeaderByOption(newValue, index);
              }}
              getOptionLabel={(option) => option.name}
              options={inputHeaders}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Input File"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Autocomplete
              className="inputAddExcluding"
              onChange={(event, newValue) => {
                handleChangeOptionHeader(newValue, index);
              }}
              getOptionLabel={(option) => option.Header}
              options={option.headersByFile}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Input column"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <Tooltip title="Delete" placement="bottom-start">
            <button
              className="buttondeleteExcluding"
              onClick={() => {
                deleteOption(index);
              }}
            >
              <img src={deletePurple} alt="" />
            </button>
          </Tooltip>
        </div>
        <div className="subtitleCondition">Else</div>
      </div>
    );
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      className="modalAddExcluding"
    >
      <div className="paperAddExcluding">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            handleClose();
          }}
          className="closeIcon"
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="titleModalAddHeader">Add Exclusion</div>
          <div className="subtitleModal">
            Select the first input to evaluate
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControl required={true} className="formControlModal">
              <Autocomplete
                className="selectModalAddHeader"
                onChange={(event, newValue) => {
                  getHeadersByFile(newValue);
                }}
                getOptionLabel={(option) => option.name}
                options={inputHeaders}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Input File"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControlModal">
              <Autocomplete
                className="selectModalAddHeader"
                onChange={(event, newValue) => {
                  handleChangeHeader(newValue);
                }}
                getOptionLabel={(option) => option.Header}
                options={headers}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Input Column"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div className="subtitleModalContent">
            If the above input does not exist then:
            </div>
            <button
              className="addInputExcluding"
              onClick={() => {
                handleAddExcluding();
              }}
            >
              Add Input
            </button>
          </div>
          <div className="replaceInputContainer">
            {options.map((option, index) => getElseIfs(option, index))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
            marginBottom: "10px",
          }}
        >
          <button
            className="buttonModal"
            onClick={() => {
              handleAddHeader();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddExcluding;
