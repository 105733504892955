import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { _get } from "../../../common/generalRequests";
import swal from "sweetalert";
import Modal from "@mui/material/Modal";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import "./modalAddCustomColumn.css";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
    margin: "0px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
      margin: "0px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddCustomColumn = ({ open, handleClose, addCustomColumn,updateCustomColumnsList }) => {
  const [columnName, setColumnName] = useState("");
  let { uid } = useParams();

  const verifyClient = async (idProc) => {
    if (idProc) {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            updateCustomColumnsList()
            return true;

          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            ).then(() => {});
            return false;
          }
        },
        (_error) =>
          swal("error", "Client not authorized to perform this action", "error")
      ).then(() => {});
    }
  };

  const addNewColumnFromModal = () => {
    //Todo contar custom columns
    if (verifyClient(uid)) {
      if (columnName) {
        addCustomColumn(columnName);
        handleClose();
      }
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <div className="paperModalAddCalculation">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            handleClose();
          }}
          className="closeIcon"
        />
        <div className="titleModal">Add new column</div>

        <div className="formContainerCustom">
          <label className="labelModal">Column Name</label>
          <FormControl required={true} className="formControlModal">
            <CssTextField
              className="selectModel"
              onChange={(event) => {
                setColumnName(event.target.value);
              }}
              variant="outlined"
            />
          </FormControl>
        </div>

        <div className="containerButtonAdd">
          <button
            className="buttonModal"
            onClick={() => addNewColumnFromModal()}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCustomColumn;
