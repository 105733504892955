import { useState, useEffect } from "react";
import { _get, _post, _put } from "../../../../common/generalRequests";
import swal from "sweetalert";
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../assets/svg/CancelarGris.svg";
import "./modalTemplate.css";
import { Checkbox } from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const ModalTemplate = ({
  open,
  close,
  edit,
  template,
  process,
  idChannel,
  setLoading,
}) => {
  let [message, setMessage] = useState("");
  let [templateName, setTemplateName] = useState("");
  const [doubleWay, setDoubleWay] = useState(false);
  const [state, setState] = useState(true);
  const [variables, setVariables] = useState([]);
  const [phone, setPhone] = useState("57");

  useEffect(() => {
    if (process.uid) {
      _get(`/helpViewTemplate/${process.uid}`, (res) => {
        setVariables(res.data.columns);
      });
    }
  }, [process.uid]);

  useEffect(() => {
    if (Object.keys(template).length > 0) {
      setDoubleWay(template.doubleWay);
      setMessage(template.message);
      setTemplateName(template.templateName);
      setState(template.state);
      setPhone(template.phoneCode);
    }
  }, [template]);

  const requestTemplate = () => {
    setLoading(true);
    const data = {
      message,
      templateName,
      uidProcess: process.uid,
      idChannel,
      state: state.toString(),
      doubleWay: doubleWay.toString(),
      phoneCode: phone,
    };
    if (edit) {
      editTemplate(data);
    } else {
      createTemplate(data);
    }
  };

  const createTemplate = (data) => {
    _post(
      "/template",
      data,
      (res) => {
        if (res.data.ok) clearInputs();
      },
      () =>
        swal("Error", "An Error has occurred", "error").then(() =>
          clearInputs()
        )
    );
  };

  const editTemplate = (data) => {
    _put(
      `/template/${template.id}`,
      data,
      (res) => {
        if (res.data.ok) clearInputs();
      },
      () =>
        swal("Error", "An Error has occurred on edit", "error").then(() =>
          clearInputs()
        )
    );
  };

  const clearInputs = () => {
    setMessage("");
    setTemplateName("");
    setDoubleWay(false);
    setState(true);
    setPhone("57");
    close();
  };

  return (
    <Modal open={open} onClose={close} className="modal">
      <div className="paperModalTemplate">
        <img src={closeIcon} alt="close" onClick={() => clearInputs()} />
        <div className="titleModal">
          {edit ? "Edit template" : "New template"}
        </div>
        <div className="columnsModal">
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className="labelModal">Name</label>
            <span className="descriptionInputModal">
              This name must be clear and precise to avoid confusion.
            </span>
            <input
              value={templateName}
              className="inputModal"
              onChange={(event) => {
                setTemplateName(event.target.value);
                let inputPhone = document.querySelector(".form-control");
                inputPhone.setAttribute("readOnly", "");
              }}
            />
            <label className="labelModal">Code</label>
            <span className="descriptionInputModal">
              Choose the country code of the country from which the customers
              are from
            </span>

            <PhoneInput
              country={"eg"}
              enableSearch={true}
              value={phone}
              onChange={(event) => {
                setPhone(event);
              }}
              className="InputPhone"
              countryCodeEditable={false}
            />

            <label className="labelModal">Message</label>
            <span className="descriptionInputModal">
              Message configured for channel sending
            </span>
            <textarea
              value={message}
              className="inputMessage"
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
            <span className="descriptionInputModal">
              If you exceed 160 characters you will be charged for additional
              messages.
            </span>
            <div className="row">
              <div className="checkTemplates">
                <Checkbox
                  checked={doubleWay}
                  onClick={() => setDoubleWay(!doubleWay)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div className="doubleWay">
                  <label className="labelModal">Double way</label>
                  <span className="descriptionInputModal">
                  Select if it has double way or not
                  </span>
                </div>

                <Checkbox
                  className="marginLeft30"
                  checked={state}
                  onClick={() => setState(!state)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <div className="doubleWay">
                  <label className="labelModal">State</label>
                  <span className="descriptionInputModal">
                  Selects whether active or inactive
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <label className="labelModal">Variables</label>
            <div className="containerVariables">
              {variables.map((column, index) => (
                <button
                  className="buttonVariables"
                  onClick={() =>
                    setMessage(message.concat(` {{${column.columnName}}} `))
                  }
                  key={index}
                >
                  {column.description}
                </button>
              ))}
            </div>
          </div>
        </div>

        <button className="buttonModal" onClick={() => requestTemplate()}>
          {edit ? "Edit" : "Create"}
        </button>
      </div>
    </Modal>
  );
};

export default ModalTemplate;
