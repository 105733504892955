import { useEffect, useState } from "react";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import "./modalAddHeader.css";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddHeader = ({
  open,
  handleClose,
  inputHeaders,
  addHeaderToColumn,
}) => {
  const [headers, setHeaders] = useState([]);
  const [headerSelected, setHeaderSelected] = useState(null);
  const [fileName, setFileName] = useState("");

  const getHeadersByFile = (value) => {
    if (value) {
      setFileName(value.name);
      setHeaders(value.headersByFile);
      setHeaderSelected(null);
    } else {
      setHeaderSelected(null);
      setHeaders([]);
    }
  };

  const handleChangeHeader = (value) => {
    if (value) {
      setHeaderSelected(value);
    }
  };

  const handleAddHeader = () => {
    if (headerSelected) {
      let headerWithFileName = headerSelected;
      headerWithFileName["file"] = fileName;
      addHeaderToColumn(headerWithFileName);
      setHeaderSelected(null);
      setFileName("");
    }
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      className="modalAddHeader"
    >
      <div className="paperModalAddHeader">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            handleClose();
          }}
          className="closeIcon"
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="titleModalAddHeader">Match headers</div>
          <div className="subtitleModal">Select the inputs you want to add</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <label className="labelModal">Input File</label>
          <span className="descriptionInputModal">Select the input file</span>
          <FormControl required={true} className="formControlModal">
            <Autocomplete
              className="selectModel"
              onChange={(event, newValue) => {
                getHeadersByFile(newValue);
              }}
              getOptionLabel={(option) => option.name}
              options={inputHeaders}
              renderInput={(params) => (
                <CssTextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
          <label className="labelModal">Input Column</label>
          <span className="descriptionInputModal">Select the Input Column</span>
          <FormControl className="formControlModal">
            <Autocomplete
              className="selectModel"
              onChange={(event, newValue) => {
                handleChangeHeader(newValue);
              }}
              getOptionLabel={(option) => option.Header}
              options={headers}
              value={headerSelected}
              renderInput={(params) => (
                <CssTextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
            marginBottom: "10px",
          }}
        >
          <button
            className="buttonModal"
            onClick={() => {
              handleAddHeader();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddHeader;
