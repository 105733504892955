import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./setup/authConfig";
import { ClearSesion } from "./common/helpers/ClearSesion";

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response.status === 401) ClearSesion();
    return Promise.reject(error);
  }
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  //document.getElementById("root")
);
