import { useNavigate } from "react-router";
import tpLogo from "../../../assets/brand/TPRecommenderLogo.webp";
import userIcon from "../../../assets/images/user.png";
import homeIcon from "../../../assets/images/home.png";
import logOutIcon from "../../../assets/svg/Logout.svg";
import Tooltip from "@mui/material/Tooltip";
import { logOut, decodeUser } from "../../generalRequests";
import { useMsal } from "@azure/msal-react";
import "./MainBar.css";

function MainBar(props) {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const user = decodeUser(localStorage.getItem("USER"));

  const rednerButtonSuperAdmin = () => {
    let user = decodeUser(localStorage.getItem("USER"));
    if (user.rolUser === 0 || user.rolUser === 1) {
      if (window.location.pathname === "/collections/super-admin") {
        return (
          <Tooltip title="Home" placement="bottom-start">
            <button
              className="mainBarButtons"
              onClick={() => {
                navigate("/home");
              }}
            >
              <img src={homeIcon} alt="Home" className="userIcon" />
            </button>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Modify permissions" placement="bottom-start">
            <button
              className="mainBarButtons"
              onClick={() => {
                navigate("/super-admin");
              }}
            >
              <img
                src={userIcon}
                alt="modificar permisos"
                className="userIcon"
              />
            </button>
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip title="Home" placement="bottom-start">
          <button
            className="mainBarButtons"
            onClick={() => {
              navigate("/home");
            }}
          >
            <img src={homeIcon} alt="Home" className="userIcon" />
          </button>
        </Tooltip>
      );
    }
  };

  return (
    <div className="main_bar">
      <div className="item1">
        <img
          className="tpLogoMainBar"
          src={tpLogo}
          alt="TPLogo"
          onClick={() => navigate("/home")}
        />
      </div>
      <div className="itemCenter">
        <span className="clientNameProgressBar">
          {props.clientName ? (
            <>
              <span className="clientsTitle">Client: </span> {props.clientName}
            </>
          ) : (
            ""
          )}
        </span>
      </div>
      <div className="item2">
        <span>
          Hello, <span className="userNameLabel">{user.firstName}</span>
        </span>
        <div>{rednerButtonSuperAdmin()}</div>

        <Tooltip title="Logout" placement="bottom-start">
          <button
            className="mainBarButtons"
            onClick={() => logOut(instance, accounts)}
          >
            <img src={logOutIcon} className="logOutIcon" alt="logOutIcon" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

export default MainBar;
