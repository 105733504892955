import { useEffect, useState } from "react";
import {
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import newProcessIcon from "../../assets/svg/NuevoProyectoIcono.svg";
import resultsActive from "../../assets/svg/ResultsActivo.svg";
import deletePurple from "../../assets/svg/DeletePurple.svg";
import { useNavigate, useLocation } from "react-router";
import { _get, _put, decodeUser } from "../../common/generalRequests";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import MainBar from "../../common/components/mainBar/MainBar";
import PostAddIcon from "@mui/icons-material/PostAdd";
import swal from "sweetalert";
import activeModelIcon from "../../assets/images/activeModelIcon.png";
import ModalCreateCollection from "./components/modalCreateCollection/ModalCreateCollection";
import ModalCreateStrategy from "./components/modalCreateStrategy/ModalCreateStrategy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../../common/components/searchBar/SearchBar";
import Cookies from "js-cookie";
import "./Home.css";
import RenderLoader from "../../common/helpers/RenderLoader";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import DescriptionIcon from "@mui/icons-material/Description";
import ModalReport from "./components/modalReport/ModalReport";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {
    color: "#000",
    fontFamily: "SancoaleSoftened-Regular",
    fontSize: "19px",
    marginLeft: "1px",
  },
  "& label.Mui-focused": {
    color: "#780095",
  },
  "& placeholder": {
    color: "#000 !important",
    fontSize: "50px !important",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#575756",
    },
  },
}));

function Home() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [clientModelList, setClientModelList] = useState([]);
  const [process, setProcess] = useState([]);
  const [allProcess, setAllProcess] = useState([]);
  const [clientProcess, setClientProcess] = useState([]);
  const [openModalCreateCollection, setOpenModalCreateCollection] =
    useState(false);
  const [openModalCreateStrategy, setOpenModalCreateStrategy] = useState(false);
  const [idSelected, setIdSelected] = useState(null);
  const [tableSelected, setTableSelected] = useState("proyectos");
  const [openModalReport, setOpenModalReport] = useState(false);
  const [clientToReport, setClientToReport] = useState(null);

  let user = decodeUser(localStorage.getItem("USER"));

  useEffect(() => {
    if (!Cookies.get("XSRF-TOKEN")) navigate("/auth-session");
    getDataProcess(user.uid);
  }, [navigate]);

  useEffect(() => {
    _get(`/clients/${user.uid}`, (res) => {
      if (res.data.ok) {
        setClients(res.data.clients);
      }
    });

    if (state) {
      setTableSelected(state.activePanel);
    }
  }, []);

  useEffect(() => {
    if (client !== "" && client !== null && client) {
      setLoading(true);

      setClientProcess(
        allProcess.filter((proc) => proc.idClient.includes(client.id))
      );
      setProcess(
        allProcess.filter((proc) => proc.idClient.includes(client.id))
      );

      _get(`/modelsClients/byclient/${client.id}`, (res) => {
        setModelList(res.data.models);
        setLoading(false);
      });
    } else {
      setClientProcess([]);
      setProcess([]);
      setModelList([]);
    }
  }, [client]);

  useEffect(() => {
    getFormHome();
  }, [tableSelected]);

  const getDataProcess = (idUser) => {
    _get(
      `/processbyUser/${idUser}`,
      (res) => {
        if (res.data.ok) {
          setAllProcess(res.data.process);
        }
        setLoading(false);
      },
      () => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const openCloseModal = (uid) => {
    if (!client && !uid) {
      swal("Select a Client please", "Select a client Please", "warning");
    } else {
      setIdSelected(uid);
      setOpenModalCreateCollection(true);
    }
  };

  const openModalStrategy = async (uid) => {
    setIdSelected(await uid);
    setOpenModalCreateStrategy(true);
  };

  const verifyClient = async (nav, idProc) => {
    if (nav) {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            navigate(nav);
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            );
          }
        },
        () =>
          swal("error", "Client not authorized to perform this action", "error")
      );
      setLoading(false);
    } else {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            return true;
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            );
            return false;
          }
        },
        () =>
          swal("error", "Client not authorized to perform this action", "error")
      );
    }
  };

  const verifyStrategy = async (proc) => {
    if (proc.headers == "[]" || proc.headers == null) {
      swal("Warning", "Please Upload file", "warning").then(() =>
        navigate("/home")
      );
    } else if (
      proc.interactions == "[]" ||
      proc.interactions == null ||
      JSON.parse(proc.interactions).interactions.length == 0
    ) {
      swal("Warning", "Please Create Interactions", "warning").then(() =>
        navigate(0)
      );
    } else {
      setLoading(true);
      await _get(
        `/strategyByIdProcess/${proc.uid}`,
        (res) => {
          setLoading(false);
          if (res.data.strategyP[0].name) {
            navigate(`/inputStrategy/`, {
              state: {
                linkedColumns: proc.linkedColumns,
                strategy: res.data.strategyP[0],
              },
            });
          } else {
            openModalStrategy(proc.uid);
          }
        },

        () => openModalStrategy(proc.uid)
      );
    }
  };

  const handleClickdeleteItem = async (item) => {
    if (verifyClient(null, item.uid)) {
      swal({
        title: `Are you sure you want to delete '${item.processName}' ?`,
        text: "Once deleted, it will not be possible to recover it!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          _put(
            `/editProcess/${item.uid}`,
            { active: 0 },
            (res) => {
              if (res.data.ok) {
                swal(`The process has been deleted!`, { icon: "success" }).then(
                  () => navigate(0)
                );
              } else {
                swal("Error", "Cannot delete project", "error").then(() =>
                  navigate(0)
                );
              }
            },
            () =>
              swal("Error", "An Error has occurred", "error").then(() =>
                navigate(0)
              )
          );
        }
      });
    } else {
      swal("error", "Client not authorized to perform this action", "error");
    }
  };

  const deleteModel = (model, index) => {
    let modelListTemp = modelList;

    let data = {
      id: model.id,
      active: model.active ? 0 : 1,
    };

    _put(
      "/models/",
      data,
      (res) => {
        if (res.data.ok) {
          navigate("/home", {
            state: {
              panelActive: "modelos",
            },
          });
        } else {
          swal("Error", `${res.data.error}`, "error");
        }
      },
      (error) => {
        swal("Error", `${error}`, "error");
      }
    );

    modelListTemp[index].active = model.active ? 0 : 1;
    setModelList(modelListTemp);
  };

  const filterProcess = (value) => {
    if (value !== "") {
      setProcess(
        clientProcess.filter((proc) =>
          proc.processName.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setProcess(clientProcess);
    }
  };

  const renderModelButtons = (model, index) => {
    if (user.rolUser !== 2 && tableSelected == "modelos" && !model.modelBase) {
      return (
        <>
          <Tooltip title={"Edit" + model.modelName} placement="bottom-start">
            <IconButton
              onClick={() => {
                navigate(`/super-admin`, {
                  state: {
                    modelToEdit: model,
                    dbModelID: modelList.find(
                      (models) => models.tableName == model.tableName
                    ).id,
                  },
                });
              }}
              className="buttonsHome"
            >
              <EditIcon className="iconsHome" fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete " + model.modelName} placement="bottom-start">
            <IconButton
              onClick={() => {
                deleteModel(model, index);
              }}
              className="buttonsHome"
            >
              <img
                src={model.active ? deletePurple : activeModelIcon}
                alt="Delete"
                className="icons"
              />
            </IconButton>
          </Tooltip>
        </>
      );
    }
  };

  const generateReport = (client) => {
    setOpenModalReport(true);
  };

  const renderClientsButtons = (client, index) => {
    if (user.rolUser !== 2 && tableSelected == "clientes") {
      return (
        <>
          <Tooltip
            title={"Download Report of " + client.fullname}
            placement="bottom-start"
          >
            <IconButton
              onClick={() => {
                setClientToReport(client);
                generateReport(client);
              }}
              className="buttonsHome"
            >
              <DescriptionIcon className="iconsHome" fontSize="medium" />
            </IconButton>
          </Tooltip>
        </>
      );
    }
  };

  const renderButtons = (proc) => {
    if (user.rolUser !== 2 && tableSelected == "proyectos") {
      return (
        <>
          <Tooltip title={"See " + proc.processName} placement="bottom-start">
            <IconButton
              onClick={() => {
                navigate(`/view/${proc.uid}`);
              }}
              className="buttonsHome"
            >
              <VisibilityIcon className="iconsHome" fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Edit " + proc.processName} placement="bottom-start">
            <IconButton
              onClick={() => {
                openCloseModal(proc.uid);
              }}
              className="buttonsHome"
            >
              <EditIcon className="iconsHome" fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={"Create strategy " + proc.processName}
            placement="bottom-start"
          >
            <IconButton
              onClick={() => {
                verifyStrategy(proc);
              }}
              className="buttonsHome"
            >
              <PostAddIcon className="iconsHome" fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={"Channels " + proc.processName}
            placement="bottom-start"
          >
            <IconButton
              onClick={() => {
                navigate(`/channels/${proc.uid}`);
              }}
              className="buttonsHome"
            >
              <CallMergeIcon className="iconsHome" fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Process File" placement="bottom-start">
            <IconButton
              onClick={() => {
                navigate(`/processfile/${proc.uid}`);
              }}
              className="buttonsHome"
            >
              <img src={resultsActive} alt="Delete" className="icons results" />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Delete "} placement="bottom-start">
            <IconButton
              onClick={() => {
                handleClickdeleteItem(proc);
              }}
              className="buttonsHome"
            >
              <img src={deletePurple} alt="Delete" className="icons" />
            </IconButton>
          </Tooltip>
        </>
      );
    } else if (user.rolUser === 2 && tableSelected == "proyectos") {
      return (
        <>
          <Tooltip title="Process File" placement="bottom-start">
            <IconButton
              onClick={() => {
                navigate(`/processfile/${proc.uid}`);
              }}
              className="buttonsHome"
            >
              <img src={resultsActive} alt="Delete" className="icons results" />
            </IconButton>
          </Tooltip>
        </>
      );
    }
  };

  const getFormHome = () => {
    let tableName;
    let nameField;
    if (tableSelected == "proyectos") {
      tableName = process;
      nameField = "processName";
    } else if (tableSelected == "clientes") {
      tableName = clients;
      nameField = "fullname";
    } else if (tableSelected == "modelos") {
      tableName = modelList;
      nameField = "modelName";
    }

    return (
      <div>
        <div className="project-list">
          <div className="elementsContainer">
            {tableName.length > 0 ? (
              tableName.map((proc, index) => (
                <List className="listItemHome" key={index}>
                  <ListItem className="itemHome">
                    <ListItemText
                      primary={
                        nameField == "processName"
                          ? proc.processName
                          : nameField == "fullname"
                          ? proc.fullname
                          : proc.modelName
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction className="secondaryItem">
                      {tableSelected == "proyectos"
                        ? renderButtons(proc)
                        : tableSelected == "modelos"
                        ? renderModelButtons(proc, index)
                        : renderClientsButtons(proc, index)}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              ))
            ) : (
              <div className="title">
                Select a client to see their{" "}
                {tableSelected == "proyectos" ? "projects" : "Models"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderButtonCreate = () => {
    if (user.rolUser === 3 || user.rolUser === 2) {
      return null;
    } else {
      return (
        <>
          <button
            variant="contained"
            className="buttonCreateProcess"
            onClick={() => {
              openCloseModal();
            }}
          >
            <img src={newProcessIcon} alt="New Process" className="icons" /> New
          </button>
        </>
      );
    }
  };

  const renderTable = () => {
    if (user.rolUser === 3) {
      return (
        <div>Please contact your administrator to request permissions</div>
      );
    } else {
      return getFormHome();
    }
  };

  return (
    <div className="container">
      <RenderLoader loading={loading} />
      <MainBar />

      <div className="tableContainer">
        <div className="tableHome">
          <div className="main-box">
            <div className="tableBar">
              <div className="tabs">
                <button
                  id="proyectos"
                  className={`homeTabs ${
                    tableSelected === "proyectos" && "selected"
                  }`}
                  onClick={() => setTableSelected("proyectos")}
                >
                  Projects
                </button>
                <button
                  id="modelos"
                  className={`homeTabs ${
                    tableSelected === "modelos" && "selected"
                  }`}
                  onClick={() => setTableSelected("modelos")}
                >
                  Models
                </button>
                <button
                  id="clientes"
                  className={`homeTabs ${
                    tableSelected === "clientes" && "selected"
                  }`}
                  onClick={() => setTableSelected("clientes")}
                >
                  Clients
                </button>
              </div>
              {tableSelected == "proyectos" || tableSelected == "modelos" ? (
                <div className="tableControls">
                  <FormControl required={true}>
                    <Autocomplete
                      value={client}
                      className="selectCliente"
                      onChange={(event, newValue) => {
                        setClient(newValue);
                      }}
                      getOptionLabel={(option) => option.fullname}
                      options={clients}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          label="Client"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  </FormControl>

                  {tableSelected == "proyectos" ? renderButtonCreate() : null}
                  <div>
                    <SearchBar filter={filterProcess} placeholder="Search" />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {renderTable()}
          </div>
        </div>
      </div>
      <ModalCreateCollection
        open={openModalCreateCollection}
        disableEscapeKeyDown={true}
        disableBackdropClick
        handleClose={() => {
          setOpenModalCreateCollection(false);
        }}
        processData={clientProcess}
        clientData={client}
        processUid={idSelected}
      />
      <ModalCreateStrategy
        open={openModalCreateStrategy}
        handleClose={() => {
          setOpenModalCreateStrategy(false);
        }}
        processData={idSelected}
      />

      <ModalReport
        open={openModalReport}
        handleClose={() => {
          setOpenModalReport(false);
        }}
        clientToReport={clientToReport}
      />
    </div>
  );
}

export default Home;
