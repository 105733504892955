import { useEffect, useState, useReducer } from "react";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import { useParams } from "react-router-dom";
import { _get, _put,decodeUser } from "../../common/generalRequests";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Row from "./components/Row";
import "./validation.css";
import ProgressBar from "../../common/components/progressBar/ProgressBar";
import { UnauthorizedMessage } from "../../common/helpers/UnauthorizedMessage";


function Validation() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dataTypes, setDataTypes] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [processType, setProcessType] = useState(0);
  const [validationColumns, setValidationColumns] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [nameProcess, setNameProcess] = useState("");
  const [processCreation, setProcessCreation] = useState(false);
  const [idClient, setIdClient] = useState(null);
  const [clientSelected, setClientSelected] = useState("");

  useEffect(() => {
    let user = decodeUser(localStorage.getItem("USER"));

    let roles=[0,1]
    if(!roles.includes(user.rolUser)){
      navigate("/home")
      UnauthorizedMessage()
   }
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          setNameProcess(res.data.oneProcess.processName);
          const dataProcess = res.data.oneProcess;
          const idType = dataProcess ? dataProcess.processType : null;
          setProcessType(idType);
          dataProcess.validationColumn =
            dataProcess.validationColumn == null
              ? []
              : dataProcess.validationColumn;
          const processValidations =
            dataProcess.validationColumn.length > 0
              ? JSON.parse(dataProcess.validationColumn)
              : [];
          if (processValidations.length > 0) {
            setValidationColumns(processValidations);
            setProcessCreation(false);
          } else {
            setProcessCreation(true);
            let columnsArray = JSON.parse(dataProcess.linkedColumns).map(
              (column) => {
                column.dataType = 7;
                column.validation = column.required
                  ? [{ validation: 53, description: "Required" }]
                  : [];
                return column;
              }
            );
            setValidationColumns(columnsArray);
          }
        }
      },
      (error) => {
        console.log(error);
        swal("Error", "An error has occurred", "error").then(() =>
          navigate("/home")
        );
      }
    );
    _get(
      "/datatype",
      (res) => {
        if (res.data.ok) {
          let arrayDataTypes = res.data.types;
          _get(
            "/validation",
            (res) => {
              if (res.data.ok) {
                let arrayValidation = res.data.val;
                let newArrayDataTypes = arrayDataTypes.map((arr) => {
                  arr = {
                    id: arr.id,
                    description: arr.description,
                    validation: arrayValidation.filter(
                      (val) => val.idDataType === arr.id
                    ),
                    selected: false,
                  };
                  return arr;
                });
                setDataTypes(newArrayDataTypes);
                setLoading(false);
              }
            },
            (error) =>
              swal("Error", "Ha ocurrido un error 2", "error").then(() =>
                navigate("/home")
              )
          );
        }
      },
      (error) =>
        swal("Error", "Ha ocurrido un error 3", "error").then(() =>
          navigate("/home")
        )
    );
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  const verifyClient = async (nav = null, idProc) => {
    if (nav) {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            navigate(nav);
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            ).then(() => {});
          }
          setLoading(false);
        },
        (error) =>
          swal("error", "Client not authorized to perform this action", "error")
      ).then(() => {});
    } else {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            return true;
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            ).then(() => {});
            return false;
          }
        },
        (error) =>
          swal("error", "Client not authorized to perform this action", "error")
      ).then(() => {});
    }
  };

  const handleAddValToProcess = (newVal, idColumn) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newValidationColumns = validationColumns.map((column) => {
        if (column.id === idColumn) {
          column.validation.push(newVal);
        }
        return column;
      });
      setValidationColumns(newValidationColumns);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const handleDeleteValToProcess = (idColumn, index, value) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newValidationColumns = validationColumns.map((column) => {
        if (column.id === idColumn) {
          column.validation.splice(index, 1);
        }
        return column;
      });
      setValidationColumns(newValidationColumns);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const changeDataTypeOfColumn = (idColumn, data) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newValidationColumns = validationColumns.map((column) => {
        if (column.id === idColumn) {
          column.dataType = data;
          column.validation = [];
        }
        return column;
      });
      setValidationColumns(newValidationColumns);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const saveDataValidation = () => {
    if (verifyClient(null, uid)) {
      if (dataChanged || processCreation) {
        swal({
          title: "Are you sure you want to continue?",
          text: "This section can be configured later",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((save) => {
          if (save) {
            let data = {
              validationColumn: JSON.stringify(validationColumns),
            };
            _put(
              `/editProcess/${uid}`,
              data,
              (res) => {
                if (res.data.ok) {
                  navigate(`/process/${uid}`);
                }
              },
              (error) =>
                swal(
                  "Error",
                  "No fue posible guardar la información, por favor intentelo nuevamente",
                  "error"
                )
            );
          }
        });
      } else {
        navigate(`/process/${uid}`);
      }
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const getColumns = () => {
    return (
      <div className="contentTableValidations">
        {validationColumns.map((column, index) => (
          <div key={index} className="rowsLinks">
            <div style={{ marginLeft: "20px" }} className="subtitleTableLink">
              {index + 1 + "."}
            </div>
            <div
              key={column.id}
              className="subtitleTableLink"
              style={{ width: 300 }}
            >
              {" "}
              {column.description +
                (column.required ? " (Required)" : "")}{" "}
            </div>
            <Row
              dataTypes={dataTypes}
              column={column}
              indexColumn={column.id}
              handleAddValToProcess={handleAddValToProcess}
              handleDeleteValToProcess={handleDeleteValToProcess}
              changeDataTypeOfColumn={changeDataTypeOfColumn}
            />
          </div>
        ))}
      </div>
    );
  };

  const getFormStep = () => {
    return (
      <div className="containerLink">
        <div className="first_rowLink">
          <div className="subtitle">Column</div>
          <div className="subtitle">Datatype</div>
          <div className="subtitle">Validation</div>
          <div className="subtitle">Additional Information</div>
        </div>
        {getColumns()}
      </div>
    );
  };

  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar clientName={clientSelected} />
        <ProgressBar
        nameProcess={nameProcess}
        nextStep={saveDataValidation}
        buttonOn={true}
        backStep={`/link/${uid}`}
        uidProcess={uid}
        backProcess={verifyClient}
        />
        <div className="common">
          <div className="boxLink">{getFormStep()}</div>
        </div>
      </div>
    </div>
  );
}

export default Validation;
