import Loader from "../tools/loader/Loader";

const RenderLoader = ({ loading }) => {
  if (loading) {
    return <Loader />;
  } else {
    return null;
  }
};

export default RenderLoader;
