import { Component } from "react";
import { routes } from "./routes/routes";

class App extends Component {
  render() {
    return routes
    
  }
}

export default App;
