import { useEffect, useState, useReducer } from "react";
import * as XLSX from "xlsx";
import ProgressBar from "../../common/components/progressBar/ProgressBar";
import BarChartIcon from "@mui/icons-material/BarChart";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import { useParams } from "react-router-dom";
import { _post, _get } from "../../common/generalRequests";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import IconButton from "@mui/material/IconButton";
import ModalResultProcess from "./components/ModalResultProcess";
import { DataGrid } from "@mui/x-data-grid";
import ModalUploadFiles from "./components/ModalUploadFiles";
import ModalProcessing from "./components/ModalProcessing";
import ReplayIcon from "@mui/icons-material/Replay";
import DescriptionIcon from "@mui/icons-material/Description";
import "./processFile.css";
import "../../common/css/ProjectStyles.css";

function ProcessFile() {
  const navigate = useNavigate();
  let randomName = (Math.random() + 1).toString(36).substring(2);
  let { uid } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isLoading, setLoading] = useState(true);
  const [openModalUploadFiles, setOpenModalUploadFiles] = useState(false);
  const [requiredInput, setRequiredInput] = useState({});
  const [openModalProcessing, setOpenModalProcessing] = useState(false);
  const [processName, setProcessName] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [processedRecordsLength, setProcessedRecordsLength] = useState(0);
  const [failedRecords, setFailedRecords] = useState(0);
  const [openModalResult, setOpenModalResult] = useState(false);
  const [dataToDownload, setDataToDownload] = useState([]);
  const [processType, setProcessType] = useState(null); // 1 singlUpload 2 Multiple Upload
  const [data, setData] = useState([]);
  const [messageProcessing, setMessageProcessing] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [nameProcess, setNameProcess] = useState("");
  const [idClient, setIdClient] = useState(null);
  const [clientSelected, setClientSelected] = useState("");
  const [validateMsg, setValidateMsg] = useState(null);
  const [validateStatus, setValidateStatus] = useState(false);
  const [headersData, setHeadersData] = useState([]);

  useEffect(() => {
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          setNameProcess(res.data.oneProcess.processName);
          let input = JSON.parse(res.data.oneProcess.headers);
          if (!input) {
            swal(
              "Warning",
              "The project has not been correctly configured.",
              "warning"
            ).then(() => navigate("/home"));
          } else {
            let inputObject = {};
            Object.keys(input).forEach((key) => {
              inputObject[`${key}`] = null;
            });
            setRequiredInput(inputObject);
          }
          setProcessName(res.data.oneProcess.processName);
          setProcessType(res.data.oneProcess.processType);
          setHeadersData(JSON.parse(res.data.oneProcess.headers));
        }
      },
      (error) =>
        swal("Error", "An error has occurred", "error").then(() =>
          navigate("/home")
        )
    );
    getUploadsInfo();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  useEffect(() => {
    getFormProcessFile();
  }, [data]);

  const reports = (item) => {
    navigate(`/reports/${item.uidProcess}`, {
      state: {
        uploadId: item.id,
        fileNameData: item.fileNameDownload,
      },
    });
  };

  const getUploadsInfo = async () => {
    await _get(
      `/processed/${uid}`,
      (res) => {
        if (res.data.ok) {
          let processedList = res.data.recordProcess;
          processedList.forEach((item) => {
            item.stepString = `${item.step}/ 4`;
          });
          setData(processedList);
          setLoading(false);
        }
      },
      (error) =>
        swal("Error", "An error has occurred", "error").then(() =>
          navigate("/home")
        )
    );
  };

  const columns = [
    {
      headerName: "Upload Date",
      field: "name",
      flex: 0.5,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerName: "Stored Records",
      field: "ProcessedRecords",
      flex: 0.5,
      headerAlign: "center",
    },
    {
      headerName: "Records with error",
      field: "FailedRecords",
      flex: 0.5,
      headerAlign: "center",
    },
    {
      headerName: "File Name",
      field: "fileName",
      flex: 0.5,
      headerAlign: "center",
    },
    {
      headerName: "Process Step",
      field: "stepString",
      flex: 0.5,
      headerAlign: "center",
    },
    {
      headerName: "Status",
      field: "state",
      headerAlign: "center",
      flex: 0.5,

      renderCell: (value) => (
        <div>
          {value.row.status === "Failed" ? (
            <>
              {" "}
              {value.row.status}{" "}
              <IconButton
                onClick={() => {
                  swal("Info", JSON.parse(value.row.errorLog).error, "info");
                }}
              >
                <DescriptionIcon fontSize="small" className="arrowIcon" />
              </IconButton>
            </>
          ) : !value.row.state || value.row.state === 0 ? (
            "Ready for Analytics"
          ) : value.row.state === 1 ? (
            "Processing Analytics"
          ) : value.row.state === 2 ? (
            "Processed by Analytics"
          ) : value.row.state === 3 ? (
            "Processed by Analytics ?"
          ) : value.row.state === -1 ? (
            "Processing"
          ) : value.row.state === -2 ? (
            <>
              {" "}
              {value.row.status}{" "}
              <IconButton
                onClick={async () => {
                  setLoading(true)
                  await getDataFileError(
                    value.row.errorLog,
                    value.row.status === "invalid Files" ? "temp" : "errors"
                  );
                  setProcessedRecordsLength(value.row.ProcessedRecords);
                  setFailedRecords(value.row.FailedRecords);
                  setOpenModalResult(true);
                  setLoading(false)
                }}
              >
                <DescriptionIcon fontSize="small" className="arrowIcon" />
              </IconButton>
            </>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Reports",
      field: "Reportes",
      headerAlign: "center",
      flex: 0.5,
      sortable: "false",
      renderCell: (value) => (
        <IconButton
          onClick={() => {
            reports(value.row);
          }}
          disabled={
            (value.row.status !== "SUCCESS" &&
              value.row.status !== "Failed in rows") ||
            value.row.state === -1 ||
            value.row.status === "insert Failed"
          }
        >
          <BarChartIcon fontSize="small" className="arrowIcon" />
        </IconButton>
      ),
    },
    {
      headerName: "Process",
      field: "ProcesarState",
      headerAlign: "center",
      sortable: "false",
      flex: 0.5,

      renderCell: (value) => (
        <button
          className={
            value.row.status === "Failed"
              ? "buttonProcessAnalytic"
              : "buttonProcessAnalytic disabledButton"
          }
          onClick={() => {
            restartFileConverter(value.row.step, value.row.errorLog);
          }}
          disabled={value.row.status !== "Failed"}
        >
          {value.row.state === -1 && value.row.status !== "Failed"
            ? "Processing"
            : value.row.status === "Failed"
            ? "Restart"
            : "Completed"}
        </button>
      ),
    },
    {
      headerName: "Analityc Process",
      field: "Procesar",
      headerAlign: "center",
      sortable: "false",
      flex: 0.5,

      renderCell: (value) => (
        <button
          className={
            value.row.ProcessedRecords > 0 && value.row.ProcessedRecords
              ? "buttonProcessAnalytic"
              : "buttonProcessAnalytic disabledButton"
          }
          onClick={() => {
            enqueueUpload(value.row);
          }}
          disabled={
            value.row.ProcessedRecords > 0 && value.row.ProcessedRecords != null
              ? false
              : true
          }
        >
          {value.row.state !== 1 ? "Run" : "Update"}
        </button>
      ),
    },
  ];

  const getDataFileError = async (errorLog, container) => {
    errorLog = JSON.parse(errorLog).fileName;
    let data = {
      fileName: errorLog,
      container: container,
    };
    await _post(
      "/generalFile/downloadErrorFile",
      data,
      (res) => {
        if (res.data.ok) {
          setDataToDownload(res.data.data);
        } else {
          swal("Error", `${res.data.error}`, "error").then(() => navigate(0));
        }
      },
      (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
    );
  };

  const restartFileConverter = async (step, errorLog) => {
    errorLog = JSON.parse(errorLog);
    let messageString = JSON.stringify(errorLog.message);
    let data = {
      step,
      qeueMessage: messageString,
    };

    await _post(
      "/generalFile/restartTask",
      data,
      (res) => {
        if (res.data.ok) {
          swal("Info", `the process has been restarted`, "Process restarted");
        } else {
          swal("Error", `${res.data.error}`, "error").then(() => navigate(0));
        }
      },
      (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
    );
  };

  const enqueueUpload = async (row) => {
    setLoading(true);
    if (row.state !== 1) {
      const rowData = {
        uploadId: row.id,
        uidProcess: row.uidProcess,
      };
      await _post(
        "/enqueueUpload",
        rowData,
        (res) => {
          if (res.data.state === 1) {
            swal("Info", "Your file was sent to analytics", "info");
            getUploadsInfo();
          }
        },
        (_error) =>
          swal(
            "Error",
            "An error occurred while reading the file ",
            "error"
          ).then(() => navigate(0))
      );
    } else {
      await _get(
        `/processed/${row.uidProcess}`,
        (_res) => {
          if (_res.data.ok) {
            let processedList = _res.data.recordProcess;
            processedList.forEach((item) => {
              item.stepString = `${item.step}/ 4`;
            });
            let upload = processedList.filter(
              (_upload) => _upload.id === row.id
            )[0];
            if (upload.state === 1) {
              swal("Info", "Your file is still being processed", "info");
            } else if (upload.state === 2) {
              console.log("aqui consoltar los errores de este upload");
            }
            setData(processedList);
          }
        },
        (_error) => swal("Error", "An error has occurred", "error")
      );
    }

    setLoading(false);
  };

  const uploadSingleFile = (key, file) => {
    let newRequiredInput = requiredInput;
    newRequiredInput[`${key}`] = file;
    setRequiredInput(newRequiredInput);
    forceUpdate();
  };

  const cancelUploadData = () => {
    let originalRequiredInput = {};
    Object.keys(requiredInput).forEach((key) => {
      originalRequiredInput[`${key}`] = null;
    });
    setRequiredInput(originalRequiredInput);
    setOpenModalUploadFiles(false);
  };

  //orquestador

  const fileConverter = async () => {
    let fileNameObject = { files: [] };
    let validateRes;
    let uploadRes;
    let typeProcess;
    let blobUrls = [];

    // primer paso
    await validTotalFiles();
    // segundo paso
    try {
      validateRes = await ValidateOneFile();
    } catch (error) {
      console.log("error validating files", error);
    }
    // tercer paso
    if (validateRes.valid) {
      validateRes.filesNames.forEach((element, index) => {
        let file = {
          fileName: `${element}`,
          path: `${validateRes.paths[index]}`,
          ext: validateRes.extensions[index],
          randomName: `${randomName}`
        };
        fileNameObject.files.push(file);
      });
      try {
        uploadRes = await CreateUpload(uid, validateRes.filesNames);
      } catch (error) {
        console.log("error creating upload", error);
      }

      // cuarto paso

      if (validateRes.paths.length > 1) {
        typeProcess = 2;
        for (let i = 0; i < validateRes.paths.length; i++) {
          blobUrls.push(
            await generalFile(
              `${validateRes.filesNames[i]}${randomName}${validateRes.extensions[i]}`,  //agregar randomizador de nombres y en las funciones agregar pa que lea tambien
              validateRes.paths[i],
              i
            )
          );
        }
      } else {
        typeProcess = 1;
        blobUrls.push(
          await generalFile(
            `${validateRes.filesNames}${randomName}${validateRes.extensions[0]}`,
            validateRes.paths[0],
            1
          )
        );
      }

      // quinto paso
      await registerProcess(
        typeProcess,
        uid,
        uploadRes.upload[0][0].id,
        fileNameObject,
        processName
      );

      // quinto paso
    } else {
      console.log("error");
      setOpenModalProcessing(false);
      setOpenModalResult(true);
    }
  };

  // 0er paso: validar archivos
  const validTotalFiles = async () => {
    setPercentage(0);
    setMessageProcessing("Validating Files");
    setOpenModalProcessing(true);
  };

  const ValidateOneFile = async (headers) => {
    const formData = new FormData();
    let valid = false;
    let bases = [];
    Object.keys(requiredInput).forEach((key) => {
      bases.push(key);
      formData.append("file", requiredInput[key]);
    });
    let notIncludesColumns = [];

    valid = true;
    //TODO Eliminar totalmente este paso de validacion
    formData.append("bases", bases);
    formData.append("processName", processName);
    formData.append("uidProcess", uid);
    if (valid) {
      return new Promise((resolve, reject) => {
        _post(
          "/files/validate",
          formData,
          async (res) => {
            if (res.data.ok) {
              setPercentage(10);
              setMessageProcessing(res.data.name);
              setValidateMsg(res.data.msg);
              setValidateStatus(res.data.valid);
              resolve(res.data);
            } else {
              swal("Error", `${res.data.error}`, "error").then(() =>
                navigate(0)
              );
            }
          },
          (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
        );
      });
    } else {
      const notIncludesColumnsStrings = notIncludesColumns.join(", ");
      setValidateMsg(
        `the following columns are missing : ${notIncludesColumnsStrings}. in the files `
      );
      return {
        valid: false,
      };
    }
  };

  // new step register to upload table

  const CreateUpload = async (uidProcess, fileNameObject) => {
    let data = {
      uidProcess,
      fileName: fileNameObject.join(),
    };

    return new Promise((resolve, reject) => {
      _post(
        "/uploads/",
        data,
        (res) => {
          if (res.data.ok) {
            setPercentage(20);
            setMessageProcessing("Register upload");
            resolve(res.data);
          } else {
            swal("Error", `${res.data.error}`, "error").then(() => navigate(0));
          }
        },
        (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
      );
    });
  };

  // new step upload file to blobstorage

  const generalFile = async (fileName, path, index) => {
    let data = {
      fileName,
      path,
    };

    return new Promise((resolve, reject) => {
      _post(
        "/generalFile/uploadBlob",
        data,
        async (res) => {
          if (res.data.ok) {
            setPercentage(50 + 5 * index);

            setMessageProcessing("Upload files to blob storage");
            await deleteLocalFiles(path);
            resolve(res.data.blobUrl);
          } else {
            swal("Error", `${res.data.error}`, "error").then(() => navigate(0));
          }
        },
        (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
      );
    });
  };

  const deleteLocalFiles = async (path) => {
    let data = {
      path,
    };
    return new Promise((resolve, reject) => {
      _post(
        "/generalFile/deleteFile",
        data,
        (res) => {
          if (res.data.ok) {
            setMessageProcessing("Delete local File");
            resolve(res.data);
          } else {
            swal("Error", `${res.data.error}`, "error").then(() => navigate(0));
          }
        },
        (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
      );
    });
  };

  const registerProcess = async (
    type,
    uidProcess,
    uploadId,
    fileNameObject,
    processName
  ) => {
    let data = {
      type,
      uidProcess,
      uploadId,
      files: fileNameObject.files,
      processName,
    };

    await _post(
      "/generalFile/registerTask",
      data,
      (res) => {
        if (res.data.ok) {
          setPercentage(100);
          setMessageProcessing("Added to the queue");
          cancelUploadData();
        } else {
          swal("Error", `${res.data.error}`, "error").then(() => navigate(0));
        }
      },
      (error) => swal("Error", `${error}`, "error").then(() => navigate(0))
    );
  };

  const closeModalResult = () => {
    setOpenModalResult(false);
    setValidateMsg(null);
  };

  const endProcessFile = async () => {
    setLoading(true);
    setProcessedRecordsLength(0);
    setFailedRecords(0);
    setDataToDownload([]);
    setOpenModalResult(false);
    let originalRequiredInput = {};
    Object.keys(requiredInput).forEach((key) => {
      originalRequiredInput[`${key}`] = null;
    });
    setRequiredInput(originalRequiredInput);
    await getUploadsInfo();
  };

  const downloadData = (data) => {
    if (data) {
      downloadxls(data, `ERROR - ${processName} - ${new Date()}`);
    } else {
      downloadxls(dataToDownload, `${processName} - ${new Date()}`);
      endProcessFile();
    }
  };

  const downloadxls = (data, name) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    XLSX.writeFile(wb, `${name}.xlsx`, { FS: ";" });
  };

  const getFormProcessFile = () => {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <IconButton
          onClick={() => getUploadsInfo()}
          style={{
            alignSelf: "flex-end",
            marginRight: "12%",
            marginTop: "5px",
          }}
        >
          <ReplayIcon className="arrowIcon" />
        </IconButton>
        {data.length > 0 ? (
          <div className="processed-list center">
            <DataGrid
              highlightOnHover
              sx={{
                width: "100%",
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  justifyContent: "center",
                },
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              columns={columns}
              rows={data}
            />
          </div>
        ) : (
          <div className="subtitle noFileUploaded">
            No file has been processed yet
          </div>
        )}
      </div>
    );
  };
  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  const closeModalProcessing = () => {
    setOpenModalProcessing(false);
    setPercentage(0);
    setMessageProcessing("");
    swal(
      "Process successfully queued",
      "The process registered in queue successfully, will be processed in a few minutes, to check the progress of the process you can click on the refresh button.",
      "success"
    ).then(getUploadsInfo());
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar clientName={clientSelected} />
        <ProgressBar
          nameProcess={nameProcess}
          nextStep={setOpenModalUploadFiles}
          buttonOn={true}
          backStep={`/home`}
          nextParameter={true}
          backProcess={navigate}
          nextButtonLabel="Upload file"
          stepIcons="results"
        />
        <div className="common">
          <div className="main-box">{getFormProcessFile()}</div>
        </div>

        <ModalUploadFiles
          open={openModalUploadFiles}
          handleClose={cancelUploadData}
          requiredInput={requiredInput}
          uploadSingleFile={uploadSingleFile}
          fileConverter={fileConverter}
          setLoading={setLoading}
        />
        <ModalProcessing
          open={openModalProcessing}
          percentage={percentage}
          message={messageProcessing}
          handleClose={closeModalProcessing}
        />
        <ModalResultProcess
          open={openModalResult}
          resultMessage={resultMessage}
          handleClose={() => {
            setOpenModalResult(false);
          }}
          processedRecords={processedRecordsLength}
          failedRecords={failedRecords}
          downloadData={downloadData}
          endProcessFile={endProcessFile}
          validateMsg={validateMsg} //revisar esto para cuando sea error de validacion.
          closeModalResult={closeModalResult}
        />
      </div>
    </div>
  );
}

export default ProcessFile;
