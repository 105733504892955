import { useMemo, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { _post } from "../../../common/generalRequests";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
); 

export default function ProbabilityChart() {
  const { uid } = useParams();
  const { state } = useLocation();
  const [labels, setLabels] = useState([]);
  const [scores, setScores] = useState([]);

  useEffect(() => {
    getDataChart();
  }, []);

  const getDataChart = () => {
    const data = { uidProcess: uid, uploadId: state.uploadId };
    _post(
      `/charts/probabilityChart`,
      data,
      (res) => {
        if (res.data.ok) {
          let scoresArr = res.data.probabilityRanges,
          labelsArr= res.data.labels;
          setLabels(labelsArr);
          setScores(scoresArr);          
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };

  const options = {
    fill: false,
    responsive: true,
    scales: {
      y: {
        min: 0,
      },
    },    
    plugins: {
      tooltip: {
        yAlign: "bottom",        
      },
      legend: {
        display: true,
      },
    },
  };
  const data = useMemo(
    function () {
      return {
        datasets: [
          {
            type: "bar",
            label: "Clients",
            data: scores,
            borderColor: "#923456",
            backgroundColor: "#FF0081",
            barThickness: 50,
          },
        ],
        labels,
      };
    },
    [labels, scores]
  );

  return <Chart type="bar" data={data} options={options} />;
}
