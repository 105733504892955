import { useState, useEffect } from "react";
import { _post, _get } from "../../../../common/generalRequests";
import swal from "sweetalert";
import ModalUploadVersion from "./components/ModalUploadVersion";
import "./versionModelMenu.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function VersionModelMenu({ modelMenu, width, setIsLoading }) {
  const [modelsContainers, setModelsContainers] = useState(null);
  const [containerName, setContainerName] = useState("");
  const [openModalUploadVersion, setOpenModalUploadVersion] = useState(false);
  useEffect(() => {
    if (modelMenu == "modelVersions") {
      setIsLoading(true);
      _get(
        "/modelsContainer",
        (res) => {
          if (res.data.ok) {
            setModelsContainers(res.data.models);
            setIsLoading(false);
          } else {
            swal("Error", `${res.data.error}`, "error");
          }
        },
        (error) => {
          swal("Error", `${error}`, "error");
          setIsLoading(false);
        }
      );
    }
  }, [modelMenu]);

  const openModalVersions = (containerName) => {
    setOpenModalUploadVersion(true);
    setContainerName(containerName);
  };

  return modelMenu == "modelVersions" ? (
    <>
      <div className="modelContainerTable">
        {modelsContainers
          ? modelsContainers.map((container, index) => {
              return (
                <div className="modelContainerItem" key={index}>
                  {container.name}{" "}
                  <Tooltip
                    title={"Upload version of" + container.name}
                    placement="bottom-start"
                    className="buttonuploadVersion"
                  >
                    <IconButton
                      onClick={() => openModalVersions(container.name)}
                    >
                      <CloudUploadIcon className="iconbuttonuploadVersion" />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            })
          : null}
      </div>

      <ModalUploadVersion
        open={openModalUploadVersion}
        handleClose={() => {
          setOpenModalUploadVersion(false);
        }}
        containerName={containerName}
        setIsLoading={setIsLoading}
      />
    </>
  ) : null;
}

export default VersionModelMenu;
