import { useMemo, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { _post } from "../../../common/generalRequests";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default function StackedProductsByGroup() {
  const { uid } = useParams();
  const { state } = useLocation();
  const [labels, setLabels] = useState([]);
  const [scores, setScores] = useState([]);
  const [percents, setPercents] = useState([]);
  const [products, setProducts] = useState([]);
  const [counts, setCounts] = useState([]);
  const [productschange, setProductsChange] = useState(false);

  useEffect(() => {
    getDataChart();
  }, []);

  useEffect(() => {
    setProductsChange(!productschange);
  }, [products]);

  const getDataChart = () => {
    const data = { uidProcess: uid, uploadId: state.uploadId };
    _post(
      `/charts/stackedProductsByGroup`,
      data,
      (res) => {
        if (res.data.ok) {
          let [labelsArr, countsArr, scoresArr, percentsArr, productsArr] = [
            res.data.groups,
            res.data.counts,
            res.data.scores,
            res.data.percents,
            res.data.products,
          ];
          setLabels(labelsArr);
          setCounts(countsArr);
          setPercents(percentsArr);
          setProducts(productsArr);
          setScores(scoresArr);
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };

  const getDatasets = () => {
    let _dataSets = [];
    products.forEach((product, index) => {
      let color = randomColor();
      _dataSets.push({
        type: "bar",
        label: product,
        data: scores[index],
        borderColor: color,
        backgroundColor: color,
        barThickness: 50,
        yAxisID: "y",
        order: 1
      });
      _dataSets.push({
        type: "line",
        label: `${product} %`,
        data: percents[index],
        tension: 0.3,
        borderColor: randomColor(),
        pointRadius: 3,
        pointBackgroundColor: randomColor(),
        yAxisID: "y2",
        order: 0
      });
    });
    return _dataSets;
  };

  const selectProducts = () => {
    let productos = products.splice(products.length - 1);

    setProducts(productos);
  };

  const colorsArray = [
    "#B31262",
    "#FFE519",
    "#FF0081",
    "#19E3FF",
    "#099EB3",
    "#00B300",
    "#FF300D",
    "#BAFF19",
    "#0D62FF",
  ];
  let colorSelected = [];
  const randomColor = () => {
    let colorChart = "";
    colorChart = colorsArray[Math.floor(Math.random() * 9)];

    while (colorSelected.includes(colorChart)) {
      colorChart = colorsArray[Math.floor(Math.random() * 9)];
    }
    colorSelected.push(colorChart);

    return colorChart;
  };

  const options = {
    fill: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      y2: {
        type: "linear",
        position: "right",
        max: 100,
        min: 0,
        ticks: {
          callback: (value, index, values) => {
            return `${value} %`;
          },          
        },
        grid:{display:false}
      },
    },
    plugins: {
      tooltip: {
        yAlign: "bottom",
        callbacks: {
          label: function (context) {            
            let label;
            if (context.dataset.type == "line") {
              label = "percentage of clients " +context.parsed.y + " %";
            }
            if (context.dataset.type == "bar") {
              label = "Number of clients " + context.parsed.y;
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
      },
    },
  };
  const data = useMemo(() => {
    return {
      datasets: getDatasets(),
      labels,
    };
  }, [labels, scores, productschange]);

  return (
    <>
      <Chart type="bar" data={data} options={options} />
      <div>
        {/*<button onClick={()=>selectProducts()}>boton 1</button>
    <button>boton 2</button>
<button>boton 3</button>*/}
      </div>
    </>
  );
}
