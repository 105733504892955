import { useState, useEffect } from "react";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import { useParams } from "react-router-dom";
import { _get , decodeUser} from "../../common/generalRequests";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Chip from "@mui/material/Chip";
import "./viewProcess.css";
import ProgressBar from "../../common/components/progressBar/ProgressBar";
import { UnauthorizedMessage } from "../../common/helpers/UnauthorizedMessage";

function ViewProcess() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState([]);
  const [nameProcess, setNameProcess] = useState("");
  const [idClient, setIdClient] = useState(null);
  const [clientSelected, setClientSelected] = useState("");

  useEffect(() => {
    let user = decodeUser(localStorage.getItem("USER"));

    let roles=[0,1]
    if(!roles.includes(user.rolUser)){
      navigate("/home")
      UnauthorizedMessage()
   }
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          setNameProcess(res.data.oneProcess.processName);
          if (res.data.oneProcess) {
            let linkedColumns = res.data.oneProcess.linkedColumns
              ? JSON.parse(res.data.oneProcess.linkedColumns)
              : [];
            let validationColumns = res.data.oneProcess.validationColumn
              ? JSON.parse(res.data.oneProcess.validationColumn)
              : [];
            if (linkedColumns.length > 0 && validationColumns.length > 0) {
              let totalData = linkedColumns.map((link) => {
                validationColumns.forEach((val) => {
                  if (link.id === val.id) {
                    link.dataType = val.dataType;
                    link.validation = val.validation;
                  }
                });
                return link;
              });
              setTotalData(totalData);
              setLoading(false);
            } else {
              swal(
                "Warning",
                "Este proceso aun no ha sido configurado",
                "warning"
              ).then(() => navigate("/home"));
            }
          } else {
            swal(
              "Warning",
              "Este proceso aun no ha sido configurado",
              "warning"
            ).then(() => navigate("/home"));
          }
        } else {
          swal("Warning", "No Autorizado", "warning").then(() =>
            navigate("/home")
          );
        }
      },
      (error) =>
        swal("Error", `${error}`, "error").then(() => navigate("/home"))
    );
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  const getStringDataType = (id) => {
    return id === 1
      ? "Boolean"
      : id === 2
      ? "Text"
      : id === 3
      ? "Numeric"
      : id === 4
      ? "Text and numbers"
      : id === 5
      ? "Date"
      : id === 6
      ? "Email"
      : "N/A";
  };


  //todo sacar funcion por que esta en otro componente tambien
  const getLabelConditional = (conditionToValue) => {
    let label = "Depends on: ";
    conditionToValue.forEach((condition,index) => {
      if(index === conditionToValue.length-1){
        if(condition[0]){
          label += condition[0].column;
        }else{
          label += condition.column;
        }
        
      }else{
        if(condition[0]){
          label += condition[0].column;
        }else{
        label += condition.column + ", ";}

      }
    });
    return label;
  };

  const getLabelExcluding = (conditionToValue) => {
    let label = "";
    conditionToValue.forEach((condition, index) => {
      if (index === conditionToValue.length - 1) label += condition.Header;
      else label += condition.Header + " or ";
    });
    return label;
  };

  const getLabelSpecialOp = (specialOps) => {
    let label = "Special Op : ";
        label += specialOps ? "Difference " : "Fill ";
    return label;
  };

  const getFormStep = () => {
    return (
      <div className="containerLink">
        <div className="first_rowLink">
          <div className="subtitle">Outputs</div>
          <div className="subtitle">Inputs</div>
          <div className="subtitle">Type</div>
          <div className="subtitle">Rules</div>
        </div>
        <div className="contentTableProcess">
          <div>
            {totalData.map((column, index) => (
              <div className="rowsLinks" key={index}>
                <div className="subtitleTableLink subtitleViewIndex">
                  {index + 1 + "."}
                </div>
                <div className="subtitleTableLink subtitleViewDescription">
                  {column.description + (column.required ? " (requerido)" : "")}{" "}
                </div>
                <div className="columnProcessCollection">
                  {column.fileHeader.length > 0 ? (
                    <div className="viewProcessRow">
                      {column.fileHeader.map((head) => (
                        <Chip
                          className="chipProcess chipTipoCollection"
                          label={head.Header}
                        />
                      ))}
                    </div>
                  ) : column.conditionToColumn ? (
                    <div className="viewProcessRow">
                      <Chip
                        className="chipProcess chipExcludingCollection"
                        label={getLabelExcluding(column.conditionToColumn)}
                      />
                    </div>
                  ) : column.conditionToValue ? (
                    <div className="viewProcessRow">
                      <Chip
                        className="chipProcess chipReglasCollection"
                        label={getLabelConditional(column.conditionToValue)}
                      />
                    </div>
                  ) : column.fillValue ? (
                    <div className="viewProcessRow">
                      <Chip
                        className="chipProcess chipSpecialOp"
                        label={getLabelSpecialOp(column.fillValue.operation)}
                      />
                    </div>
                  ): (
                    <Chip className="chipProcess chipNACollection" label="N/A" />
                  )}
                </div>
                <div className="columnProcessCollection">
                  {column.dataType !== 7 ? (
                    <Chip
                      className="chipProcess chipTipoCollection"
                      label={getStringDataType(column.dataType)}
                    />
                  ) : (
                    <Chip className="chipProcess chipNACollection" label="N/A" />
                  )}
                </div>
                <div className="columnProcessCollection">
                  {
                    <div className="viewProcessGrid">
                      {column.validation.length > 0 ? (
                        column.validation.map((val) => (
                          <Chip
                            className="chipProcess chipReglasCollection"
                            label={
                              (val.description ? val.description : "Ninguna") +
                              (val.info ? ": " + val.info : "")
                            }
                          />
                        ))
                      ) : (
                        <Chip className="chipProcess chipNACollection" label="N/A" />
                      )}
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar clientName={clientSelected} />
        <ProgressBar 
         nameProcess={nameProcess}
         nextStep={navigate}
         buttonOn={true}
         backStep={`/home`}
         nextParameter={`/processfile/${uid}`}
         backProcess={navigate}
        />
        <div className="common">
          <div className="boxLink">{getFormStep()}</div>
        </div>
      </div>
    </div>
  );
}

export default ViewProcess;
