import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../assets/svg/CancelarGris.svg";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { _put } from "../../../../../common/generalRequests";
import { useParams } from "react-router-dom";
import RenderLoader from "../../../../../common/helpers/RenderLoader";

import "./modalCreateSalto.css";

const ModalCreateSalto = ({
  open,
  close,
  allChannels,
  interaction,
  indexInteraction,
}) => {
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const [nameSalto, setNameSalto] = useState("");
  const [typeChannel, setTypeChannel] = useState(
    "F19674A6-E50E-4BE5-8702-3A9B72FDBA43"
  );
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    if (Object.entries(interaction).length !== 0) {
      setChannels(
        interaction.interactions[indexInteraction].interaction.channels
      );
    }
  }, [interaction]);

  const createSalto = () => {
    setLoading(true);
    let idTypes = channels.map((channel) => {
      return channel.channel.idType;
    });

    if (idTypes.includes(typeChannel)) {
      swal(
        "Warning",
        "You cannot create more than one jump of the same type.",
        "warning"
      );
      setLoading(false);
    } else {
      let data = {};
      let channelsData = {
        channel: {
          name: nameSalto,
          idType: typeChannel,
          main: true,
          rule: {
            idCondition: "",
            jumpTo: "",
            frecuency: "",
            idTemplate: "",
          },
        },
      };

      interaction.interactions[indexInteraction].interaction.channels.push(
        channelsData
      );

      data = {
        interactions: JSON.stringify(interaction),
      };

      _put(
        `/editProcess/${uid}`,
        data,
        (res) => {
          if (res.data.ok) {
            setLoading(false);
            handleClose();
          }
        },
        (error) => swal("Error", error.message, "error")
      );
    }
  };

  const handleClose = () => {
    setNameSalto("");
    setChannels([]);
    setTypeChannel("F19674A6-E50E-4BE5-8702-3A9B72FDBA43");
    close();
  };

  return (
    <div>
      <RenderLoader loading={loading} />
      <Modal open={open} onClose={handleClose} className="modal">
        <div className="paperCreateCollection">
          <img
            src={closeIcon}
            alt="close"
            onClick={() => handleClose()}
            className="closeIcon"
          />
          <span className="titleModal">Create Jump</span>
          <label className="labelModal">Channel Type</label>
          <select
            name="typeChannel"
            className="selectChannels"
            onChange={(event) => {
              setTypeChannel(event.target.value);
              setNameSalto(
                allChannels.find(
                  (_channel) => _channel.channel.id === event.target.value
                ).channel.name
              );
            }}
          >
            <option value="" selected disabled>
            Select an option
            </option>
            {allChannels.map((option, index) => (
              <option key={index} value={option.channel.id}>
                {option.channel.name}
              </option>
            ))}
          </select>
          <button
            className="buttonModal"
            onClick={() => {
              if (nameSalto === "") {
                swal(
                  "Warning",
                  "Select an option before creating the Jump",
                  "warning"
                );
              } else {
                createSalto();
              }
            }}
          >
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateSalto;
