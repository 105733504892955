import Modal from "@mui/material/Modal";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import "./modalConfirmConvertion.css";

const ModalConfirmConvertion = ({
  open,
  handleClose,
  handleCancel,
  accept,
}) => {
  return (
    <Modal open={open} onClose={handleClose} className="modalconfirmConvertion">
      <div className="paperConfirmConvertion">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            handleClose();
          }}
          className="closeIcon"
        />
        <div className="titleModal">Do you want to process a file now?</div>
        <div className="subtitleModal">
        The process will be saved and you will see it on your home page
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <button className="buttonNoModal" onClick={() => handleCancel()}>
            No
          </button>
          <button className="buttonModal" onClick={() => accept()}>
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmConvertion;
