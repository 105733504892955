import { useMemo, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { _post } from "../../../common/generalRequests";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default function ClientByGroup() {
  const { uid } = useParams();
  const { state } = useLocation();
  const [labels, setLabels] = useState([]);
  const [scores, setScores] = useState([]);
  const [percents, setPercents] = useState([]);

  useEffect(() => {
    getDataChart();
  }, []);

  const getDataChart = () => {
    const data = { uidProcess: uid, uploadId: state.uploadId };
    _post(
      `/charts/clientsByGroup`,
      data,
      (res) => {
        if (res.data.ok) {
          let [labelsArr, scoresArr, percentsArr] = [
            res.data.groups,
            res.data.scores,
            res.data.percents,
          ];
          setLabels(labelsArr);
          setScores(scoresArr);
          setPercents(percentsArr);
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };

  const options = {
    fill: false,
    responsive: true,
    scales: {
      y: {
        type: "linear",
        position: "left",
        min: 0,
      },
      y2: {
        type: "linear",
        position: "right",
        max: 100,
        min: 0,
        ticks: {
          callback: (value, index, values) => {
            return `${value} %`;
          },
        },
        grid: { display: false },
      },
    },
    plugins: {
      tooltip: {
        yAlign: "bottom",
        callbacks: {
          label: function (context) {
            let label;
            if (context.dataset.type == "line") {
              label = "percentage of clients " + percents[context.dataIndex];
            }
            if (context.dataset.type == "bar") {
              label = "Number of clients " + context.parsed.y;
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
      },
    },
  };
  const data = useMemo(
    function () {
      return {
        datasets: [
          {
            type: "line",
            label: "Percentage",
            data: percents,
            tension: 0.3,
            borderColor: "#780095",
            pointRadius: 3,
            pointBackgroundColor: "#780095",
            yAxisID: "y2",
          },
          {
            type: "bar",
            label: "Clients",
            data: scores,
            borderColor: "#923456",
            backgroundColor: "#FF0081",
            barThickness: 50,
            yAxisID: "y",
          },
          /*{
            yAxisID: "y2",
            data: percents,
            label: "Percentage",
            borderColor: "#9C27B0",
            backgroundColor: "#9C27B0",
          },*/
        ],
        labels,
      };
    },
    [labels, scores]
  );

  return <Chart type="bar" data={data} options={options} />;
}
