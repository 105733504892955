import IconButton from "@mui/material/IconButton";
import React from "react";
import { DeleteForever } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import "./headerBox.css";
import ChipTemplate from "./ChipTemplate";
import AddChip from "./AddChip";

function HeaderBox({
  addHeaderValue,
  column,
  removeHeaderValue,
  removeConditionalHeader,
  removeExcludingHeader,
  addExcludingValue,
  addConditionalValue,
  addFillValue,
  deleteCustomColumn,
  removeSpecialOp,
}) {
  const getLabelConditional = (conditionToValue) => {
    let label = "Conditional:  ";
    if (conditionToValue[0][0]) {
      conditionToValue.forEach((condition) => {
        label += condition[0].column + ", ";
      });
    } else {
      label = "reconfigure";
    }

    return label;
  };

  const getLabelEspecialOp = (fillValue) => {
    let label =
      "Special operation:  " +
      (fillValue.operation == 0 ? "Fill" : "Special Difference");

    return label;
  };

  const getLabelExcluding = (conditionToValue) => {
    let label = "Excluyente: ";
    conditionToValue.forEach((condition) => {
      label += condition.Header + ", ";
    });
    return label;
  };

  const validInputExist = (headerColumn) => {
    return (
      headerColumn.fileHeader.length === 0 &&
      !headerColumn.conditionToColumn &&
      !headerColumn.conditionToValue
    );
  };

  const getChip = (column) => {
    if (
      column.fileHeader.length > 0 &&
      !column.conditionToValue &&
      !column.fillValue
    ) {
      return column.fileHeader.map((head, index) => (
        <div className="chipContainerRow" key={index}>
          <Tooltip title={head.Header} placement="bottom">
            <Chip
              className="chip linkEntradas"
              label={head.Header}
              onDelete={() => removeHeaderValue(index, head, column.id)}
            />
          </Tooltip>
        </div>
      ));
    } else if (column.fillValue && column.conditionToValue) {
      return (
        <>
          <ChipTemplate
            remove={removeSpecialOp}
            column={column}
            getLabel={getLabelEspecialOp}
            value={column.fillValue}
            classStyle="chip specialOperations liteSpecial"
          />
          <ChipTemplate
            remove={removeConditionalHeader}
            column={column}
            getLabel={getLabelConditional}
            value={column.conditionToValue}
            classStyle="chip linkExcluding lite"
          />
        </>
      );
    } else if (column.conditionToColumn) {
      return (
        <ChipTemplate
          remove={removeExcludingHeader}
          column={column}
          getLabel={getLabelExcluding}
          value={column.conditionToColumn}
          classStyle="chip linkConditions"
        />
      );
    } else if (column.conditionToValue && column.fileHeader.length === 0) {
      return (
        <ChipTemplate
          remove={removeConditionalHeader}
          column={column}
          getLabel={getLabelConditional}
          value={column.conditionToValue}
          classStyle="chip linkExcluding"
        />
      );
    } else if (column.fillValue && column.fileHeader.length === 0) {
      return (
        <ChipTemplate
          remove={removeSpecialOp}
          column={column}
          getLabel={getLabelEspecialOp}
          value={column.fillValue}
          classStyle="chip specialOperations"
        />
      );
    } else if (column.conditionToValue && column.fileHeader.length > 0) {
      return (
        <div className="chipContainerGrid">
          <Tooltip title={column.fileHeader[0].Header} placement="bottom">
            <Chip
              className="chip linkEntradas"
              label={column.fileHeader[0].Header}
              onDelete={() =>
                removeHeaderValue(0, column.fileHeader[0], column.id)
              }
            />
          </Tooltip>
          <ChipTemplate
            remove={removeConditionalHeader}
            column={column}
            getLabel={getLabelConditional}
            value={column.conditionToValue}
            classStyle="chip linkExcluding"
          />
        </div>
      );
    } else if (column.fillValue && column.fileHeader.length > 0) {
      return (
        <>
          <div className="chipContainerRow">
            <Tooltip title={column.fileHeader[0].Header} placement="bottom">
              <Chip
                className="chip linkEntradas lite"
                label={column.fileHeader[0].Header}
                onDelete={() =>
                  removeHeaderValue(0, column.fileHeader[0], column.id)
                }
              />
            </Tooltip>
          </div>
          <ChipTemplate
            remove={removeSpecialOp}
            column={column}
            getLabel={getLabelEspecialOp}
            value={column.fillValue}
            classStyle="chip specialOperations liteSpecial"
          />
        </>
      );
    }

    return "";
  };

  return (
    <div className="rows">
      <div className="headerBoxRow">
        <div className="headerBoxFlex">{getChip(column)}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
           
          }}>
            {" "}
            {validInputExist(column) && (
              <div>
                {!column.fillValue?.operation && (
                  <AddChip
                    titleValue="Add Input one by one"
                    value={column.id}
                    addValue={addHeaderValue}
                    classStyle="addBoxOne"
                  />
                )}

                {!column.fillValue && (
                  <>
                    <AddChip
                      titleValue="Add Excluding Input"
                      value={column}
                      addValue={addExcludingValue}
                      classStyle="addBoxExcluding"
                    />
                    <AddChip
                      titleValue="Add Input with condition"
                      value={column}
                      addValue={addConditionalValue}
                      classStyle="addBoxCondition"
                    />
                  </>
                )}
              
              </div>
            )}
            {!column.fillValue & !column.conditionToColumn &&
            !column.conditionToValue ? (
              <AddChip
                titleValue="Add Special Operation"
                value={column}
                addValue={addFillValue}
                classStyle="addBoxFill"
              />
            ) : null}
            {column.customColumn ? (
              <Tooltip title={"Delete"} placement="bottom-start">
                <IconButton
                  className="buttonDeleteCustomColumn"
                  onClick={() => {
                    deleteCustomColumn(column.id);
                  }}
                >
                  <DeleteForever className="iconHeaderBox" fontSize="medium" />
                </IconButton>
                
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderBox;
