import { useEffect, useState, useReducer } from "react";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useParams } from "react-router-dom";
import { _put, _get, decodeUser } from "../../common/generalRequests";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import HeaderBox from "./components/headerBox/HeaderBox";
import ModalAddHeader from "./components/ModalAddHeader";
import ModalAddCondition from "./components/ModalAddCondition";
import ModalAddFill from "./components/ModalAddFill";
import ModalAddExcluding from "./components/ModalAddExcluding";
import ModalAddCustomColumn from "./components/ModalAddCustomColumn";
import ProgressBar from "../../common/components/progressBar/ProgressBar.js";
import "./linkCollectionToFile.css";
import { UnauthorizedMessage } from "../../common/helpers/UnauthorizedMessage";

function LinkCollectionToFile() {
  const navigate = useNavigate();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  let { uid } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [processName, setProcessName] = useState("");
  const [linkedHeaders, setLinkedHeaders] = useState([]);
  const [inputHeaders, setInputHeaders] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [openModalAddHeader, setOpenModalAddHeader] = useState(false);
  const [openModalAddCondition, setOpenModalAddCondition] = useState(false);
  const [openModalAddFill, setOpenModalAddFill] = useState(false);
  const [openModalExcluding, setOpenModalExcluding] = useState(false);
  const [ready, setReady] = useState(false);
  const [idColumn, setIdColumn] = useState(null);
  const [conditionalColumn, setConditionalColumn] = useState({});
  const [excludingColumn, setExcludingColumn] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [openModalAddCustomColumn, setOpenModalAddCustomColumn] =
    useState(false);
  const [columnCounter, setColumnCounter] = useState(0);
  const [newcustomColumns, setNewCustomColumns] = useState(0);
  const limiterCustomColumns = 100;
  const [dataChangedCustom, setDataChangedCustom] = useState(false);
  const [clientSelected, setClientSelected] = useState("");
  const [idClient, setIdClient] = useState(null);
  const [modelID, setModelID] = useState(null);
  const [queryColumns, SetQueryColumns] = useState(null);

  let user = decodeUser(localStorage.getItem("USER"));
  //useVerifyRole([0,1])
  let roles = [0, 1];
  useEffect(() => {
    if (!roles.includes(user.rolUser)) {
      navigate("/home");
      UnauthorizedMessage();
    } else {
      swal({
        title: "Info",
        text: "For correct operation, the variables of the selected model will be shown as required",
        icon: "info",
        buttons: true,
      }).then(() => {});
    }

    _get(
      `/condition`,
      (response) => {
        if (response.data.ok) {
          setConditionOptions(response.data.conditions);
        }
      },
      () =>
        swal(
          "Error",
          "Error obtaining information from calculations",
          "error"
        ).then(() => navigate("/home"))
    );
    _get(
      `/process/${uid}`,
      async (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          const dataProcess = res.data.oneProcess;
          const processHeaders = dataProcess.linkedColumns
            ? JSON.parse(dataProcess.linkedColumns)
            : [];
          setNewCustomColumns(processHeaders.length);
          setProcessName(dataProcess.processName);

          setModelID(dataProcess.idModel);

          _get(
            `/models/${dataProcess.idModel}`,
            (res2) => {
              if (res2.data.ok) {
                SetQueryColumns(res2.data.queryColumns);
              }
            },
            (error) =>
              swal("Error", `${error}`, "error").then(() => navigate("/home"))
          );

          if (processHeaders.length > 0) {
            setLinkedHeaders(processHeaders);
            setColumnCounter(
              processHeaders.filter((col) => col.customColumn).length
            );
          } else {
            const idModel = await getIdProcessFromIdModel(dataProcess.idModel);
            _get(
              `/columns/${idModel}`,
              (res1) => {
                if (res1.data.ok) {
                  let columnsArray = res1.data.column.map((column) => {
                    column.fileHeader = [];
                    column.conditionToColumn = null;
                    column.conditionToValue = null;
                    return column;
                  });
                  setLinkedHeaders(columnsArray);
                }
              },
              (error) =>
                swal("Error", `${error}`, "error").then(() => navigate("/home"))
            );
          }
          let dataCalculate = JSON.parse(res.data.oneProcess.headers);
          let inputHeadersArr = [];
          Object.entries(dataCalculate).forEach(([key, value]) => {
            let obj = {
              name: key,
              headersByFile: value,
            };
            inputHeadersArr.push(obj);
          });
          setInputHeaders(inputHeadersArr);
          setLoading(false);
        }
      },
      (error) =>
        swal("Error", "Error obtaining process information", "error").then(() =>
          navigate("/home")
        )
    );
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  const getIdProcessFromIdModel = async (idModel) => {
    let idProcessType = null;
    await _get(
      `/models/${idModel}`,
      (res) => {
        if (res.data.ok) {
          idProcessType = res.data.model.idProcesstype;
        }
      },
      (_error) =>
        swal("Error", "An error occurred in the database query", "error").then(
          () => navigate("/home")
        )
    );
    return idProcessType;
  };

  const addHeaderValue = (idColumn) => {
    if (verifyClient(null, uid)) {
      setIdColumn(idColumn);
      setOpenModalAddHeader(true);
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const removeHeaderValue = (index, value, idColumn) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);

      let newHeaders = linkedHeaders.map((head) => {
        if (head.id === idColumn) {
          head.fileHeader.splice(index, 1);
        }
        return head;
      });
      setLinkedHeaders(newHeaders);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const removeConditionalHeader = (idColumn) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newHeaders = linkedHeaders.map((head) => {
        if (head.id === idColumn) {
          head.conditionToValue = null;
        }
        return head;
      });
      setLinkedHeaders(newHeaders);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const removeSpecialOp = (idColumn) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newHeaders = linkedHeaders.map((head) => {
        if (head.id === idColumn) {
          head.fillValue = null;
        }
        return head;
      });
      setLinkedHeaders(newHeaders);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const removeExcludingHeader = (idColumn) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newHeaders = linkedHeaders.map((head) => {
        if (head.id === idColumn) {
          head.conditionToColumn = null;
        }
        return head;
      });
      setLinkedHeaders(newHeaders);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };
  const getMessage = () => {
    let requiredEmpty = linkedHeaders
      .filter(
        (column) =>
          column.required &&
          column.fileHeader == 0 &&
          !column.conditionToColumn &&
          !column.conditionToValue &&
          !column.fillValue
      )
      .map((columns) => {
        return columns.description;
      });
    let requiredEmptyString = "";
    requiredEmpty.forEach((nameCol) => {
      requiredEmptyString += nameCol + ", ";
    });
    return (
      "The required variables " +
      requiredEmptyString +
      " have not been mapped, this generates an incorrect operation of the analytics."
    );
  };
  const saveAndContinue = () => {
    if (verifyClient(null, uid)) {
      if (
        linkedHeaders.filter(
          (column) =>
            column.required &&
            column.fileHeader == 0 &&
            !column.conditionToColumn &&
            !column.conditionToValue &&
            !column.fillValue
        ).length > 0
      ) {
        swal({
          title: "Are you sure you want to continue?",
          text: getMessage(),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((save) => {
          if (save) {
            saveDataRelations();
          }
        });
      } else {
        if (dataChanged) {
          swal({
            title: "Are you sure you want to continue?",
            text: "Remember to check the relationships before continuing",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((save) => {
            if (save) {
              saveDataRelations();
            }
          });
        } else {
          navigate(`/validation/${uid}`);
        }
      }
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const verifyClient = async (nav = null, idProc) => {
    if (nav) {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            navigate(nav);
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            ).then(() => {});
          }
          setLoading(false);
        },
        (error) =>
          swal("error", "Client not authorized to perform this action", "error")
      ).then(() => {});
    } else {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            return true;
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            ).then(() => {});
            return false;
          }
        },
        (error) =>
          swal("error", "Client not authorized to perform this action", "error")
      ).then(() => {});
    }
  };

  const saveDataRelations = () => {
    let data = {};

    if (dataChangedCustom) {
      let customColumns = linkedHeaders
        .filter((col) => col.customColumn)
        .map((col, index) => {
          col.columnName = `var${index}`;
          linkedHeaders[
            linkedHeaders.findIndex((object) => {
              return object.id === col.id;
            })
          ] = col;
          return col;
        });
      customColumns.forEach((col) => {
        linkedHeaders[
          linkedHeaders.findIndex((object) => {
            return object.id === col.id;
          })
        ] = col;
      });
      data = {
        linkedColumns: JSON.stringify(linkedHeaders),
        validationColumn: "[]",
      };
    } else {
      data = {
        linkedColumns: JSON.stringify(linkedHeaders),
        validationColumn: "[]",
      };
    }

    _put(
      `/editProcess/${uid}`,
      data,
      (res) => {
        if (res.data.ok) {
          navigate(`/validation/${uid}`);
        }
      },
      (error) =>
        swal(
          "Error",
          "It was not possible to save the information, please try again.",
          "error"
        )
    );
  };

  const addHeaderToColumn = (fileHeader) => {
    setDataChanged(true);
    let newHeaders = linkedHeaders.map((head) => {
      if (head.id === idColumn) {
        head.fileHeader.push(fileHeader);
        head.conditionToColumn = null;
      }
      return head;
    });
    setLinkedHeaders(newHeaders);
    setIdColumn(null);
    setOpenModalAddHeader(false);
    forceUpdate();
  };

  const addExcludingHeader = (idColumn, header) => {
    setDataChanged(true);
    let newHeaders = linkedHeaders.map((head) => {
      if (head.id === idColumn) {
        head.conditionToColumn = header;
        head.fileHeader = [];
        head.conditionToValue = null;
      }
      return head;
    });
    setLinkedHeaders(newHeaders);
    setExcludingColumn({});
    setOpenModalExcluding(false);
    forceUpdate();
  };

  const addConditionalHeader = (idColumn, condition) => {
    setDataChanged(true);
    let newHeaders = linkedHeaders.map((head) => {
      if (head.id === idColumn) {
        head.conditionToValue = condition;
      }
      return head;
    });
    setLinkedHeaders(newHeaders);
    setConditionalColumn({});
    setOpenModalAddCondition(false);
    forceUpdate();
  };

  const cancelAddHeaderToColumn = () => {
    setIdColumn(null);
    setOpenModalAddHeader(false);
  };

  const addConditionalValue = (column) => {
    if (verifyClient(null, uid)) {
      setConditionalColumn(column);
      setOpenModalAddCondition(true);
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const cancelAddConditionalValue = () => {
    setConditionalColumn({});
    setOpenModalAddCondition(false);
  };

  const addFillValue = (column) => {
    if (verifyClient(null, uid)) {
      setConditionalColumn(column);
      setOpenModalAddFill(true);
    } else {
      swal(
        "error",
        "Client not authorized to perform this actionhandleClose",
        "error"
      ).then(() => {});
    }
  };

  const cancelAddFillValue = () => {
    setConditionalColumn({});
    setOpenModalAddFill(false);
  };

  const saveFillValue = (idColum, fillValue) => {
    setDataChanged(true);
    const newHeaders = linkedHeaders.map((head) => {
      if (head.id === idColum) head.fillValue = fillValue;
      return head;
    });

    setLinkedHeaders(newHeaders);
    setConditionalColumn({});
    setOpenModalAddCondition(false);
    forceUpdate();
  };

  const addExcludingValue = (column) => {
    if (verifyClient(null, uid)) {
      setExcludingColumn(column);
      setOpenModalExcluding(true);
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const cancelAddExcluding = () => {
    setExcludingColumn({});
    setOpenModalExcluding(false);
  };

  const handleAddCustomColumn = () => {
    if (verifyClient(null, uid)) {
      setOpenModalAddCustomColumn(true);
    } else {
      swal("error", "Client not authorized to perform this action", "error");
      return false;
    }
  };

  const addCustomColumn = (columnName) => {
    if (columnCounter >= limiterCustomColumns) {
      swal(
        "error",
        `Max number of columns is 100 current # of columns: ${columnCounter}`,
        "error"
      );
      return false;
    } else {
      setDataChanged(true);
      setDataChangedCustom(true);
      linkedHeaders.push({
        customColumn: true,
        id: linkedHeaders[linkedHeaders.length - 1].id + 1,
        idProcessType: linkedHeaders[0].idProcessType,
        description: columnName,
        columnName: "",
        required: false,
        fileHeader: [],
        conditionToColumn: null,
        conditionToValue: null,
      });
    }
    setOpenModalAddCustomColumn(false);
    forceUpdate();
  };

  const deleteCustomColumn = (id) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      setDataChangedCustom(true);
      let newlinkedHeaders = linkedHeaders.filter((column) => column.id != id);
      setColumnCounter(linkedHeaders.filter((col) => col.customColumn).length);
      setLinkedHeaders(newlinkedHeaders);
      forceUpdate();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const updateCustomColumnsList = () =>{
    setColumnCounter(linkedHeaders.filter((col) => col.customColumn).length);
  }

  const getFormStep = () => {
    return (
      <div>
        <div>
          <div className="containerTableLink">
            <div>
              <button
                className="addCustomColum"
                onClick={() => {
                  handleAddCustomColumn();
                }}
              >
                <PostAddIcon />
                Add Custom Column
              </button>
            </div>
          </div>
          <div className="first_rowLink">
            <div className="titleTableLink">Output</div>
            <div className="titleTableLink">Input</div>
            <div className="titleTableLink">Actions</div>
          </div>
          <div className="contentTableLink">
            {queryColumns
              ? linkedHeaders.map((header, index) => (
                  <div
                    className={
                      header.required ||
                      queryColumns.includes(header.columnName)
                        ? "rowsLinks required"
                        : "rowsLinks"
                    }
                    key={index}
                  >
                    <>
                      <div className="subtitleTableLink indexLink">
                        {index + 1 + "."}
                      </div>
                      <div
                        className="subtitleTableLink descriptionLink"
                        key={header.id}
                      >
                        {" "}
                        {header.description +
                          (header.required ||
                          queryColumns.includes(header.columnName)
                            ? " (Required)"
                            : "")}{" "}
                      </div>
                    </>
                    <div className="headersBoxes">
                      <HeaderBox
                        column={header}
                        key={index}
                        addHeaderValue={addHeaderValue}
                        removeHeaderValue={removeHeaderValue}
                        addConditionalValue={addConditionalValue}
                        addFillValue={addFillValue}
                        addExcludingValue={addExcludingValue}
                        removeConditionalHeader={removeConditionalHeader}
                        removeExcludingHeader={removeExcludingHeader}
                        deleteCustomColumn={deleteCustomColumn}
                        removeSpecialOp={removeSpecialOp}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    );
  };

  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar clientName={clientSelected} />
        <ProgressBar
          nameProcess={processName}
          nextStep={saveAndContinue}
          buttonOn={true}
          backStep={`/inputfile/${uid}`}
          backProcess={navigate}
        />
        <div className="common">
          <div className="boxLink">
            <div className="containerLink">{getFormStep()}</div>
          </div>
        </div>
        <ModalAddHeader
          open={openModalAddHeader}
          inputHeaders={inputHeaders}
          addHeaderToColumn={addHeaderToColumn}
          handleClose={cancelAddHeaderToColumn}
        />
        <ModalAddCondition
          conditionalColumn={conditionalColumn}
          inputHeaders={inputHeaders}
          valOptions={conditionOptions}
          open={openModalAddCondition}
          addCondition={addConditionalHeader}
          handleClose={cancelAddConditionalValue}
        />
        <ModalAddFill
          saveFillValue={saveFillValue}
          conditionalColumn={conditionalColumn}
          inputHeaders={inputHeaders}
          valOptions={conditionOptions}
          open={openModalAddFill}
          handleClose={cancelAddFillValue}
        />
        <ModalAddExcluding
          open={openModalExcluding}
          excludingColumn={excludingColumn}
          inputHeaders={inputHeaders}
          addHeaderToColumn={addHeaderToColumn}
          addExcludingHeader={addExcludingHeader}
          handleClose={cancelAddExcluding}
        />
        <ModalAddCustomColumn
          open={openModalAddCustomColumn}
          handleClose={() => {
            setOpenModalAddCustomColumn(false);
          }}
          addCustomColumn={addCustomColumn}
          updateCustomColumnsList={updateCustomColumnsList}
        />
      </div>
    </div>
  );
}

export default LinkCollectionToFile;
