import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import closeIcon from "../../../../assets/svg/CancelarGris.svg";
import { _post, _get } from "../../../../common/generalRequests";
import swal from "sweetalert";
import RenderLoader from "../../../../common/helpers/RenderLoader";
import Box from "@mui/material/Box";

const ModalCreateStrategy = ({ open, handleClose, processData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [clientofProcessSelected, setClientofProcessSelected] = useState("");
  const [processSelected, setProcessSelected] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      _get(
        `/process/${processData}`,
        (res) => {
          if (res.data.ok) {
            if (!res.data.oneProcess.headers) {
              swal(
                "Warning",
                "configure your process , upload file please",
                "warning"
              );
              setLoading(false);
              navigate(`/inputfile/${processData}`);
            }
            setProcessSelected(res.data.oneProcess);
          }
        },
        () => {
          swal("Error", "An Error has occurred", "error");
          setLoading(false);
        }
      );
    }
  }, [open]);

  useEffect(() => {
    if (open && processSelected !== []) {
      _get(
        `/client/${processSelected.idClient}`,
        (response) => {
          setClientofProcessSelected(response.data.client[0].fullname);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [processSelected]);

  const verifyColumns = () => {
    if (
      processSelected.uid &&
      processSelected.idClient &&
      name &&
      description
    ) {
      createNewStrategy();
    } else {
      swal("Warning", "Faltan campos por completar", "warning");
      setLoading(false);
    }
  };

  const createNewStrategy = () => {
    const data = {
      name,
      description,
      idClient: processSelected.idClient,
      idProcess: processSelected.uid,
    };

    _post(
      "/strategy",
      data,
      (res) => {
        if (res.data.ok) {
          navigate("/inputStrategy/", {
            state: {
              linkedColumns: processSelected.linkedColumns,
              strategy: res.data.strategy[0],
              uidProcess: processSelected.uid,
            },
          });
        } else {
          swal("Error", "An Error has occurred1", "error").then(() =>
            navigate(0)
          );
        }
      },
      () =>
        swal("Error", "An Error has occurred2", "error").then(() => navigate(0))
    );
  };

  const modalContent = () => {
    return (
      <div className="paperCreateCollection">
        <img src={closeIcon} alt="close" onClick={() => handleClose()} />
        <div className="titleModal">Define New Strategy</div>

        <label className="labelModal">Customer name</label>
        <span className="descriptionInputModal">
          This name must be clear and precise to avoid confusion
        </span>
        <input
          value={clientofProcessSelected}
          disabled
          className="inputModal"
        />

        <label className="labelModal">Name of process</label>
        <span className="descriptionInputModal">
          This name must be clear and precise to avoid confusion
        </span>
        <input
          value={processSelected.processName}
          disabled
          className="inputModal"
        />

        <label className="labelModal">Strategy name</label>
        <span className="descriptionInputModal">
          This name must be clear and precise to avoid confusion
        </span>
        <input
          className="inputModal"
          onChange={(event) => setName(event.target.value)}
        />

        <label className="labelModal">Strategy Description</label>
        <span className="descriptionInputModal">
          This description must be clear and precise to avoid confusion.
        </span>
        <input
          className="inputModal"
          onChange={(event) => setDescription(event.target.value)}
        />
        <button
          className="buttonModal"
          onClick={() => {
            setLoading(true);
            verifyColumns();
          }}
        >
          Create
        </button>
      </div>
    );
  };

  return (
    <div>
      <RenderLoader loading={loading} />
      <Modal open={open} onClose={handleClose} className="modal">
        <Box sx={{ width: "33%" }}>{modalContent()}</Box>
      </Modal>
    </div>
  );
};

export default ModalCreateStrategy;
