import IconButton from "@mui/material/IconButton";
import React from "react";
import { AddBoxTwoTone } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import "./headerBox.css";

function AddChip({ addValue, titleValue,value,classStyle }) {
  return (
    <Tooltip title={titleValue} placement="bottom-start">
 <IconButton
   className="buttonBackground"
   onClick={() => {
     addValue(value);
   }}
 >
   <AddBoxTwoTone fontSize="small" className={classStyle} />
 </IconButton>
</Tooltip>
  );
}

export default AddChip;












