import { useState } from "react";
import analyticsActivo from "../../../assets/svg/AnalyticsActivo.svg";
import fileconverterActivo from "../../../assets/svg/FileConverterActivo.svg";
import analyticsInactivo from "../../../assets/svg/AnalyticsInactivo.svg";
import channelSelectorInactivo from "../../../assets/svg/ChannelSelectorInactivo.svg";
import businessStrategyInactivo from "../../../assets/svg/BusinessStrategyInactivo.svg";
import resultsInactivo from "../../../assets/svg/ResultsInactivo.svg";
import fileconverterCompleto from "../../../assets/svg/FileConverterCompleto.svg";
import analyticsCompleto from "../../../assets/svg/AnalyticsCompleto.svg";
import channelSelectorCompleto from "../../../assets/svg/ChannelSelectorCompleto.svg";
import businessStrategyComplete from "../../../assets/svg/BusinessStrategyCompleto.svg";
import resultsActive from "../../../assets/svg/ResultsActivo.svg";
import channelSelectorActivo from "../../../assets/svg/ChannelSelectorActivo.svg";
import { useNavigate } from "react-router";
import backIcon from "../../../assets/images/left-arrow.png";
import guardarIcono from "../../../assets/svg/GuardarIcono.svg";
import businessStrategyActivo from "../../../assets/svg/BusinessStrategyActivo.svg";

function ProgressBar({
  nameProcess,
  nextStep,
  nextParameter = null,
  backStep,
  buttonOn,
  backProcess,
  state,
  uidProcess = null,
  nextButtonLabel = "Save & next",
  stepIcons = "fileConverter",
}) {
  const navigate = useNavigate();

  let arrayIndex = 0;
  if (stepIcons == "analytics") {
    arrayIndex = 1;
  } else if (stepIcons == "channelSelector") {
    arrayIndex = 2;
  } else if (stepIcons == "strategy") {
    arrayIndex = 3;
  } else if (stepIcons == "results") {
    arrayIndex = 4;
  }

  const iconsArray = [
    [
      fileconverterActivo,
      analyticsInactivo,
      channelSelectorInactivo,
      businessStrategyInactivo,
      resultsInactivo,
    ],
    [
      fileconverterCompleto,
      analyticsActivo,
      channelSelectorInactivo,
      businessStrategyInactivo,
      resultsInactivo,
    ],
    [
      fileconverterCompleto,
      analyticsCompleto,
      channelSelectorActivo,
      businessStrategyInactivo,
      resultsInactivo,
    ],
    [
      fileconverterCompleto,
      analyticsCompleto,
      channelSelectorCompleto,
      businessStrategyActivo,
      resultsInactivo,
    ],
    [
      fileconverterCompleto,
      analyticsCompleto,
      channelSelectorCompleto,
      businessStrategyComplete,
      resultsActive,
    ],
  ];
  const stylesArray = [
    [
      "nameProgressActive",
      "nameProgress",
      "nameProgress",
      "nameProgress",
      "nameProgress",
    ],
    [
      "nameProgressActive",
      "nameProgressActive",
      "nameProgress",
      "nameProgress",
      "nameProgress",
    ],
    [
      "nameProgressActive",
      "nameProgressActive",
      "nameProgressActive",
      "nameProgress",
      "nameProgress",
    ],
    [
      "nameProgressActive",
      "nameProgressActive",
      "nameProgressActive",
      "nameProgressActive",
      "nameProgress",
    ],
    [
      "nameProgressActive",
      "nameProgressActive",
      "nameProgressActive",
      "nameProgressActive",
      "nameProgressActive",
    ],
  ];

  return (
    <div className="progressBar">
      <span className="nameProcessProgressBar">{nameProcess}</span>
      <div className="progressIcons">
        <div className="iconsContainer">
          <img src={iconsArray[arrayIndex][0]} alt="file converte" />
          <span className={stylesArray[arrayIndex][0]}>File Converter</span>
        </div>

        <span>.............</span>
        <div className="iconsContainer">
          <img src={iconsArray[arrayIndex][1]} alt="analytics" />
          <span className={stylesArray[arrayIndex][1]}>analytics</span>
        </div>

        <span>.............</span>
        <div className="iconsContainer">
          <img src={iconsArray[arrayIndex][2]} alt="channel Selector" />
          <span className={stylesArray[arrayIndex][2]}>Channel Selector</span>
        </div>

        <span>.............</span>
        <div className="iconsContainer">
          <img src={iconsArray[arrayIndex][3]} alt="bussines Strategy" />
          <span className={stylesArray[arrayIndex][3]}>Business Strategy</span>
        </div>

        <span>.............</span>
        <div className="iconsContainer">
          <img
            className="resultIcon"
            src={iconsArray[arrayIndex][4]}
            alt="result"
          />
          <span className={stylesArray[arrayIndex][4]}>Results</span>
        </div>
      </div>

      <div className="progressButtonsContainer">
        <button
          className="buttonSaveNext"
          onClick={() => {
            !uidProcess
              ? !state ? backProcess(backStep) : navigate(backStep, {
                state: state,
              })
              : backProcess(backStep, uidProcess);
          }}
        >
          <img src={backIcon} alt="Guardar" className="buttonBackIcon" /> last step
        </button>



        {buttonOn ? (
          <button
            className="buttonSaveNext"
            onClick={() => {
              nextStep(nextParameter);
            }}
          >
            {" "}
            <img
              src={guardarIcono}
              alt="Guardar y siguiente"
              className="buttonIcon"
            />{" "}
            {nextButtonLabel}
          </button>
        ) : !nextStep  ? null : (
          <button
            className="buttonSaveNext disabled"
            disabled
            onClick={() => {
              nextStep(nextParameter);
            }}
          >
            {" "}
            <img
              src={guardarIcono}
              alt="Guardar y siguiente"
              className="buttonIcon"
            />{" "}
            Save & next
          </button>
        )}
      </div>
    </div>
  );
}

export default ProgressBar;
