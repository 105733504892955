import swal from "sweetalert";


export const  UnauthorizedMessage = () => {
    swal({
        title: "Info",
        text: "the user is not authorized to access this functionality.",
        icon: "info",
        buttons: true,
      }).then(() => {});
};