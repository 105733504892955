import { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";

import InteractionCard from "./InteractionCard";

import "./interactionCard.css";

const rfStyle = {
  backgroundColor: "#fff",
};

const nodeTypes = { interactionCard: InteractionCard };

const initialEdges = [
  /*{ id: '2-4', source: 'node-2', target: 'node-4' }, { id: '4-3', source: 'node-4', target: 'node-3' }*/
];

const initialNodes = [];

function InteractionFlow() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const [array, setArray] = useState([
    {
      idNode: "1-1",
      nameChannel: "SMS",
      level:1,
      sublevel:1,
      idChannel: "F19674A6-E50E-4BE5-8702-3A9B72FDBA43",
      mainNode: true,
      frequency: "24h",
      idTemplate: "",
      rule: [
        {
          idTargetNode: "2-1",
          idCondition: "1",
        },
        {
          idTargetNode: "2-2",
          idCondition: "1",
        },
      ],
    },
    {
      idNode: "2-1",
      level:2,
      sublevel:1,
      nameChannel: "SMS",
      idChannel: "F19674A6-E50E-4BE5-8702-3A9B72FDBA43",
      mainNode: false,
      frequency: "24h",
      idTemplate: "",
      rule: [ ],
    },
    {
      idNode: "2-2",
      level:2,
      sublevel:2,
      nameChannel: "TPMarketing",
      idChannel: "D1E2F938-DCDC-4A33-80B7-3D87DBEE8DE6",
      mainNode: false,
      frequency: "24h",
      idTemplate: "",
      rule: [ ],
    },
  ]);
  const [switche, setSwitche] = useState(0);

  useEffect(() => {
    generateNodes();
  }, [array]);

  useEffect(() => {
    generateNodes();
  }, [switche]);

  useEffect(() => {
    console.log("nodes");
  }, [nodes]);

  const generateNodes = () => {
    let dinamicNodes = [];
    

    array.forEach((element, index) => {
      
      let levelNodes = array.filter(node => node.level == element.level)

      dinamicNodes.push({
        id: element.idNode,
        type: "interactionCard",
        sourcePosition: "right",
        targetPosition: "left",
        position: levelNodes.length == 1 ? { x: index + 1 * 25 + index * 300, y: 25 } : levelNodes.length == 2 ? { x: index + 1 * 25 + index * 300, y: 100*element.sublevel  } : { x: index + 1 * 25 + index * 300, y: 100* element.sublevel  },
        data: {name: element.nameChannel, addNodeExt: addNode },
      });
    });

    setNodes(dinamicNodes);

    return dinamicNodes;
  };

  const addNode = (id) => {
    let arrayNew = array;
    let numero = parseInt(array.at(-1)) + 1 + "-" + "1";
    numero = String(numero);
    arrayNew.push(numero);
    setArray(arrayNew);

    console.log(array);

    setSwitche(switche + 1);
    //array.at(-1)+1
  };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  return (
    <div style={{ width: "100%" }}>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        style={rfStyle}
      >
        <Background />
      </ReactFlow>
    </div>
  );
}

export default InteractionFlow;
