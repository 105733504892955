import { useState } from "react";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/material/styles";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import "./modalAddCalculation.css";



const CssTextField = styled(TextField)(({ theme }) => ( {
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
    margin: "0px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
      margin: "0px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddCalculation = ({
  open,
  handleClose,
  inputHeaders,
  addCalculation,
  calOptions,
}) => {
  const [headers, setHeaders] = useState([]);
  const [headerSelected, setHeaderSelected] = useState([]);
  const [fileSelected, setFileSelected] = useState({});
  const [calculationSelected, setCalculationSelected] = useState(null);
  const [textFromNotRequired, setTextFromNotRequired] = useState(false);
  const [textFrom, setTextFrom] = useState("");
  const [textTo, setTextTo] = useState("");

  const getHeadersByFile = (value) => {
    if (value) {
      setFileSelected(value.name);
      setHeaders(value.headersByFile);
    } else {
      setHeaderSelected([]);
      setHeaders([]);
    }
  };

  const addCalculationFromModal = () => {
    if (calculationSelected !== {} && textTo && fileSelected) {
      let newCalculation = {
        idCalculation: calculationSelected.id,
        text1: calculationSelected.texto1,
        info1: textFrom,
        text2: calculationSelected.texto2,
        info2: textTo,
      };
      addCalculation(fileSelected, headerSelected, newCalculation);
    }
  };

  const closeModal = () => {
    setFileSelected([]);
    setCalculationSelected(null);
    setTextFrom("");
    setTextTo("");
    handleClose();
  };

  const handleChangeCondition = (value) => {
    if (value) {
      setCalculationSelected(value);
      if (value.id === 3 || value.id === 4) {
        setTextFromNotRequired(true);
      } else {
        setTextFromNotRequired(false);
      }
    }
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      className="modalAddCalculation"
    >
      <div className="paperModalAddCalculation">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            closeModal();
          }}
          className="closeIcon"
        />
        <div className="column">
          <div className="titleModal">Add New Rule</div>
        </div>
        <label className="labelModal">Input File</label>
        <FormControl required={true} className="formControlModalAddCalculation">
          <Autocomplete
            className="selectModalAddCalculation"
            onChange={(_event, newValue) => {
              getHeadersByFile(newValue);
            }}
            getOptionLabel={(option) => option.name}
            options={inputHeaders}
            renderInput={(params) => (
              <CssTextField {...params} variant="outlined" />
            )}
          />
        </FormControl>
        <label className="labelModal">Input column</label>
        <FormControl className="formControlModalAddCalculation">
          <Autocomplete
            className="selectModalAddCalculation"
            onChange={(_event, newValue) => {
              setHeaderSelected(newValue);
            }}
            getOptionLabel={(option) => option.Header}
            options={headers}
            renderInput={(params) => (
              <CssTextField {...params} variant="outlined" />
            )}
          />
        </FormControl>
        <label className="labelModal">Condition</label>
        <FormControl className="formControlModalAddCalculation">
          <Autocomplete
            className="selectModalAddCalculation"
            onChange={(_event, newValue) => {
              handleChangeCondition(newValue);
            }}
            getOptionLabel={(option) => option.texto1}
            options={calOptions}
            renderInput={(params) => (
              <CssTextField {...params} variant="outlined" />
            )}
          />
        </FormControl>
        <label className="labelModal">Contains or equals:</label>
        <input
          disabled={textFromNotRequired}
          className="inputModalAddCalculation"
          name="name"
          id="name"
          variant="outlined"
          onChange={(event) => {
            setTextFrom(event.target.value);
          }}
        />
        <label className="labelModal">Replace with:</label>
        <input
          className="inputModalAddCalculation"
          name="name"
          id="name"
          onChange={(event) => {
            setTextTo(event.target.value);
          }}
        />

        <button
          className="buttonModalAddCalculation"
          onClick={() => {
            addCalculationFromModal();
          }}
        >
          Add
        </button>
      </div>
    </Modal>
  );
};

export default ModalAddCalculation;
