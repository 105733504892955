import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import { Textinput, onlyNumbers } from "../../../../../common/tools";
import { _post, _get } from "../../../../../common/generalRequests";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import closeIcon from "../../../../../assets/svg/CancelarGris.svg";
import "./modalUploadVersion.css";
const ModalUploadVersion = ({
  open,
  containerName,
  handleClose,
  setIsLoading,
}) => {
  const [versions, setVersions] = useState([]);
  const [file, setFile] = useState(null);
  const [nameVersion, setNameVersion] = useState(null);
  const [toggleUpload, setToggleUpload] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      _get(
        `/modelsVersion/${containerName}`,
        (res) => {
          if (res.data.ok) {
            setVersions(res.data.models);
            setIsLoading(false);
          } else {
            swal("Error", `${res.data.error}`, "error");
            setIsLoading(false);
          }
        },
        (error) => {
          swal("Error", `${error}`, "error");
          setIsLoading(false);
        }
      );
    }
  }, [open, toggleUpload]);

  const handleFileUpload = async (event) => {
    if (event) {
      setFile(event.target.files[0]);
    }
  };

  const uploadOneFile = async () => {
    if (file) {
      if (nameVersion) {
        if (!versions.some((version) => version.name == nameVersion)) {
          setIsLoading(true);
          const [accountName, containerVersionName] = [
            process.env.REACT_APP_ACCOUNT_NAME_ML,
            process.env.REACT_APP_CONTAINER_NAME_VERSION_ML_STORAGE,
          ];

          const formData = new FormData();
          formData.append("accountName", accountName);
          formData.append("containerName", containerVersionName);
          formData.append("modelVersion", 1);
          formData.append("file", file);
          _post(
            "/scripts/",
            formData,
            (res) => {
              if (res.data.ok) {
                let data = {
                  name: containerName,
                  version: nameVersion,
                  scriptName: file.name,
                };
                _post(
                  "/modelsVersion",
                  data,
                  (res) => {
                    if (res.data.ok) {
                      swal(
                        "Uploaded version",
                        "The version has been successfully uploaded",
                        "success"
                      );
                      setToggleUpload(!toggleUpload);
                    } else {
                      swal("Error", `${res.data.error}`, "error");
                    }
                  },
                  (error) => {
                    swal("Error", `${error}`, "error");
                  }
                );
              } else {
                swal("Error", `${res.data.error}`, "error");
              }
            },
            (error) => {
              swal("Error", `${error}`, "error");
            }
          );
        } else {
          swal(
            "Info",
            "There is already a version with that number, please change the version number",
            "warning"
          );
        }
      } else {
        swal("Info", "Please enter the version number", "warning");
      }
    } else {
      swal("Info", "You must upload a version file", "warning");
    }
  };

  const closeModal = () => {
    handleClose();
  };

  return (
    <Modal disableEscapeKeyDown open={open} className="modalUploadFiles">
      <div className="paperModalUploadFiles">
        <img
          src={closeIcon}
          alt="close"
          className="closeIcon"
          onClick={() => closeModal()}
        />
        <div className="column">
          <div className="titleModal">Add new version to {containerName}</div>
        </div>
        <div className="versionContainerTable">
          {versions.length > 0
            ? versions.map((version, index) => {
                return (
                  <>
                    <div className="versionContainerItem" key={index}>
                      <div>
                        <strong>Name:</strong> {containerName}{" "}
                      </div>{" "}
                      <div>
                        <strong> Version:</strong>
                        {version.name}
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </div>

        <TextField
          className="formcontrolVersion"
          name="file"
          margin="dense"
          id="file"
          type="file"
          variant="outlined"
          onChange={(event) => {
            handleFileUpload(event);
          }}
        />
        <Textinput
          label="Version number"
          type="number"
          onChange={(event) => {
            setNameVersion(event.target.value);
          }}
          fullWidth
          validate={onlyNumbers}
          className="formcontrolVersion"
          clearable={true}
          value={nameVersion}
        />

        <button
          className="uploadScriptButton buttonVersionMargin"
          onClick={() => {
            uploadOneFile();
          }}
        >
          Upload Script
        </button>
      </div>
    </Modal>
  );
};

export default ModalUploadVersion;
