import { useEffect, useState } from "react";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import ClientByGroup from "./clientsByGroup/ClientByGroup";
import StackedProductsByGroup from "./stackedProductsByGroup/StackedProductsByGroup";
import { _get } from "../../common/generalRequests";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import "./charts.css";
import GroupByAntiquity from "./groupByAntiquity/GroupByAntiquityChart";
import ProbabilityChart from "./probabilityChart/ProbabilityChart";
import DensityByGroup from "./densityByGroup/DensityByGroup";
import GroupByProductValue from "./groupByProductValue/GroupByProductValue";
import DistributionByProduct from "./distributionByProduct/DistributionByProduct";
import ProbabilityGroupByAttemps from "./probabilityGroupByAttemps/ProbabilityGroupsByAttemps";
import ProbabilityGroupByProducts from "./probabilityGroupByProducts/ProbabilityGroupByProducts";
import GroupByProductValueInterval from "./groupByProductValueInterval/groupByProductValueInterval";
import GroupByDebtInterval from "./groupByDebtInterval/groupByDebtInterval";
import ProbabilityGroupByDaysOfDebt from "./probabilityGroupByDaysOfDebt/ProbabilityGroupByDaysOfDebt";
import RecommendedChannelByHour from "./recommendedChannelByHour/RecommendedChannelByHour";
import ProgressBar from "../../common/components/progressBar/ProgressBar";
import RecommendedChannelByWeekday from "./recommendedChannelByWeekday/recommendedChannelByWeekday";
import RecommendedChannelByCost from "./recommendedChannelByCost/RecommendedChannelByCost";
import RecommendedChannelByAttemptsInterval from "./recommendedChannelByAttemptsInterval/RecommendedChannelByAttemptsInterval";
import RecommendedHour from "./recommendedHour/RecommendedHour";
import GroupByWeekday from "./groupByWeekday/GroupByWeekday";

function Charts() {
  const navigate = useNavigate();
  let { uid } = useParams();
  const [clientSelected, setClientSelected] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [idClient, setIdClient] = useState(null);
  const [idModel, setIdModel] = useState(null);
  const [nameProcess, setNameProcess] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          setNameProcess(res.data.oneProcess.processName);
          setIdModel(res.data.oneProcess.idModel);
          if (res.data.oneProcess.headers) {
            let headersFile = JSON.parse(res.data.oneProcess.headers);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      },
      (_error) =>
        swal("Error", "An error has occurred", "error").then(() =>
          navigate("/home")
        )
    );
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar clientName={clientSelected} />
        <ProgressBar
          nameProcess={nameProcess}
          nextStep={null}
          buttonOn={false}
          backStep={`/reports/${uid}`}
          state={{
            uploadId: state.uploadId
          }}
          backProcess={navigate}
          stepIcons="analytics"
          nextParameter={"siguiente"}
        />
        <div className="common">
          <div className="main-box">
            <div className="chartsContainer">
              {idModel !== 4 && idModel ? (
                <>
                  {" "}
                  <div className="chartBox">
                    <div className="titleCharts">Number of clients by group</div>
                    <div className="chartsDescription">
                      X Axis : Number of Clients || X Axis : Groups
                    </div>
                    <ClientByGroup />
                  </div>
                </>
              ) : null}

              {idModel == 2 || idModel == 3 ? (
                <>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Clients by group and product
                    </div>
                    <div className="chartsDescription">
                      Y Axis : Number of Clients || X Axis : Groups
                    </div>
                    <StackedProductsByGroup />
                  </div>
                </>
              ) : (
                ""
              )}

              {idModel !== 4 && idModel ? (<><div className="chartBox">
                <div className="titleCharts">Probability distribution</div>
                <div className="chartsDescription">
                  Y axis : Density || X axis : Probability
                </div>

                <DensityByGroup />
                <div className="titleCharts">probability chart</div>
                <div className="chartsDescription">
                  Y axis : Density || X axis : Probability
                </div>

                <ProbabilityChart />
              </div></>):null}

              
              {idModel == 2 || idModel == 3 ? (
                <>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Probability distribution by product
                    </div>
                    <div className="chartsDescription">
                      Y axis : Density || X axis : Probability
                    </div>

                    <DistributionByProduct />
                  </div>
                </>
              ) : (
                ""
              )}

              {idModel == 2 ? (
                <>       
                  <div className="chartBox">
                    <div className="titleCharts">
                      Group by product value interval
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%)|| X axis : Value of Product
                    </div>
                  </div>

                  <div className="chart">
                    <GroupByProductValue />
                  </div>

                  <div className="chartBox">
                    <div className="titleCharts">
                      Probability groups by attempts
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X axis : Number of
                      Attempts
                    </div>

                    <ProbabilityGroupByAttemps />
                  </div>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Probability groups by product
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X axis : Groups
                    </div>

                    <ProbabilityGroupByProducts />
                  </div>
                </>
              ) : (
                ""
              )}

              {idModel == 3 ? (
                <>
                  <div className="chartBox">
                    <div className="titleCharts">Quota Value Group Chart</div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X Axis: Quota Value
                    </div>
                  </div>
                  <div className="chart">
                    <GroupByProductValueInterval />
                  </div>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Graph of groups by debt value
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X axis: Debt value
                    </div>
                  </div>
                  <div className="chart">
                    <GroupByDebtInterval />
                  </div>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Graph of groups by day of arrears
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X axis: Days past due
                    </div>
                  </div>
                  <div className="chart">
                    <ProbabilityGroupByDaysOfDebt />
                  </div>
                </>
              ) : (
                ""
              )}

              {idModel == 4 ? (
                <>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Recommended channel per hour{" "}
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability(%) || X axis : Time
                    </div>
                  </div>
                  <div className="chart">
                    <RecommendedChannelByHour />
                  </div>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Recommended channel by day of the week
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X axis : Day of week
                    </div>
                  </div>
                  <div className="chart">
                    <RecommendedChannelByWeekday />
                  </div>

                  <div className="chartBox">
                    <div className="titleCharts">
                      Recommended channel by cost
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability(%) || X axis : Cost
                    </div>
                  </div>
                  <div className="chart">
                    <RecommendedChannelByCost />
                  </div>
                  <div className="chartBox">
                    <div className="titleCharts">
                      Channel recommended by attempts
                    </div>
                    <div className="chartsDescription">
                      Y axis : Probability group(%) || X axis : Number of
                      Attempts
                    </div>
                  </div>

                  <div className="chart">
                    <RecommendedChannelByAttemptsInterval />
                  </div>
                </>
              ) : (
                ""
              )}

              {idModel == 1 ? (
                <>
                  <div className="chartBox">
                    <div className="titleCharts">Recommended hours</div>
                    <div className="chartsDescription">
                      Y axis: Number of Clients || X axis : Recommended Hour
                    </div>

                    <RecommendedHour />
                  </div>
                  <div className="chartBox">
                    <div className="titleCharts">Recommended days</div>
                    <div className="chartsDescription">
                      Y axis: Number of Clients || X axis : Recommended Day
                    </div>
                  </div>
                  <div className="chart">
                    <GroupByWeekday />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Charts;
