import { useEffect, useState } from "react";
import ProgressBar from "../../common/components/progressBar/ProgressBar";
import BarChartIcon from "@mui/icons-material/BarChart";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import { useParams } from "react-router-dom";
import { _post, _get } from "../../common/generalRequests";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/ArrowDownward";
import * as XLSX from "xlsx";
import "./reports.css";

import "../../common/css/ProjectStyles.css";

function Reports() {
  const navigate = useNavigate();
  let { uid } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [processName, setProcessName] = useState([]);
  const [data, setData] = useState([]);
  const [idClient, setIdClient] = useState(null);
  const [clientSelected, setClientSelected] = useState("");
  const [uploadData, setUploadData] = useState(null);
  const { state } = useLocation();

  useEffect(() => {
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          setProcessName(res.data.oneProcess.processName);
        }
      },
      (error) =>
        swal("Error", "An error has occurred", "error").then(() =>
          navigate("/home")
        )
    );
    _get(
      `/processed/${uid}`,
      (res) => {
        if (res.data.ok) {
          let processedList = res.data.recordProcess;

          setData(processedList);
        }
      },
      (error) =>
        swal("Error", "An error has occurred", "error").then(() =>
          navigate("/home")
        )
    );
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  useEffect(() => {
    if (data.length > 0) {
      setUploadData(data.filter((upload) => upload.id === state.uploadId));
      setLoading(false);
    }
  }, [data]);

  const charts = (item) => {
    navigate(`/charts/${item.uidProcess}`, {
      state: {
        uploadId: item.id,
      },
    });
  };

  const downloadxls = (data, name, renameCustom = null) => {
    let ws; 
    if (renameCustom) {
      const headerRow = Object.keys(data[0]);
      const dataRows = data.map((obj) => Object.values(obj));
      for (let i = 0; i < headerRow.length; i++) {
        if (headerRow[i] && headerRow[i].includes("-Borrar")) {
          headerRow[i] = headerRow[i].replace("-Borrar", "");
        }
      }
      const arrayOfArrays = [headerRow, ...dataRows];

      ws = XLSX.utils.aoa_to_sheet(arrayOfArrays,{dense: true});
    } else {
      ws = XLSX.utils.json_to_sheet(data,{dense: true});
    }
    let wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "sheet");

    XLSX.writeFile(wb, `${name}.xlsx`, { FS: ";" });
    closeModalDownloading()
  };

  const handleConfirmDownload = (row) => {
    swal({
      title: "Confirm",
      text: `Are you sure you want to download the information from the process ${row.name}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((download) => {
      if (download) {
        setLoading(true);
        _get(
          `/processedbyid/${row.id}&${row.uidProcess}`,
          (res) => {
            if (res.data.ok) {
              setLoading(false);
              let renameCustom = false;
              if (res.data.dataProcess.length > 0) {
                res.data.dataProcess = res.data.dataProcess.map((record) => {
                  if (record.CustomColumns) {
                    let customColumns = JSON.parse(record.CustomColumns);
                    if (customColumns.length > 0) {
                      customColumns = customColumns.map((customCol) => {
                        customCol = JSON.parse(customCol);
                        return customCol;
                      });
                      record.CustomColumns = customColumns;

                      record.CustomColumns.forEach((custom) => {
                        if (record[custom.description] !== undefined) {
                          renameCustom = true;
                          record[`${custom.description}-Borrar`] = custom.value;
                        } else {
                          record[custom.description] = custom.value;
                        }
                      });
                      delete record.CustomColumns;
                      return record;
                    }
                  }

                  return record;
                });
                downloadxls(
                  res.data.dataProcess,
                  `data OK - ${processName} - ${row.id} - ${row.name}`,
                  renameCustom
                );
              } else {
                swal("Warning", "No records", "warning");
              }
            }
          },
          (error) => {
            swal("Error", "An error has occurred", "error").then(() =>
              navigate(0)
            );
          }
        );
      }
    });
  };

  const handleConfirmDownloadReport = (row) => {
    swal({
      title: "Confirm",
      text: `Are you sure you want to download the interaction report for the process ${row.name}`,
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then((download) => {
      let data = {
        idUpload: row.id,
        uidProcess: row.uidProcess,
      };
      _post("/channels/getReportChannel", data, (res) => {
        if (res.data.ok) {
          if (res.data.reportchannels.length > 0) {
            downloadxls(
              res.data.reportchannels,
              `Channel Report - ${processName} - ${row.id} - ${row.name}`
            );
          } else {
            swal("Warning", "No records", "warning");
          }
        } else {
          swal("Error", `An error has occurred ${res.data.error}`, "error");
        }
      });
    });
  };

  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  const closeModalDownloading = () => {
    swal("Success", "The file has been downloaded.", "success");
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar clientName={clientSelected} />
        <ProgressBar
          nameProcess={processName}
          nextStep={null}
          buttonOn={false}
          backStep={`/processfile/${uid}`}
          nextParameter={true}
          backProcess={navigate}
          nextButtonLabel={null}
          stepIcons="results"
        />
        <div className="table-container">
          <table>
            <thead>
              <tr className="tableHeader">
                <td>Upload Date: {uploadData ? uploadData[0].name : ""}</td>
                <td className="tableGrid">Download</td>
                <td className="tableGrid">Graphics</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> File Convert Processing:</td>
                <td className="tableGrid">
                  <IconButton
                    onClick={() => {
                      handleConfirmDownload(uploadData[0]);
                    }}
                  >
                    <FileDownloadIcon className="arrowIcon" fontSize="small" />
                  </IconButton>
                </td>
                <td className="tableGrid">
                  <IconButton
                    disabled
                    onClick={() => {
                      charts(uploadData[0]);
                    }}
                  >
                    <BarChartIcon className="arrowIcon" fontSize="small" />
                  </IconButton>
                </td>
              </tr>
              <tr>
                <td> Result of the analytical model:</td>
                <td className="tableGrid">
                  {uploadData ? (
                    <IconButton
                      onClick={() => {
                        handleConfirmDownload(uploadData[0]);
                      }}
                    >
                      <FileDownloadIcon
                        className="arrowIcon"
                        fontSize="small"
                      />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </td>
                <td className="tableGrid">
                  <IconButton
                    onClick={() => {
                      charts(uploadData[0]);
                    }}
                  >
                    <BarChartIcon className="arrowIcon" fontSize="small" />
                  </IconButton>
                </td>
              </tr>
              <tr>
                <td> Consolidated report by channels:</td>
                <td className="tableGrid">
                  <IconButton
                    onClick={() => {
                      handleConfirmDownloadReport(uploadData[0]);
                    }}
                  >
                    <FileDownloadIcon className="arrowIcon" fontSize="small" />
                  </IconButton>
                </td>
                <td className="tableGrid">
                  <IconButton
                    disabled
                    onClick={() => {
                      console.log("graficas");
                    }}
                  >
                    <BarChartIcon className="arrowIcon" fontSize="small" />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Reports;
