import { useState, useEffect } from "react";
import { _get } from "../../../common/generalRequests";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import "./modalAddCriteria.css";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
    margin: "0px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
      margin: "0px",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddCriteria = ({
  open,
  close,
  secundaries,
  addCriteria,
  conditionsSelect,
  priorities,
  idProcess,
}) => {
  const [secundarySelected, setSecundarySelected] = useState({});
  const [priority, setPriority] = useState("");
  const [interaction, setInteraction] = useState("");
  const [conditions, setConditions] = useState([]);
  const [interactions, setInteractions] = useState([]);

  useEffect(() => {
    setConditions([
      { condition: conditionsSelect[0], text: "", operator: null },
    ]);
    setInteraction("TPMarketing");
  }, [conditionsSelect]);

  useEffect(() => {
    if (idProcess !== "" && idProcess !== null && idProcess !== undefined) {
      getInteractions();
    }
  }, [open]);

  const getInteractions = () => {
    _get(
      `/process/${idProcess}`,
      (res) => {
        if (res.data.ok) {
          if (
            res.data.oneProcess.interactions !== null &&
            Object.entries(res.data.oneProcess.interactions).length !== 0
          ) {
            setInteractions(
              JSON.parse(res.data.oneProcess.interactions).interactions
            );
          }
        }
      },
      (error) => swal("Error", error.message, "error")
    );
  };

  const addCriteriaFromModal = () => {
    if (
      secundarySelected &&
      secundarySelected.id &&
      priority &&
      conditions[0].text !== ""
    ) {
      addCriteria(priority, secundarySelected, interaction, conditions);
      closeStates();
    } else {
      swal("Error", "Complete form to continue", "error");
    }
  };

  const closeStates = () => {
    setSecundarySelected({});
    setPriority("");
    setConditions([
      { condition: conditionsSelect[0], text: "", operator: null },
    ]);
  };

  const closeModal = () => {
    closeStates();
    close();
  };

  const handleIdCondition = (newCondition, index) => {
    const copy = [...conditions];
    if (!newCondition) {
      copy[index].text = "";
      setConditions(copy);
      return;
    }

    copy[index].condition = newCondition;
    setConditions(copy);
  };

  const handleOperatorCondition = (newOperator, index) => {
    const copy = [...conditions];
    copy[index].operator = newOperator.target.value;
    setConditions(copy);
  };

  const handleTextCondition = (newText, index) => {
    const copy = [...conditions];
    copy[index].text = newText;
    setConditions(copy);
  };

  const verifyConditions = (index) => {
    let copy = [...conditions];
    if (index !== 0) {
      copy.splice(index, 1);
    } else {
      copy.push({ condition: conditionsSelect[0], text: "", operator: "O" });
    }
    setConditions(copy);
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={close}
      className="modalAddCriteria"
    >
      <div className="paperAddCriteria">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            closeModal();
          }}
          className="closeIcon"
          style={{ alignSelf: "flex-end" }}
        />
        <div
          className="titleModal"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "10px",
          }}
        >
          Add criteria or data validation
        </div>
        <div
          className="subtitleModal"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "5px",
          }}
        >
          Select the criteria you want to add
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginTop: "5px",
          }}
        >
          <FormControl>
            <Autocomplete
              className="inputAddCriteria"
              onChange={(_event, newValue) => setPriority(newValue)}
              options={priorities}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Select priority"
                  variant="outlined"
                />
              )}
            />
          </FormControl>

          <FormControl>
            <Autocomplete
              className="inputAddCriteria"
              onChange={(_event, newValue) => setSecundarySelected(newValue)}
              getOptionLabel={(option) => option.description}
              options={secundaries}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Input column"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div>

        <FormControl>
          <Autocomplete
            className="inputAddCriteria"
            onChange={(_event, newValue) =>
              setInteraction(newValue.interaction)
            }
            getOptionLabel={(option) => option.interaction.name}
            options={interactions}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="Interaction Selector"
                variant="outlined"
              />
            )}
          />
        </FormControl>

        <div className="criteriaConditionsContainer">
          {conditions.map((condition, index) => (
            <div
              key={`operator${index}`}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {condition.operator !== null ? (
                <FormControl>
                  <Select
                    variant="outlined"
                    value={condition.operator}
                    className="operator"
                    onChange={(event) => {
                      handleOperatorCondition(event, index);
                    }}
                  >
                    <MenuItem key={"1"} value={"Y"}>
                      And
                    </MenuItem>
                    <MenuItem key={"2"} value={"O"}>
                      Or
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : null}

              <div
                key={index}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControl>
                  <Autocomplete
                    value={condition.condition}
                    className="inputAddCriteria"
                    onChange={(_event, newValue) => {
                      handleIdCondition(newValue, index);
                    }}
                    clearOnBlur={false}
                    getOptionLabel={(option) => option.texto1}
                    options={conditionsSelect}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Select condition"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <CssTextField
                  label="value field"
                  className="inputAddCriteria"
                  variant="outlined"
                  value={condition.text}
                  onChange={(event) => {
                    handleTextCondition(event.target.value, index);
                  }}
                />
                <Button
                  variant="contained"
                  size="large"
                  className="buttonMore"
                  onClick={() => {
                    verifyConditions(index);
                  }}
                >
                  {index !== 0 ? "-" : "+"}
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <button
            className="buttonModalAddCalculation"
            onClick={() => {
              addCriteriaFromModal();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCriteria;
