import Modal from "@mui/material/Modal";
import "./modalProcessing.css";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#ff0081",
  },
}));

const ModalProcessing = ({ open, percentage, message, handleClose }) => {
  return (
    <Modal disableEscapeKeyDown open={open} className="modalProcessing">
      <div className="paperProcessing">
        <div className="title titleProcessing">Processing:</div>
        <div className="message">{message}</div>
        <div className="progressContainer">
          <LinearProgressWithLabel value={percentage} />
        </div>
        <div>
          <button
            className={"buttonModal" + (percentage !== 100 ? " disabledButton" : "")}
            disabled={percentage !== 100}
            onClick={handleClose}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default ModalProcessing;
