import { useState, useEffect } from "react";
import { _get, _post, decodeUser } from "../../../../common/generalRequests";
import swal from "sweetalert";
import closeIcon from "../../../../assets/svg/CancelarGris.svg";
import { TextField, Modal, Divider, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "./ModalConfiguration.css";

const ModalConfiguration = ({
  open,
  close,
  channel,
  providersSelect,
  idClient,
  addConfiguration,
  setLoading,
}) => {
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState("");
  const [configurationJson, setConfigurationJson] = useState({});
  const [inputsProvider, setInputsProvider] = useState([]);
  const [inputsValuesProvider, setInputsValuesProvider] = useState([]);

  const user = decodeUser(localStorage.getItem("USER"));

  useEffect(() => {
  getProviders()
  }, [providersSelect]);

  useEffect(() => {
    if (provider) {
      getChannelProviderConfig();
    }
  }, [provider]);

  useEffect(() => {
    //getProviderInputs();
    renderProviderInputs();
  }, [configurationJson]);

  const getProviders = () => {
    setProviders(providersSelect);
  }

  const getChannelProviderConfig = () => {
    setLoading(true);
    const data = {
      idClient: idClient,
      idChannel: channel.id,
      idProvider: provider.id,
    };
    _post(
      `/channels/getConfigChannel`,
      data,
      (res) => {
        if (res.data.ok) {
          const configJson = res.data.Config;
          if (configJson) {
            setConfigurationJson(configJson);
            setInputsProvider(Object.keys(configJson));
            setInputsValuesProvider(Object.values(configJson));
          }
          setLoading(false);
        }
      },
      (error) => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const setChannelProviderConfig = () => {
    setLoading(true);
    const data = {
      idClient: idClient,
      idChannel: channel.id,
      idProvider: provider.id,
      config: configurationJson,
    };
    _post(
      `/channels/setConfigChannel`,
      data,
      (res) => {
        if (res.data.ok) {
          setLoading(false);
        }
      },
      (error) => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const addConfigurationFromModal = () => {
    if (provider && configurationJson) {
      setChannelProviderConfig();
      addConfiguration();
      closeModal();
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const closeModal = () => {
    setProvider("");
    setInputsProvider([]);
    setInputsValuesProvider([]);
    close();
  };

  const renderProviderInputs = () => {
    if (user.rolUser === 3) {
      return (
        <div>Please contact your administrator to request permissions</div>
      );
    } else if (configurationJson) {
      return getProviderInputs();
    }
    return <div></div>;
  };

  const getProviderInputs = () => {
    return (
      <div className="containerConfiguration">
        {inputsProvider.length > 0 ? (
          <div className="containerDynamic">
            {inputsProvider.map((input, index) => (
              <FormControl
                required={true}
                className="formControlModal"
                key={index}
              >
                <TextField
                  className="dynamicInputs" 
                  onChange={(event) => {
                    inputValueChange(index, event.target.value);
                  }}
                  value={
                    inputsValuesProvider[index]
                      ? inputsValuesProvider[index]
                      : ""
                  }
                  variant="outlined"
                  label={input}
                />
              </FormControl>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };
  const inputValueChange = (index, newValue) => {
    inputsValuesProvider[index] = newValue;
    let newInputsValuesProvider = [...inputsValuesProvider];
    newInputsValuesProvider[index] = newValue;
    setInputsValuesProvider(newInputsValuesProvider);
    inputsProvider.forEach((inputName, _index) => {
      configurationJson[inputName] = inputsValuesProvider[_index];
    });
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => {
        closeModal();
      }}
      className="modal"
    >
      <div className="paperCreateCollection">
        <img src={closeIcon} alt="close" onClick={() => closeModal()} />
        <div className="titleModal margingBottom35">Add configuration</div>
        <div className="subtitleModal">Select supplier</div>
        <FormControl required={true} className="formControlModal">
          <Autocomplete
            className="inputConfiguration"
            onChange={(_event, newValue) => setProvider(newValue)}
            options={providers}
            getOptionLabel={(option) => option.providersName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select supplier"
                variant="outlined"
              />
            )}
          />
        </FormControl>
        <Divider />
        <div className="subtitleModal">Configuration</div>
        {renderProviderInputs()}

        <div className="buttonsContainerModal">
          <button className="buttonNoModal" onClick={() => closeModal()}>
            Cancel
          </button>
          <button
            className="buttonModal"
            onClick={() => addConfigurationFromModal()}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfiguration;
