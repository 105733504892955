import { useState, useEffect } from "react";
import {
  _get,
  decodeUser,
  _post,
  _put,
} from "../../../../common/generalRequests";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import closeIcon from "../../../../assets/svg/CancelarGris.svg";
import { styled } from "@mui/material/styles";
import "./modalCreateCollection.css";
import RenderLoader from "../../../../common/helpers/RenderLoader";
import Box from "@mui/material/Box";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalCreateCollection = ({
  open,
  handleClose,
  processData,
  clientData,
  processUid,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modelList, setModelList] = useState([]);
  const [model, setModel] = useState(null);
  const [processType, setProcessType] = useState(null);
  const [processTypesList, setProcessTypesList] = useState([]);
  const [campaingCode, setCampaingCode] = useState("");
  const [nameOfProcess, setNameOfProcess] = useState("");
  const [edit, setEdit] = useState(false);
  const [client, setClient] = useState({});
  const [modelChanged, setModelChanged] = useState(false);
  const [processTypeChanged, setProcessTypeChanged] = useState(false);
  const [idModel, setIdModel] = useState(null);
  const [idProcessType, setIdProcessType] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [modelColumns, setModelColumns] = useState(null);
  const [modelQueryColumns, setModelQueryColumns] = useState(null);
  const [processList, setProcessList] = useState(null);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    if (open) {
      processUid ? setLoading(true) : setLoading(false);
      setEdit(false);
      setNameOfProcess("");
      setCampaingCode("");
      setProcessType(null);
      setModel(null);
      setIdModel(null);
      setModelColumns(null);
      setProcessList(processData);
      _get(`/modelsClients/byclient/${clientData.id}`, (res1) => {
        if (res1.data.ok) {
          setModelList(
            res1.data.models.filter((model) => model.active === true)
          );
          _get("/processtype", (res2) => {
            if (res2.data.ok) {
              setProcessTypesList(res2.data.tipos);
              if (processUid) {
                setEdit(true);
                _get(
                  `/process/${processUid}`,
                  (res) => {
                    if (res.data.ok) {
                      setNameOfProcess(res.data.oneProcess.processName);
                      setProcessList(
                        processData.filter(
                          (process) =>
                            process.processName !==
                            res.data.oneProcess.processName
                        )
                      );

                      setCampaingCode(res.data.oneProcess.campaingCode);
                      setClient(res.data.oneProcess.idClient);

                      let modelID = res.data.oneProcess.idModel;
                      const modelProcess = res1.data.models
                        ? res1.data.models.filter(
                            (_model) =>
                              _model.id === res.data.oneProcess.idModel
                          )
                        : [];

                      setIdModel(modelProcess[0]);
                      setIdProcessType(
                        modelProcess.length > 0
                          ? modelProcess[0].idProcesstype
                          : null
                      );
                      setModel(modelProcess.length > 0 ? modelProcess[0] : {});
                      const processTypes = res2.data.tipos
                        ? res2.data.tipos.filter(
                            (type) =>
                              type.id === res.data.oneProcess.processType
                          )
                        : [];
                      setProcessType(
                        processTypes.length > 0 ? processTypes[0] : {}
                      );
                      const headersVal = res.data.oneProcess.headers
                        ? res.data.oneProcess.headers
                        : null;
                      setHeaders(headersVal ? headersVal : null);
                      setLoading(false);
                    }
                  },
                  () => {
                    swal("Error", "An Error has occurred", "error");
                  }
                );
              }
            }
          });
        }
      });
    }
  }, [open]);

  useEffect(() => {
    if (idModel && open) {
      let ModelIdProcessType = idModel.idProcesstype
        ? idModel.idProcesstype
        : idProcessType;
      let ModelId = idModel.id;
      _get(
        `/columns/${ModelIdProcessType}`,
        (res1) => {
          if (res1.data.ok) {
            let columnsArray = res1.data.column.map((column) => {
              column.fileHeader = [];
              column.conditionToColumn = null;
              column.conditionToValue = null;
              return column;
            });
            setModelColumns(columnsArray);
          }
        },
        (error) =>
          swal("Error", `${error}`, "error").then(() => navigate("/home"))
      );

      _get(
        `/models/${ModelId}`,
        (res2) => {
          if (res2.data.ok) {
            setModelQueryColumns(res2.data.queryColumns);
          }
        },
        (error) =>
          swal("Error", `${error}`, "error").then(() => navigate("/home"))
      );
    }
  }, [idModel]);

  const verifyColumns = () => {
    setLoading(true);
    if (changes) {
      if (
        nameOfProcess &&
        campaingCode &&
        client &&
        model.id &&
        processType.id
      ) {
        if (
          processList.some((process) => process.processName === nameOfProcess)
        ) {
          swal(
            "Warning",
            "This process name already exists, please change the name of the project",
            "warning"
          );
        } else {
          if (edit) {
            editProcess();
          } else {
            createCollection();
          }
        }
      } else {
        swal("Warning", "Missing fields to complete", "warning");
      }
    } else {
      navigate(`/inputfile/${processUid}`);
    }
  };

  const createCollection = () => {
    const data = {
      processName: nameOfProcess,
      campaingCode: campaingCode,
      uidAuthor: decodeUser(localStorage.getItem("USER")).uid,
      idClient: clientData.id,
      entrada: 1,
      processType: processType.id,
      idModel: model.id,
    };
    _post(
      "/create",
      data,
      (res) => {
        if (res.data.ok) {
          navigate(`/inputfile/${res.data.newProcess[0].uid}`);
        } else {
          swal("Error", res.data.err, "error");
        }
      },
      () =>
        swal("Error", "An Error has occurred", "error").then(() => navigate(0))
    );
  };

  const editProcess = () => {
    let data =
      modelChanged || processTypeChanged
        ? {
            processName: nameOfProcess,
            campaingCode: campaingCode,
            idModel: model.id,
            processType: processType.id,
            linkedColumns: JSON.stringify([]),
            validationColumn: JSON.stringify([]),
            headers: JSON.stringify([]),
          }
        : { processName: nameOfProcess, campaingCode: campaingCode };

    _put(
      `/editProcess/${processUid}`,
      data,
      (res) => {
        if (res.data.ok) {
          navigate(`/inputfile/${processUid}`);
        } else {
          swal("Error", "An Error has occurred1", "error").then(() => {
            setLoading(false);
            navigate(0);
          });
        }
      },
      () =>
        swal("Error", "An Error has occurred2", "error").then(() => navigate(0))
    );
  };

  const handleChangeModel = (newValue) => {
    setIdModel(newValue);

    if (edit) {
      swal({
        title: "Are you sure?",
        text: "You are about to change the model and this will eliminate the entire current configuration of this process.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((save) => {
        if (save) {
          setModel(newValue);
          setModelChanged(true);
        }
      });
    } else {
      setModel(newValue);
    }
  };

  const closeModal = () => {
    handleClose();
    if (edit) {
      setEdit(false);
      setNameOfProcess("");
      setCampaingCode("");
      setProcessType("");
      setModel("");
      setIdModel(null);
      setModelColumns(null);
    }
  };
  const handleChangeProcessType = (newValue) => {
    if (edit) {
      swal({
        title: "¿Estás seguro?",
        text: "Estás a punto de cambiar el tipo de proceso y esto eliminará toda la configuración actual de este proceso",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((save) => {
        if (save) {
          setProcessType(newValue);
          setProcessTypeChanged(true);
        }
      });
    } else {
      setProcessType(newValue);
    }
  };

  const getTooltipeTitle = () => {
    return (
      <>
        <div>Required fields for the model </div>
        <div>{idModel.modelName} : </div>
        {modelColumns && modelQueryColumns
          ? modelColumns.map((column, index) =>
              column.required ||
              modelQueryColumns.includes(column.columnName) ? (
                <p>
                  {column.description[0] +
                    column.description.toLowerCase().slice(1)}
                </p>
              ) : null
            )
          : ""}
      </>
    );
  };
  return (
    <div>
      <RenderLoader loading={loading} />
      <Modal open={open} onClose={handleClose} className="modal">
        <Box sx={{ width: "33%" }}>
          <div className="paperCreateCollection">
            <img src={closeIcon} alt="close" onClick={() => closeModal()} />
            <div className="titleModal">
              {edit ? "Edit Project" : "Create New Project"}
            </div>
            <label className="labelModal">Project's name</label>
            <span className="descriptionInputModal">
              This name must be clear and precise to avoid confusion
            </span>
            <input
              className="inputModal"
              value={nameOfProcess}
              onChange={(event) => {
                setChanges(true);
                setNameOfProcess(event.target.value);
              }}
            />
            <label className="labelModal">Campaign Code</label>
            <span className="descriptionInputModal">
              This code must be clear and precise to avoid confusion
            </span>
            <input
              className="inputModal"
              value={campaingCode}
              onChange={(event) => {
                setChanges(true);
                setCampaingCode(event.target.value);
              }}
            />

            <div className="modeloAnalitico">
              <label className="labelModal">Analytical Model</label>
              {modelColumns ? (
                <Tooltip title={getTooltipeTitle()} placement="bottom-start">
                  <div className="infotooltip"> Required fields </div>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
            <span className="descriptionInputModal">
              This name must be clear and precise to avoid confusion
            </span>

            <FormControl required={true} className="formControlModal">
              <Autocomplete
                value={model}
                className="selectModel"
                onChange={(_event, newValue) => {
                  setChanges(true);
                  handleChangeModel(newValue);
                }}
                getOptionLabel={(option) => option.modelName}
                options={modelList}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Analytical Model"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>

            <label className="labelModal">Process type</label>
            <span className="descriptionInputModal">
              This name must be clear and precise to avoid confusion
            </span>
            <FormControl required={true} className="formControlModal">
              <Autocomplete
                value={processType}
                className="selectModel"
                onChange={(_event, newValue) => {
                  setChanges(true);
                  handleChangeProcessType(newValue);
                }}
                getOptionLabel={(option) => option.description}
                options={processTypesList}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Process type"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <button className="buttonModal" onClick={() => verifyColumns()}>
              {edit ? "Edit" : "Create"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalCreateCollection;
