import { useEffect, useState } from "react";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import { _get, _put } from "../../common/generalRequests";
import { useLocation, useNavigate } from "react-router";
import swal from "sweetalert";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Chip from "@mui/material/Chip";
import ModalAddCriteria from "./components/ModalAddCriteria";
import "./criteriaStrategy.css";
import ProgressBar from "../../common/components/progressBar/ProgressBar";

let prioritiesOriginal = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
];

function CriteriaStrategy() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [primary, setPrimary] = useState({});
  const [secundary, setSecundary] = useState([]);
  const [idStrategy, setidStrategy] = useState("");
  const [strategyDetail, setStrategyDetail] = useState("");
  const [criterias, setCriterias] = useState([]);
  const [conditionsSelect, setConditionsSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [priorities, setPriorities] = useState(prioritiesOriginal);

  const filterCriterias = () => {
    const criteriasPriorities = criterias.map((criteria) => criteria.priority);
    setPriorities(
      prioritiesOriginal.filter((pri) => !criteriasPriorities.includes(pri))
    );
    priorities.sort((a, b) => {
      const aN = +a;
      const bN = +b;

      return aN - bN;
    });
    setOpenModal(true);
  };

  useEffect(() => {
    setLoading(true);
    _get(
      `/calculation`,
      (response) => {
        if (response.data.ok) {
          setConditionsSelect(
            response.data.calculations.filter(
              (filtro) =>
                filtro.id === 1 ||
                filtro.id === 2 ||
                filtro.id === 5 ||
                filtro.id === 6
            )
          );
          setSecundary(state.secundary);
          if (state.criteria !== "") setCriterias(JSON.parse(state.criteria));

          setStrategyDetail(state.id);
          setidStrategy(state.idStrategy);
          setPrimary(state.primary);
          setLoading(false);
        } else
          swal("Error", "Error getting calculation information", "error").then(
            () => navigate("/home")
          );
      },
      () =>
        swal("Error", "Error getting calculation information", "error").then(
          () => navigate("/home")
        )
    );
  }, [
    navigate,
    state.criteria,
    state.secundary,
    state.id,
    state.idStrategy,
    state.primary,
  ]);

  const addCriteria = (
    priority,
    secundarySelected,
    interaction,
    conditions
  ) => {
    setOpenModal(false);
    let copy = [...criterias];
    copy.push({ priority, secundarySelected, interaction, conditions });
    setCriterias(copy);
  };

  const deleteCriteria = (index) => {
    let copy = [...criterias];
    copy.splice(index, 1);
    setCriterias(copy);
  };

  const updateStrategiaDetail = () => {
    setLoading(true);
    const data = {
      idStrategy: idStrategy,
      primary: JSON.stringify(primary),
      secundary: JSON.stringify(secundary),
      criteria: JSON.stringify(criterias),
    };

    _put(
      `/detailStrategy/${strategyDetail}`,
      data,
      (res) => {
        setLoading(false);
        if (res.data.ok) {
          swal("Success", "Strategy detail was updated", "success")
        } else {
          swal("Error", "An Error has occurred", "error");
        }
      },
      () => swal("Error", "An Error has occurred", "error")
    );
  };

  const getFormStep = () => {
    return (
      <div className="containerLink">
        <div className="buttonCalculationContainer">
          <button
            className="buttonAddCriteria"
            onClick={() => {
              filterCriterias();
            }}
          >
            <PostAddIcon />
            Add Criterion
          </button>
        </div>

        <div>
          <div
            className="first_rowLink"
            style={{ placeContent: "space-evenly" }}
          >
            <div className="subtitleTableStrategy chipWidth">Priority</div>
            <div className="subtitleTableStrategy chipWidth">Input</div>
            <div className="subtitleTableStrategy chipWidth">Channel Selector</div>
            <div className="subtitleTableStrategy chipWidth">Validation / Criterion</div>
          </div>
          <div className="contentTableCalculations">
            {criterias.length > 0 ? (
              criterias.map((cri, index) => {
                const textCondi = cri.conditions[0].condition.texto1;
                const criteriaLabel =
                  textCondi.charAt(0).toUpperCase() + textCondi.slice(1);
                const textLabel =
                  cri.conditions[0].text === ""
                    ? ""
                    : " / " + cri.conditions[0].text;
                const conditionsLabel = criteriaLabel + textLabel;
                return (
                  <div key={`row${index}`} className="rowsLinks">
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        padding: "5px 0",
                        width: "100%",
                      }}
                    >
                      <div style={{ alignSelf: "center",width:"200px" }}>
                        Priority {cri.priority}
                      </div>
                      <Chip
                        className="chipEntradasCriteria chipsCriteria"
                        key={`entry${index}`}
                        label={cri.secundarySelected.description}
                      />
                      <Chip
                        className="chipCanal chipsCriteria"
                        key={`selector${index}`}
                        label={
                          cri.interaction
                            ? `${cri.interaction.name}`
                            : `${cri.selector}`
                        }
                      />
                      <Chip
                        className="chipConditionCriteria chipsCriteria"
                        key={`condition${index}`}
                        label={conditionsLabel}
                        onDelete={() => deleteCriteria(index)}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="title" style={{ marginTop: "30px" }}>
                No criteria yet
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar />
        <ProgressBar
          nameProcess="Configurar Criterios"
          nextStep={updateStrategiaDetail}
          buttonOn={true}
          backStep={`/home`}
          backProcess={navigate}
          stepIcons="strategy"
          nextButtonLabel="Save"
        />
        <div className="common">
          <div className="boxLink">{getFormStep()}</div>
        </div>
        <ModalAddCriteria
          open={openModal}
          close={() => setOpenModal(false)}
          secundaries={secundary}
          addCriteria={addCriteria}
          conditionsSelect={conditionsSelect}
          priorities={priorities}
          idProcess={state.idProcess}
        />
      </div>
    </div>
  );
}

export default CriteriaStrategy;
