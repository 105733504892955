import { useState, useReducer, useEffect } from "react";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./modalAddCondition.css";
import { _get } from "../../../common/generalRequests";
import swal from "sweetalert";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddCondition = ({
  open,
  handleClose,
  inputHeaders,
  addCondition,
  valOptions,
  conditionalColumn,
}) => {
  const [headers, setHeaders] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [conditionsArray, setConditionsArray] = useState([]);
  const [formatArray, setFormatArray] = useState([]);
  const [thenColumnCheckbox, setThenColumnCheckbox] = useState([false]);
  const [daysColumnCheckbox, setDaysColumnCheckbox] = useState([false]);
  const [radioDateValue, setRadioDateValue] = useState([""]);

  const connectors = [
    { name: "None", value: "Ninguno" },
    { name: "And", value: "&&" },
    { name: "Or", value: "||" },
  ];
  useEffect(() => {
    if (open) {
      _get(
        "/validation",
        (res) => {
          if (res.data.ok) {
            let arrayValidation = res.data.val.filter(
              (validation) =>
                validation.idDataType === 5 &&
                validation.id !== 28 &&
                validation.id !== 54
            );
            setFormatArray(arrayValidation);
          }
        },
        (error) => swal("Error", "An error has occurred", "error")
      );
    }
  }, [open]);

  const addConditonToArray = () => {
    let newThenColumnCheckbox = [...thenColumnCheckbox];
    newThenColumnCheckbox.push(false);
    setThenColumnCheckbox(newThenColumnCheckbox);
    let newConditionsArray = conditionsArray;
    let newCondition = [
      {
        file: {},
        cond: null,
        column: null,
        info: "",
        finalValue: "",
        headersByFile: [],
        headersByFile2: [],
        headersByFile3: [],
        headersByFile4: [],
        connector: "Ninguno",
      },
    ];
    newConditionsArray.push(newCondition);
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const addConditionConnector = (newConditionsArray, index) => {
    let newCondition = {
      file: {},
      cond: null,
      column: null,
      info: "",
      finalValue: "",
      headersByFile: [],
      headersByFile2: [],
      headersByFile3: [],
      headersByFile4: [],
      connector: "Ninguno",
    };
    newConditionsArray[index].push(newCondition);
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const getHeadersByFile = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].file = value.name;
      newConditionsArray[index][index2].headersByFile = value.headersByFile;
    } else {
      newConditionsArray[index][index2].file = {};
      newConditionsArray[index][index2].headersByFile = [];
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const getHeadersByFile2 = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].file2 = value.name;
      newConditionsArray[index][index2].headersByFile2 = value.headersByFile;
    } else {
      newConditionsArray[index][index2].file2 = {};
      newConditionsArray[index][index2].headersByFile2 = [];
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const getHeadersByFile3 = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].file3 = value.name;
      newConditionsArray[index][index2].headersByFile3 = value.headersByFile;
    } else {
      newConditionsArray[index][index2].file3 = {};
      newConditionsArray[index][index2].headersByFile3 = [];
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const getHeadersByFile4 = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].file4 = value.name;
      newConditionsArray[index][index2].headersByFile4 = value.headersByFile;
    } else {
      newConditionsArray[index][index2].file4 = {};
      newConditionsArray[index][index2].headersByFile4 = [];
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeHeader = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].column = value.Header;
    } else {
      newConditionsArray[index][index2].column = null;
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeHeader2 = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].column2 = value.Header;
    } else {
      newConditionsArray[index][index2].column2 = null;
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeHeader3 = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].column3 = value.Header;
    } else {
      newConditionsArray[index][index2].column3 = null;
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeHeader4 = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (value) {
      newConditionsArray[index][index2].column4 = value.Header;
    } else {
      newConditionsArray[index][index2].column4 = null;
    }
    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeCondition = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (!value) {
      newConditionsArray[index][index2].cond = value;
      newConditionsArray[index][index2].info = "";
    } else {
      if (value.id === 6 || value.id === 5) {
        newConditionsArray[index].splice(
          index2 + 1,
          newConditionsArray[0].length
        );
      }
      if (value) {
        newConditionsArray[index][index2].cond = value.id;
        newConditionsArray[index][index2].info =
          value.id === 1 || value.id === 2 || value.id === 6 ? "N/A" : "";
        newConditionsArray[index][index2].finalValue =
          value.id === 6 ? "N/A" : "";
      }
    }

    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeConnector = (value, index, index2) => {
    let newConditionsArray = conditionsArray;
    if (!value) {
      value = "Ninguno";
    }
    if (value) {
      newConditionsArray[index][index2].connector = value;
    } else {
      newConditionsArray[index][index2].connector = "Ninguno";
    }
    if (value !== "Ninguno" && value) {
      addConditionConnector(newConditionsArray, index);
    } else {
      newConditionsArray[index].splice(
        index2 + 1,
        newConditionsArray[0].length
      );
    }

    forceUpdate();
  };

  const handleChangeInfo = (key, value, index, index2 = null) => {
    let newConditionsArray = conditionsArray;
    if (key === "finalValue") {
      newConditionsArray[index].forEach((element) => {
        element[`${key}`] = value;
      });
    } else {
      if (value) {
        newConditionsArray[index][index2][`${key}`] = value;
      } else {
        newConditionsArray[index][index2][`${key}`] = null;
      }
    }

    setConditionsArray(newConditionsArray);
    forceUpdate();
  };

  const handleChangeRadioDate = (event, index, index2) => {
    let newRadioDateValue = radioDateValue;
    if (event.target.value === "Fecha Actual") {
      handleChangeInfo("info", "now", index, index2);
    }
    if (event.target.value === "Valor de Columna") {
      handleChangeInfo("info", "ColumnVal", index, index2);
    }
    newRadioDateValue[index] = event.target.value;
    setRadioDateValue(newRadioDateValue);

    forceUpdate();
  };

  const addConditionFromModal = () => {
    let ready = true;
    conditionsArray.forEach((condition) => {
      condition.forEach((element) => {
        for (let value of Object.values(element)) {
          if (!value) ready = false;
        }
      });
    });
    if (ready && conditionsArray.length > 0) {
      addCondition(conditionalColumn.id, conditionsArray);
      setConditionsArray([]);
      closeModal();
    }
  };

  const closeModal = () => {
    setConditionsArray([]);
    setThenColumnCheckbox([false]);
    setDaysColumnCheckbox([false]);
    setRadioDateValue([""]);

    handleClose();
  };

  const diferenciaInfo = (item, index, index2) => {
    if (conditionsArray[index][index2].cond === 5) {
      return (
        <FormControl>
          <Autocomplete
            className="inputCondition"
            onChange={(event, newValue) => {
              handleChangeInfo(
                "format",
                newValue.description.toUpperCase(),
                index,
                index2
              );
            }}
            getOptionLabel={(option) => option.description}
            options={formatArray}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="Select Date Format"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      );
    } else {
      return <></>;
    }
  };

  const diferenciaFields = (item, index, index2) => {
    if (conditionsArray[index][index2].cond === 5) {
      return (
        <>
          <div className="subtitleCondition centerSubtitle">
            Source of second date to compare
          </div>

          <div className="checkBoxContainer">
            {/*<input type="checkbox" value="fecha Actual" name="fecha Actual" />{" "}
              Fecha Actual*/}

            <FormControl component="fieldset" className="radioGroup">
              <RadioGroup
                aria-label=""
                name="dataInfo"
                value={radioDateValue[index]}
                onChange={(event) => {
                  handleChangeRadioDate(event, index, index2);
                }}
                row
              >
                <FormControlLabel
                  value="Fecha Actual"
                  control={<Radio />}
                  label="Current date"
                />
                <FormControlLabel
                  value="Valor de Columna"
                  control={<Radio />}
                  label="Column Value"
                />
                <FormControlLabel
                  value="Fecha Especifica"
                  control={<Radio />}
                  label="Specific date"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {radioDateValue[index] === "Fecha Especifica" ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControl>
                <CssTextField
                  label="Date to compare"
                  value={item.info}
                  className="inputCondition"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="name"
                  id="name"
                  variant="outlined"
                  onChange={(event) => {
                    handleChangeInfo("info", event.target.value, index, index2);
                  }}
                />
              </FormControl>
            </div>
          ) : (
            ""
          )}
          {radioDateValue[index] === "Valor de Columna" ? (
            <>
              <div className="subtitleCondition centerSubtitle">
                Select file and source column for second date
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FormControl required={true}>
                  <Autocomplete
                    className="inputCondition"
                    onChange={(event, newValue) => {
                      getHeadersByFile3(newValue, index, index2);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={inputHeaders}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Date source file 2"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    className="inputCondition"
                    onChange={(event, newValue) => {
                      handleChangeHeader3(newValue, index, index2);
                    }}
                    getOptionLabel={(option) => option.Header}
                    options={item.headersByFile3}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Date source column 2"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </>
          ) : (
            ""
          )}

          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormControl>
              <Autocomplete
                className="inputCondition"
                onChange={(event, newValue) => {
                  handleChangeInfo("validation2", newValue, index, index2);
                }}
                getOptionLabel={(option) => option}
                options={["Greater than", "Smaller than", "Equal"]}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Select Validation"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <CssTextField
                label="Number of days"
                value={item.info2 === "DaysOfColumn" ? "" : item.info2}
                className="inputDays"
                name="name"
                id="name"
                variant="outlined"
                disabled={daysColumnCheckbox[index]}
                onChange={(event) => {
                  handleChangeInfo("info2", event.target.value, index, index2);
                }}
              />
            </FormControl>
            <div
              className="checkBoxContainer"
              onChange={() => {
                handleChangeInfo("info2", "DaysOfColumn", index, index2);
                checkboxDaysColumn(index);
              }}
            >
              <input
                type="checkbox"
                value="Dias desde columna"
                name="Dias desde columna"
              />{" "}
              Days from column
            </div>
          </div>

          {daysColumnCheckbox[index] ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControl required={true}>
                <Autocomplete
                  className="inputCondition"
                  onChange={(event, newValue) => {
                    getHeadersByFile4(newValue, index, index2);
                  }}
                  getOptionLabel={(option) => option.name}
                  options={inputHeaders}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      label="Input file for days"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <Autocomplete
                  className="inputCondition"
                  onChange={(event, newValue) => {
                    handleChangeHeader4(newValue, index, index2);
                  }}
                  getOptionLabel={(option) => option.Header}
                  options={item.headersByFile4}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      label="Input column for days"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  const concatenarFields = (item, index, index2) => {
    if (conditionsArray[index][index2].cond === 6) {
      return (
        <>
          <div className="subtitleCondition">Second data to concatenate</div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormControl required={true}>
              <Autocomplete
                className="inputCondition"
                onChange={(event, newValue) => {
                  getHeadersByFile2(newValue, index, index2);
                }}
                getOptionLabel={(option) => option.name}
                options={inputHeaders}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Input File 2"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Autocomplete
                className="inputCondition"
                onChange={(event, newValue) => {
                  handleChangeHeader2(newValue, index, index2);
                }}
                getOptionLabel={(option) => option.Header}
                options={item.headersByFile2}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Input Column 2"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </div>
        </>
      );
    }
  };

  const thenColumn = (item, index) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControl required={true}>
          <Autocomplete
            className="inputCondition"
            onChange={(event, newValue) => {
              getHeadersByFile2(newValue, index, 0);
            }}
            getOptionLabel={(option) => option.name}
            options={inputHeaders}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="File for then"
                variant="outlined"
              />
            )}
          />
        </FormControl>
        <FormControl>
          <Autocomplete
            className="inputCondition"
            onChange={(event, newValue) => {
              handleChangeHeader2(newValue, index, 0);
            }}
            getOptionLabel={(option) => option.Header}
            options={item.headersByFile2}
            renderInput={(params) => (
              <CssTextField
                {...params}
                label="Column for then"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </div>
    );
  };
  const getIfElses = (item, index, index2) => {
    return (
      <div key={`${index}${index2}`}>
        <div className="subtitleCondition">
          {conditionsArray[index][index2].cond !== 6
            ? "If the Input column"
            : "First data to concatenate"}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FormControl required={true}>
            <Autocomplete
              className="inputCondition"
              onChange={(event, newValue) => {
                getHeadersByFile(newValue, index, index2);
              }}
              getOptionLabel={(option) => option.name}
              options={inputHeaders}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Input file"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Autocomplete
              className="inputCondition"
              onChange={(event, newValue) => {
                handleChangeHeader(newValue, index, index2);
              }}
              getOptionLabel={(option) => option.Header}
              options={item.headersByFile}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Input column"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </div>

        <div className="subtitleCondition">Condition</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FormControl>
            <Autocomplete
              className="inputCondition"
              onChange={(event, newValue) => {
                handleChangeCondition(newValue, index, index2);
              }}
              getOptionLabel={(option) => option.description}
              options={index2 === 0 ? valOptions : valOptions.slice(0, 4)}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="select condition"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          {conditionsArray[index][index2].cond !== 5 &&
          conditionsArray[index][index2].cond !== 6 ? (
            <FormControl>
              <CssTextField
                label="Condition Value"
                value={item.info}
                className="inputCondition"
                InputLabelProps={{
                  shrink: true,
                }}
                name="name"
                id="name"
                variant="outlined"
                onChange={(event) => {
                  handleChangeInfo("info", event.target.value, index, index2);
                }}
              />
            </FormControl>
          ) : (
            <></>
          )}
          {diferenciaInfo(item, index, index2)}
        </div>

        {diferenciaFields(item, index, index2)}
        {concatenarFields(item, index, index2)}
        {conditionsArray[index][0].cond !== 6 &&
        conditionsArray[index][index2].cond !== 5 ? (
          <FormControl>
            <Autocomplete
              className="inputCondition"
              onChange={(event, newValue) => {
                handleChangeConnector(
                  newValue ? newValue.value : null,
                  index,
                  index2
                );
              }}
              getOptionLabel={(option) => option.name}
              options={connectors}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="select logical operation"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        ) : (
          ""
        )}
      </div>
    );
  };

  const checkboxFinalValueColumn = (index) => {
    let newThenColumnCheckbox = [...thenColumnCheckbox];
    newThenColumnCheckbox[index] = !newThenColumnCheckbox[index];
    setThenColumnCheckbox(newThenColumnCheckbox);

    let newConditionsArray = [...conditionsArray];
    newConditionsArray[index].forEach((element) => {
      element[`finalValue`] = "columnValue";
    });
    setConditionsArray(newConditionsArray);
  };

  const checkboxDaysColumn = (index) => {
    let newDaysColumCheckbox = [...daysColumnCheckbox];
    newDaysColumCheckbox[index] = !newDaysColumCheckbox[index];
    setDaysColumnCheckbox(newDaysColumCheckbox);

    let newConditionsArray = [...conditionsArray];
    newConditionsArray[index].forEach((element) => {
      element[`finalValue`] = "DaysFromColumn";
    });
    setConditionsArray(newConditionsArray);
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      className="modalAddCondition"
    >
      <div className="paperAddCondition">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            closeModal();
          }}
          className="closeIcon"
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="titleModalAddHeader">
            Add Conditions on an Output Field
          </div>
          <div className="subtitleModal">Add conditions to the column</div>
        </div>
        <div className="conditionsContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="subtitleModalContent">
              For the output column{" "}
              {conditionalColumn ? conditionalColumn.description : ""}
            </div>
            <button
              className="addInputExcluding"
              onClick={() => {
                addConditonToArray();
              }}
            >
              Add Condition
            </button>
          </div>
          <div style={{ height: "296px" }}>
            {conditionsArray.length > 0
              ? conditionsArray.map((option, index) => {
                  let conditionsBlock = option.map((element, index2) => {
                    return getIfElses(element, index, index2);
                  });
                  return (
                    <div key={index}>
                      {conditionsBlock}
                      {conditionsArray[index][0].cond !== 6 ? (
                        <>
                          <div className="subtitleCondition">then</div>

                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {!thenColumnCheckbox[index] ? (
                              <CssTextField
                                label="Output column is equal to"
                                className="inputCondition"
                                name="name"
                                id="name"
                                variant="outlined"
                                onChange={(event) => {
                                  handleChangeInfo(
                                    "finalValue",
                                    event.target.value,
                                    index
                                  );
                                }}
                              />
                            ) : (
                              <></>
                            )}

                            <div
                              className="checkBoxContainer"
                              onChange={() => {
                                checkboxFinalValueColumn(index);
                              }}
                            >
                              <input
                                type="checkbox"
                                value="Valor de columna"
                                name="valor de columna"
                              />{" "}
                              Column value
                            </div>
                          </div>
                          {thenColumnCheckbox[index]
                            ? thenColumn(conditionsArray[index][0], index)
                            : ""}
                        </>
                      ) : (
                        ""
                      )}

                      <Divider />
                      <div className="subtitleConditionElse">Else</div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
            marginTop: "20px",
          }}
        >
          <button
            className="buttonModal"
            onClick={() => {
              addConditionFromModal();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCondition;
