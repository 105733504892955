import { useState } from "react";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";
import "./modalAddFill.css";

const optionsOP = [
  { name: "Fill", value: 0 },
  { name: "Difference", value: 1 },
];

const formats = [
  { name: "DD-MM-YYYY", value: 0 },
  { name: "MM-DD-YYYY", value: 1 },
  { name: "YYYY-MM-DD", value: 2 },
];

const today = (hi) => {
  const day = hi.getDate();
  const month = hi.getMonth() + 1;
  const year = hi.getFullYear();

  return `${year}-${month}-${day}`;
};

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddFill = ({
  saveFillValue,
  open,
  handleClose,
  inputHeaders,
  valOptions, // Las condiciones de la tabla.
  conditionalColumn,
}) => {
  const [principalHeaders, setPrincipalHeaders] = useState([]);
  const [principalHeaderSelected, setPrincipalHeaderSelected] = useState(null);
  const [secondHeaders, setSecondHeaders] = useState([]);
  const [secondHeaderSelected, setSecondHeadeSelected] = useState(null);
  const [specialOp, setSpecialOp] = useState(null);
  const [principalFile, setPrincipalFile] = useState("");
  const [secondFile, setSecondFile] = useState("");

  const [compareDate, setCompareDate] = useState(today(new Date()));
  const [check, setCheck] = useState(false);
  const [dateToggle, setDateToggle] = useState(false);
  const [diference, setDiference] = useState(false);
  const [format, setFormat] = useState(formats[0]);

  const getDate = () => {
    const dattte = check ? "dateNow" : new Date(compareDate);
    if (dattte == "dateNow") {
      return dattte;
    }
    return `${dattte.getDate()}-${
      dattte.getMonth() + 1
    }-${dattte.getFullYear()}`;
  };

  const getHeadersPrincipal = (value) => {
    setPrincipalFile(value ? value.name : "");
    setPrincipalHeaders(value ? value.headersByFile : []);
  };

  const getHeadersSecond = (value) => {
    setSecondFile(value ? value.name : "");
    setSecondHeaders(value ? value.headersByFile : []);
  };

  const handleAddFill = () => {
    if (!principalHeaderSelected || (diference && !secondHeaderSelected)) {
      swal("Warning", "Please fill in all the fields", "warning");
      return;
    }

    if (diference) {
      saveFillValue(conditionalColumn.id, {
        operation: 1,
        principalName: principalFile,
        principalHeader: principalHeaderSelected,
        format,
        date: getDate(),
        secondName: secondFile,
        secondHeader: secondHeaderSelected,
      });
    } else {
      saveFillValue(conditionalColumn.id, {
        operation: 0,
        principalName: principalFile,
        principalHeader: principalHeaderSelected,
      });
    }

    closeModal();
  };

  const closeModal = () => {
    setPrincipalFile("");
    setPrincipalHeaders([]);
    setPrincipalHeaderSelected(null);
    setSecondFile("");
    setSecondHeaders([]);
    setSecondHeadeSelected(null);
    setFormat(formats[0]);
    setDiference(false);
    setCheck(false);
    setCompareDate(today(new Date()));
    setSpecialOp(null);
    setDateToggle(false);

    handleClose();
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={closeModal}
      className="modalAddFill"
    >
      <div
        className="paperAddFill"
        style={{
          height: diference ? "560px" : "370px",
        }}
      >
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            closeModal();
          }}
          className="closeIcon"
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="titleModalAddFill">Special operations</div>
          <div className="subtitleModal">Select the inputs to evaluate</div>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControl required={true} className="formControlModal">
              <Autocomplete
                className="selectModalAddFill"
                onChange={(_event, newValue) => {
                  getHeadersPrincipal(newValue);
                }}
                getOptionLabel={(option) => option.name}
                options={inputHeaders}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Input file"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl className="formControlModal">
              <Autocomplete
                className="selectModalAddFill"
                onChange={(_event, newValue) => {
                  setPrincipalHeaderSelected(newValue);
                }}
                getOptionLabel={(option) => option.Header}
                options={principalHeaders}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Input Column"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: diference ? "center" : "start",
            }}
          >
           
            <FormControl required={true} className="formControlModalOPE">
              <Autocomplete
                className="selectModalAddFill"
                onChange={(_event, newValue) => {
                  setSpecialOp(newValue);
                  setDiference(newValue === optionsOP[1]);
                }}
                defaultValue={specialOp}
                getOptionLabel={(option) => option.name}
                options={conditionalColumn.fileHeader?.length === 0 ? optionsOP : [{ name: "Fill", value: 0 }]}  
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Special operation"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControl
              required={true}
              className="formControlModalOPE"
              style={{ visibility: diference ? "visible" : "hidden" }}
            >
              <Autocomplete
                className="selectModalAddFill"
                onChange={(_event, newValue) => {
                  setFormat(newValue);
                }}
                defaultValue={formats[0]}
                getOptionLabel={(option) => option.name}
                options={formats}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    label="Date format"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </div>

          {diference ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <FormControl className="formControlModalOP">
                  <CssTextField
                    label="Date to compare"
                    className="selectModalAddFill"
                    type="date"
                    disabled={check}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="date"
                    id="date"
                    value={compareDate}
                    variant="outlined"
                    onChange={(event) => {
                      setDateToggle(true);
                      setCompareDate(event.target.value);
                    }}
                  />
                </FormControl>
                <div
                  className="checkBoxContainer"
                  onChange={(event) => {
                    setCheck(event.target.value === "false");
                  }}
                >
                  <input type="checkbox" value={check} disabled={dateToggle} />
                  Current date
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <FormControl required={true} className="formControlModalOPE">
                  <Autocomplete
                    className="selectModalAddFill"
                    onChange={(_event, newValue) => {
                      getHeadersSecond(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    options={inputHeaders}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Comparison file"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <FormControl className="formControlModalOPE">
                  <Autocomplete
                    className="selectModalAddFill"
                    onChange={(_event, newValue) => {
                      setSecondHeadeSelected(newValue);
                    }}
                    getOptionLabel={(option) => option.Header}
                    options={secondHeaders}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Subtraction column"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <button
            className="buttonModal"
            onClick={() => {
              handleAddFill();
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddFill;
