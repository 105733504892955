import Cookies from "js-cookie";

export const ClearSesion = () => {
  Cookies.remove("XSRF-TOKEN", { path: "/" });
  localStorage.removeItem("USER");
  localStorage.removeItem("TOKEN_KEY");
  localStorage.removeItem("UID");
  sessionStorage.clear();
  window.location.replace("/collections/login");
};
