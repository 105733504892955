import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import ModalAddValidation from "./ModalAddValidation";
import "./row.css";

function Row({
  dataTypes,
  changeDataTypeOfColumn,
  handleDeleteValToProcess,
  handleAddValToProcess,
  column,
}) {
  const [validation, setValidation] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const getValidationList = (event) => {
    let id = event.target.value;
    let valList = dataTypes.filter((data) => data.id === id);
    setValidation(valList[0].validation);
    changeDataTypeOfColumn(column.id, id);
  };

  const handleAddValidation = (validation, additional,validation2 = null, additional2 = null , format=null) => {
    let newValidation = {
      validation: validation.id,
      validation2:validation2,
      description: validation.description,
      info: additional,
      info2:additional2,
      format:format
    };

   
    handleAddValToProcess(newValidation, column.id);
  };

  const openModalAndSetTypeOfValidation = () => {
    let id = column.dataType;
    let valList = dataTypes.filter((data) => data.id === id);
    setValidation(valList[0].validation);
    setOpenModal(true);
  };

  return (
    <div className="rows">
      <FormControl variant="outlined" className="formControlRow">
        <InputLabel>Type</InputLabel>
        <Select
          label="Tipo"
          onChange={(event) => getValidationList(event)}
          value={column.dataType}
        >
          {dataTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              {type.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {column.validation.length > 0 ? (
        <div className="columnValidationChip">
          {column.validation.map((val, index) => (
            <div className="rowsValidationChip" key={index}>
              <Chip
                className="chipValidation"
                label={val.description}
                onDelete={() => {
                  handleDeleteValToProcess(column.id, index, val);
                }}
              />
              <Chip
                className="chipAditionalInfo"
                label={val.info ? val.info : "N/A"}
              />
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
      <div className="buttonAddInfo">
        <Tooltip title="Add Validation">
          <IconButton
            onClick={() => {
              openModalAndSetTypeOfValidation();
            }}
          >
            <PostAddIcon fontSize="small" className="addIcon" />
          </IconButton>
        </Tooltip>
      </div>

      <ModalAddValidation
        open={openModal}
        validation={validation}
        handleClose={() => {
          setOpenModal(false);
        }}
        handleAddValidation={handleAddValidation}
      />
    </div>
  );
}

export default Row;
