import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import MainBar from "../../common/components/mainBar/MainBar";
import { useLocation, useNavigate } from "react-router";
import Loader from "../../common/tools/loader/Loader";
import { _post, _put } from "../../common/generalRequests";
import swal from "sweetalert";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./inputStrategy.css";
import ProgressBar from "../../common/components/progressBar/ProgressBar";

const checkedIcon = <CheckBoxIcon fontSize="small" />;

function InputStrategy() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [linkedColumns, setLinkedColumns] = useState([]);
  const [strategy, setStrategy] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [check1, setCheck1] = useState(true);
  const [primary, setPrimary] = useState({});
  const [indexPrimary, setIndexPrimary] = useState(null);
  const [secundary, setSecundary] = useState([]);
  const [criteria, setCriteria] = useState("");

  useEffect(() => {
    setLoading(true);
    let linked = null;
    let copy = [];
    if (state.strategy.primary_key) {
      setCriteria(state.strategy.criteria);
      linked = JSON.parse(state.linkedColumns).map((_column, index) => {
        _column["check"] = false;
        _column["isSecundary"] = false;

        if (JSON.parse(state.strategy.primary_key).id === _column.id) {
          setCheck1(false);
          setPrimary(_column);
          setIndexPrimary(index);
          _column["check"] = true;
        }

        if (
          JSON.parse(state.strategy.secundary_key).find(
            (sc) => sc.id === _column.id
          )
        ) {
          _column["isSecundary"] = true;
          copy.push(_column);
        }
        return _column;
      });
    }

    setLinkedColumns(linked || JSON.parse(state.linkedColumns));
    setStrategy(state.strategy);
    setSecundary(copy);
    setLoading(false);
  }, [state.linkedColumns, state.strategy]);

  const checkSecundary = (index, linkedColumn) => {
    const copy = [...linkedColumns];
    let copySecundary = [...secundary];
    copy[index].isSecundary = !copy[index].isSecundary;

    if (copy[index].isSecundary) {
      copySecundary.push(copy[index]);
    } else {
      if (
        criteria !== "" &&
        JSON.parse(criteria).find(
          (cri) => cri.secundarySelected.id === linkedColumn.id
        )
      ) {
        copy[index].isSecundary = !copy[index].isSecundary;
        swal("Error", "Priority defined for this column", "error");
      } else {
        copySecundary = copySecundary.filter(
          (item) => item.id !== linkedColumn.id
        );
      }
    }

    setLinkedColumns(copy);
    setSecundary(copySecundary);
  };

  const verifyStrategy = () => {
    setLoading(true);
    const data = {
      idStrategy: strategy.idStrategy || strategy.ID,
      primary: JSON.stringify(primary),
      secundary: JSON.stringify(secundary),
      criteria,
    };

    if (state.strategy.primary_key) {
      updateDetailStrategy(data);
    } else {
      createDetailStrategy(data);
    }
  };

  const updateDetailStrategy = (data) => {
    _put(
      `/detailStrategy/${strategy.id}`,
      data,
      (res) => {
        navigateCriteria(res);
      },
      () =>
        swal("Error", "An Error has occurred", "error").then(() => navigate(0))
    );
  };

  const createDetailStrategy = (data) => {
    _post(
      "/detailStrategy",
      data,
      (res) => {
        navigateCriteria(res);
      },
      () =>
        swal("Error", "An Error has occurred", "error").then(() => navigate(0))
    );
  };

  const navigateCriteria = (res) => {
    setLoading(false);
    if (res.data.ok) {
      navigate("/criteriaStrategy/", {
        state: {
          id: res.data.detailStrategy.id,
          idStrategy: res.data.detailStrategy.idStrategy,
          idProcess: state.strategy.idProcess
            ? state.strategy.idProcess
            : state.uidProcess,
          primary,
          secundary,
          criteria,
        },
      });
    } else {
      swal("Error", "An Error has occurred", "error").then(() => navigate(0));
    }
  };

  const getFormStep = () => {
    return (
      <div className="column">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>
                Primary key
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>Columns</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Secondary
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linkedColumns.map((linkedColum, index) => (
                <TableRow key={linkedColum.id}>
                  <TableCell style={{ textAlign: "center" }}>
                    <Checkbox
                      checked={linkedColum.check}
                      checkedIcon={checkedIcon}
                      disabled={!check1}
                      onClick={() => {
                        setCheck1(false);
                        setPrimary(linkedColum);
                        setIndexPrimary(index);
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: "355px" }}>
                    {linkedColum.description}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Checkbox
                      checked={linkedColum.isSecundary}
                      checkedIcon={checkedIcon}
                      disabled={index === indexPrimary}
                      onClick={() => checkSecundary(index, linkedColum)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div>
        {renderLoader()}
        <MainBar />
        <ProgressBar 
        nameProcess={strategy.name}
        nextStep={verifyStrategy}
        buttonOn={true}
        backStep={state.uidProcess ? `/channels/${state.uidProcess}` : `/channels/${state.strategy.idProcess}`}
        backProcess={navigate}
        stepIcons="strategy"
        
        />
        <div className="common">
          <div className="TableInputStrategy">
            <div>{getFormStep()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputStrategy;
