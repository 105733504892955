import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import "./modalResultProcess.css";

const ModalResultProcess = ({
  open,
  resultMessage,
  endProcessFile,
  processedRecords,
  failedRecords,
  downloadData,
  validateMsg,
  closeModalResult,
}) => {
  return (
    <Modal disableEscapeKeyDown open={open} className="modalResultProcess">
      <div className="paperResultProcess">
        <div style={{ width: "100%", height: "50%" }}>
          <div className="titleModal">
            {!validateMsg
              ? "File Converter Results:"
              : "files validation Results:"}{" "}
          </div>
          <div className="subtitleModal">
            {!validateMsg
              ? `${processedRecords} records were processed`
              : `${validateMsg}`}
          </div>

          {failedRecords !== 0 ? (
            <div className="subtitle">{failedRecords} records have error</div>
          ) : (
            ""
          )}
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        </div>
        {failedRecords !== 0 ? (
          <div>
            <div
              className="subtitle"
              style={{ marginTop: "10%", marginBottom: "10%" }}
            >
              Click on Download to view error logs
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <button
                variant="contained"
                size="large"
                className="buttonModal"
                onClick={() => {
                  downloadData();
                }}
              >
                Download
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "end",
              }}
            >
              <button
                variant="contained"
                size="large"
                className="buttonModal"
                onClick={() => {
                  !validateMsg ? endProcessFile() : closeModalResult();
                }}
              >
              {!validateMsg ? "End" : "Close"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalResultProcess;
