import { useState } from "react";
import Modal from "@mui/material/Modal";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import Divider from "@mui/material/Divider";
import { List, ListItem, ListItemText } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./modalUploadFiles.css";

const ModalUploadFiles = ({
  open,
  handleClose,
  requiredInput,
  uploadSingleFile,
  fileConverter,
  setLoading,
}) => {
  const [alert, setAlert] = useState(false);
  const [fileName, setfileName] = useState({});
  const [fileNames, setfileNames] = useState([]);

  const closeModal = () => {
    handleClose();
  };

  const handleFileUpload = async (key, event) => {
    if (event) {
      setLoading(true);
      fileNames.push(event.target.files[0].name);
      setfileName(event.target.files[0]);
      uploadSingleFile(key, event.target.files[0]);
      setLoading(false);
    }
  };

  const validateTotalFiles = () => {
    let ready = true;
    Object.values(requiredInput).forEach((value) => {
      if (!value) ready = false;
    });
    if (ready) {
      fileConverter();
    } else {
      setAlert(true);
    }
  };

  const getFormUpload = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <div className="uploadText">Required</div>
        <div>
          {Object.keys(requiredInput).map((key) => (
            <List dense key={key}>
              <ListItem className="containerUpload">
                <ListItemText>
                  <div className="nameFile">{key}</div>
                </ListItemText>
                <ListItemText>
                  <TextField
                    className="formControl"
                    name="file"
                    id="file"
                    type="file"
                    variant="outlined"
                    inputProps={{
                      accept:
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={(event) => {
                      handleFileUpload(key, event);
                    }}
                  />
                </ListItemText>
              </ListItem>
              <Divider component="li" />
            </List>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal disableEscapeKeyDown open={open} className="modalUploadFiles">
      <div className="paperModalUploadFiles">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            closeModal();
          }}
          className="closeIcon"
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="titleModal">Upload files to process</div>
          {alert ? (
            <div className="subtitleModal">
              The processing must be carried out with all the files
            </div>
          ) : (
            <div className="subtitleModal">
              Upload ALL files needed for processing
            </div>
          )}
        </div>
        <div className="contenedor_modal">
          <div style={{ height: "420px" }}>{getFormUpload()}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
            marginTop: "20px",
          }}
        >
          <button
            className="buttonNoModal"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            className="buttonModal"
            onClick={() => {
              validateTotalFiles();
            }}
          >
            Process
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUploadFiles;
