import { useMemo, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { _post } from "../../../common/generalRequests";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default function RecommendedChannelByWeekday() {
  const { uid } = useParams();
  const { state } = useLocation();
  const [labels, setLabels] = useState([]);
  const [scores, setScores] = useState([]);
  const [groups, setGroups] = useState([]);
  const [percents, setPercents] = useState([]);

  useEffect(() => {
    getDataChart();
  }, []);

  const getDataChart = () => {
    const data = { uidProcess: uid, uploadId: state.uploadId };
    _post(
      `/charts/recommendedChannelByWeekday`,
      data,
      (res) => {
        if (res.data.ok) {
          let [labelsArr, scoresArr, groupsArr, percentsArr] = [
            res.data.labels,
            res.data.managementDates,
            res.data.channels,
            res.data.percents,
          ];          
          setLabels(labelsArr);
          setScores(scoresArr);
          setGroups(groupsArr);
          setPercents(percentsArr);
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  };

  const getDatasets = () => {
    let _dataSets = [];
    groups.forEach((group, index) => {
      let color = randomColor();
      _dataSets.push({
        type: "bar",
        label: `${group} % `,
        data: percents[index],
        backgroundColor: color,
      });
    });
    return _dataSets;
  };

  const colorsArray = [
    "#B31262",
    "#FFE519",
    "#FF0081",
    "#19E3FF",
    "#099EB3",
    "#00B300",
    "#FF300D",
    "#BAFF19",
    "#0D62FF",
  ];
  let colorSelected = [];
  const randomColor = () => {
    let colorChart = "";
    colorChart = colorsArray[Math.floor(Math.random() * 9)];
    while (colorSelected.includes(colorChart)) {
      colorChart = colorsArray[Math.floor(Math.random() * 9)];
    }
    colorSelected.push(colorChart);
    return colorChart;
  };

  const options = {
    fill: false,
    responsive: true,
    scales: {
      y: {
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        yAlign: "bottom",
      },
      legend: {
        display: true,
      },
    },
  };
  const data = useMemo(
    function () {
      return {
        datasets: getDatasets(),
        labels,
      };
    },
    [labels, scores, groups, percents]
  );

  return <Chart type="bar" data={data} options={options} />;
}
