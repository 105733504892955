import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { _get, _put, decodeUser, _delete } from "../../common/generalRequests";
import MainBar from "../../common/components/mainBar/MainBar";
import { useNavigate } from "react-router";
import { Divider, Container, List, ListItem, Checkbox } from "@mui/material";
import deletePurple from "../../assets/svg/DeletePurple.svg";
import editIcon from "../../assets/svg/Editar.svg";
import guardarIcono from "../../assets/svg/GuardarIcono.svg";
import newTemplate from "../../assets/svg/NuevoModeloIcono.svg";
import SearchBar from "../../common/components/searchBar/SearchBar";
import swal from "sweetalert";
import Cookies from "js-cookie";
import ModalConfiguration from "../channelSelector/components/modalConfiguration/ModalConfiguration";
import "./channelSelector.css";
import RenderLoader from "../../common/helpers/RenderLoader";
import ModalTemplate from "./components/modalTemplate/modalTemplate";
import ModalInteractions from "./components/modalInteractions/Modalinteractions";
import ModalCreateStrategy from "../home/components/modalCreateStrategy/ModalCreateStrategy";
import ProgressBar from "../../common/components/progressBar/ProgressBar";
import { UnauthorizedMessage } from "../../common/helpers/UnauthorizedMessage";

const ChannelSelector = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  let inputPhone = document.querySelector(".InputPhone");
  const [isLoading, setLoading] = useState(true);
  const [process, setProcess] = useState({});
  const [templateSelected, setTemplateSelected] = useState({});
  const [searchValue] = useState("");
  const [allTemplates, setAllTemplates] = useState([]);
  const [finalTemplates, setFinalTemplates] = useState([]);
  const [allChannels, setAllChannels] = useState([]);
  const [channel, setChannel] = useState({});
  const [channelSelected, setChannelSelected] = useState("");
  const [providers, setProviders] = useState([]);
  const [openModalAddConfiiguration, setOpenModalAddConfiguration] =
    useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openInteractions, setOpenInteractions] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [nameProcess, setNameProcess] = useState("");
  const [idClient, setIdClient] = useState(null);
  const [clientSelected, setClientSelected] = useState("");
  const [openModalCreateStrategy, setOpenModalCreateStrategy] = useState(false);

  const user = decodeUser(localStorage.getItem("USER"));

  useEffect(() => {
    let roles = [0, 1];
    if (!roles.includes(user.rolUser)) {
      navigate("/home");
      UnauthorizedMessage();
    }
    if (!Cookies.get("XSRF-TOKEN")) navigate("/auth-session");
    getDataProcess(uid);
  }, [uid, navigate, openInteractions]);

  useEffect(() => {
    if (process.idClient) getDataClient(process.idClient);
  }, [process]);

  useEffect(() => {
    renderTableChannels();
  });

  const getDataProcess = (id) => {
    setLoading(true);
    _get(
      `/process/${id}`,
      (res) => {
        if (res.data.ok) {
          let input = JSON.parse(res.data.oneProcess.headers);
          if (!input) {
            swal(
              "Warning",
              "The project has not been correctly configured.",
              "warning"
            );
            navigate("/home");
          }
          setIdClient(res.data.oneProcess.idClient);
          setNameProcess(res.data.oneProcess.processName);
          setProcess(res.data.oneProcess);
          setLoading(true);
        }
      },
      () => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (idClient) {
      getClientSelected();
    }
  }, [idClient]);

  const getClientSelected = () => {
    _get(
      `/client/${idClient}`,
      (response) => {
        const clientR = response.data.client[0].fullname;
        setClientSelected(clientR);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        swal("Warning", error, "warning");
      }
    );
  };

  const getDataClient = (id) => {
    _get(
      `/clientById/${id}`,
      (res) => {
        if (res.data.ok) {
          const clientChannels = JSON.parse(res.data.client.channels);
          setAllChannels(clientChannels);
        }
        setLoading(false);
      },
      () => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const selectTemplate = (template) => {
    setLoading(true);
    _put(
      `/selectedTemplate/${template.id}/${template.uidProcess}`,
      {},
      (res) => {
        if (res.data.ok) {
          setSelected(template);
        }
        setLoading(false);
      },
      () => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const findSelected = (data) => {
    data.forEach((temp) => {
      if (temp.selected) setSelected(temp);
    });
  };

  const getTemplates = (id) => {
    _get(
      `/templateByChannel/${id}/${process.uid}`,
      (res) => {
        if (res.data.ok) {
          setAllTemplates(res.data.template);
          setFinalTemplates(res.data.template);
          findSelected(res.data.template);
        }
        setLoading(false);
      },
      () => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const filterTemplate = (value) => {
    if (value !== "") {
      const newTemplates = allTemplates.filter((templ) =>
        templ.templateName.includes(value)
      );
      setAllTemplates(newTemplates);
    } else {
      setAllTemplates(finalTemplates);
    }
  };

  const handleAddConfiguration = async (channelObj) => {
    setChannel(channelObj);
    getProvidersByChannelId(channelObj.id);
    setOpenModalAddConfiguration(true);
  };

  const getProvidersByChannelId = (idChannel) => {
    _get(
      `/provider/${idChannel}`,
      (res) => {
        if (res.data.ok) setProviders(res.data.provider);
      },
      () => {
        swal("Error", "An Error has occurred", "error");
        setLoading(false);
      }
    );
  };

  const handleClickdeleteTemplate = async (template) => {
    swal({
      title: `Are you sure you want to delete the template '${template.templateName}' ?`,
      text: "Once deleted, it will not be possible to recover it.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        _delete(
          `/template/${template.id}`,
          (res) => {
            if (res.data.ok) {
              swal(`The template has been removed!`, { icon: "success" }).then(
                () => {
                  getTemplates(channel.id);
                }
              );
            } else {
              setLoading(false);
              swal("Error", "Could not delete template", "error");
            }
          },
          () => {
            setLoading(false);
            swal("Error", "An Error has occurred", "error");
          }
        );
      }
    });
  };

  const handleTemplates = (channelObj) => {
    setChannel(channelObj);
    setChannelSelected(` - ${channelObj.name}`);
    setLoading(true);
    getTemplates(channelObj.id);
  };

  const renderTemplates = () => {
    if (user.rolUser === 3 || user.rolUser === 2) {
      return null;
    } else {
      return (
        <div className="tableHeaderTemplates">
          <div className="titleChannels">Templates {channelSelected}</div>
          <button
            disabled={channelSelected === ""}
            className="buttonInteracciones"
            onClick={() => {
              setEdit(false);
              setOpenTemplate(true);
            }}
          >
            {" "}
            <img src={newTemplate} alt="New Template" />
            New
          </button>
          <SearchBar placeholder="Search template" filter={filterTemplate} />
        </div>
      );
    }
  };

  const verifyStrategy = async () => {
    if (process.headers == "[]" || process.headers == null) {
      swal("Warning", "Please Upload file", "warning").then(() =>
        navigate("/home")
      );
    } else if (
      process.interactions == "[]" ||
      process.interactions == null ||
      JSON.parse(process.interactions).interactions.length == 0
    ) {
      swal("Warning", "Please Create Interactions", "warning").then(() =>
        navigate(0)
      );
    } else {
      setLoading(true);
      await _get(
        `/strategyByIdProcess/${uid}`,
        (res) => {
          setLoading(false);
          if (res.data.strategyP[0].name) {
            navigate(`/inputStrategy/`, {
              state: {
                linkedColumns: process.linkedColumns,
                strategy: res.data.strategyP[0],
                idProcess: uid,
                uidProcess: uid,
              },
            });
          } else {
            openModalStrategy();
          }
        },

        () => openModalStrategy()
      );
    }
  };

  const openModalStrategy = async () => {
    setOpenModalCreateStrategy(true);
  };

  const getFormTemplates = () => {
    return (
      <div className="tableTemplate">
        <div className="first_rowTemplates">
          <div className="titleTemplatesTable selectedTitle"></div>
          <div className="titleTemplatesTable nametitle">Name</div>
          <div className="titleTemplatesTable stateTitle">State</div>
          <div className="titleTemplatesTable doublewaytitle">Double via</div>
          <div className="titleTemplatesTable previewTitle">Preview</div>
          <div className="titleTemplatesTable phoneCodeTitle">PhoneCode</div>
          <div className="titleTemplatesTable actionsTitle">Actions</div>
        </div>
        <div className="overTableTemplates">
          {allTemplates.length > 0 ? (
            allTemplates.map((_template, index) => (
              <List className="rowTemplate" key={index}>
                <ListItem className="listItemTemplate">
                  <div className="templateGrid templateSelected">
                    {" "}
                    <input
                      type="radio"
                      className="templateSelectedS"
                      checked={_template === selected}
                      onChange={() => selectTemplate(_template)}
                    ></input>
                  </div>
                  <div className="templateGrid templateName">
                    {" "}
                    <span className="textTemplateName">
                      {_template.templateName}
                    </span>
                  </div>
                  <div className="templateGrid templateState">
                    {_template.state === true ? "Active" : "Inactive"}
                  </div>
                  <div className="templateGrid templateDoubleWay">
                    {_template.doubleWay === true ? "Yes" : "No"}
                  </div>
                  <div className="templateGrid templatePreview">
                    {_template.message}
                  </div>
                  <div className="templateGrid templatePhoneCode">
                    {_template.phoneCode}
                  </div>
                  <div className="templateGrid templateActions">
                    <div
                      onClick={() => {
                        handleClickdeleteTemplate(_template);
                      }}
                    >
                      <img src={deletePurple} alt="" className="actionIcon" />
                    </div>
                    <div
                      onClick={() => {
                        setEdit(true);
                        setOpenTemplate(true);
                        setTemplateSelected(_template);
                      }}
                    >
                      <img src={editIcon} alt="edit" className="actionIcon" />
                    </div>
                  </div>
                </ListItem>
              </List>
            ))
          ) : (
            <div className="noChannels">
              <span>No channels yet</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getFormChannels = () => {
    return (
      <div>
        {allChannels.length > 0 ? (
          allChannels.map((_channel, index) => (
            <List className="listItem" key={index}>
              <ListItem>
                <div className="checkboxChannels">
                  <Checkbox
                    checked={JSON.parse(_channel.channel.enabled)}
                    onClick={(e) => handleCheckbox(e, _channel.channel)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
                <div className="nameChannel">{_channel.channel.name}</div>
                <div className="buttonsChannelContainer">
                  <button
                    className="buttonChannel"
                    onClick={() => {
                      handleAddConfiguration(_channel.channel);
                    }}
                  >
                    Setting
                  </button>
                  {JSON.parse(_channel.channel.template) ? (
                    <button
                      className="ButtonTemplates"
                      onClick={() => {
                        handleTemplates(_channel.channel);
                      }}
                    >
                      Templates
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </ListItem>
            </List>
          ))
        ) : (
          <div className="title">No channels yet</div>
        )}
      </div>
    );
  };

  const handleCheckbox = (e, _channel) => {
    let newData = allChannels;
    newData.forEach((data) => {
      if (data.channel.name === _channel.name) {
        JSON.parse(_channel.enabled);
        data.channel.enabled = JSON.stringify(e.target.checked);
      }
    });
    setAllChannels(newData);
    updChannelState(newData);
  };

  const handleClose = () => {
    getTemplates(channel.id);
    setOpenTemplate(false);
  };

  const updChannelState = (arrayChannels) => {
    setLoading(true);
    let data = {
      idClient: process.idClient,
      arrayChannels: JSON.stringify(arrayChannels),
    };
    _put(
      `/channels/`,
      data,
      (res) => {
        if (res.data.ok) {
          setLoading(false);
        }
      },
      (error) =>
        swal(
          "Error",
          `It was not possible to save the information, please try again: ${error}`,
          "error"
        )
    );
  };

  const renderTableChannels = () => {
    if (user.rolUser === 3) {
      return (
        <div>Please contact your administrator to request permissions</div>
      );
    } else {
      return getFormChannels();
    }
  };

  const addConfiguration = () => {
    setOpenModalAddConfiguration(false);
  };

  return (
    <div className="container">
      <div>
        <RenderLoader loading={isLoading} />
        <MainBar clientName={clientSelected} />
        <ProgressBar
          nameProcess={nameProcess}
          nextStep={verifyStrategy}
          buttonOn={true}
          backStep={`/home`}
          backProcess={navigate}
          stepIcons="channelSelector"
        />
        <div className="commonChannels">
          <Container>
            <div className="boxChannels">
              <div className="containerChannels">
                <div className="tableChannelContainer">
                  <div className="main-box">
                    <div className="tableHeaderChannels">
                      <div className="titleChannels">Enable</div>
                      <div className="titleChannels">Channel</div>
                      <div className="titleChannels">Actions</div>
                      <button
                        className="buttonInteracciones"
                        onClick={() => {
                          setOpenInteractions(true);
                        }}
                      >
                        {" "}
                        <img src={guardarIcono} alt="interacciones" />{" "}
                        Interactions
                      </button>
                    </div>

                    {renderTableChannels()}
                  </div>
                </div>

                <div className="tableTemplateContainer">
                  <div className="main-box">
                    {renderTemplates()}
                    <Divider variant="middle" />
                    <div>{getFormTemplates()}</div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <ModalConfiguration
          open={openModalAddConfiiguration}
          close={() => {
            setOpenModalAddConfiguration(false);
          }}
          channel={channel}
          providersSelect={providers}
          idClient={process.idClient}
          addConfiguration={addConfiguration}
          setLoading={setLoading}
        />
        <ModalTemplate
          open={openTemplate}
          close={() => {
            handleClose();
          }}
          edit={edit}
          template={templateSelected}
          process={process}
          idChannel={channel.id}
          setLoading={setLoading}
        />
        <ModalInteractions
          open={openInteractions}
          close={() => {
            setOpenInteractions(false);
          }}
          allChannels={allChannels}
        />

        <ModalCreateStrategy
          open={openModalCreateStrategy}
          handleClose={() => {
            setOpenModalCreateStrategy(false);
          }}
          processData={uid}
        />
      </div>
    </div>
  );
};

export default ChannelSelector;
