import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import tpLogo from "./../../assets/brand/TPRecommenderLogo.webp";
import { _post, logOut } from "../../common/generalRequests";
import swal from "sweetalert";
import { useMsal } from "@azure/msal-react";
import { encode } from "js-base64";
import Cookies from "js-cookie";
import RenderLoader from "../../common/helpers/RenderLoader";
import { loginRequest } from "../../setup/authConfig";
const axios = require("axios");

export default function Login() {
  const baserURLKPIOauth = process.env.REACT_APP_BASEURL_KPI;
  const xsrfToken = Cookies.get("XSRF-TOKEN");
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    password: "",
    username: "",
    showPassword: false,
    isLoading: false,
  });

  useEffect(() => {
    if (xsrfToken) navigate("/auth-session");
  });

  const getKPIAccesToken = async (user) => {
    let data = {
      userName: process.env.REACT_APP_KPI_USERNAME,
      clientId: process.env.REACT_APP_KPI_CLIENT,
    };
    let options = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    };

    await axios
      .post(`${baserURLKPIOauth}/Oauth/GetAccessToken`, data, options)
      .then((res) => {
        insertTransaction(res.data.access_token, user);
      })
      .catch((e) => {});
  };

  const insertTransaction = async (accessTokenKPI, user) => {
    let data = {
      idApplicationClient: process.env.REACT_APP_ID_APPLICATION_CLIENT,
      userLogin: user,
    };
    let options = {
      headers: {
        Authorization: `Bearer ${accessTokenKPI}`,
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(`${baserURLKPIOauth}/Transaction`, data, options)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {});
  };

  function handleLogin() {
    setValues({ ...values, isLoading: true });
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        const dataLogin = {
          token: res.accessToken,
        };
        _post(
          "/login",
          dataLogin,
          (response) => {
            const successLogin = response.data || null;
            if (successLogin.ok) {
              Cookies.set(
                "XSRF-TOKEN",
                encode(encode(encode(encode(encode(successLogin.token))))),
                { secure: true, sameSite: "none", expires: 60 }
              );

              localStorage.setItem("TOKEN_KEY", xsrfToken);
              localStorage.setItem(
                "USER",
                encode(
                  encode(encode(encode(JSON.stringify(successLogin.user))))
                )
              );
              //Api kpi implementation
              getKPIAccesToken(successLogin.user);

              navigate("/auth-session");
            } else {
              setValues({ ...values, isLoading: false });
              swal("Error", "Login Failed", "error").then(() =>
                logOut(instance, accounts)
              );
            }
          },
          (error) => {
            swal(
              "Warning",
              error.message ? error.message : error,
              "warning"
            ).then(() => logOut(instance, accounts));
            setValues({ ...values, isLoading: false });
          }
        );
      })
      .catch((e) => {
        setValues({ ...values, isLoading: false });
        swal("error", "An error has ocurred", "error").then(() =>
          logOut(instance, accounts)
        );
      });
  }

  return (
    <div className="login-img">
      <RenderLoader loading={values.isLoading} />
      <div className="login-box">
        <div className="header-box">
          <img src={tpLogo} alt="TP-Logo" />
        </div>
        <div className="mid-box">
          <button
            variant="contained"
            size="large"
            className="buttonLogin"
            onClick={() => handleLogin()}
          >
            Log In
          </button>
        </div>
      </div>
    </div>
  );
}
