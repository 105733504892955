import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Textinput, onlyAlphabet } from "../../../../common/tools";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import {
  _get,
  _post,
  _put,
  decodeUser,
  _deleteMulti,
} from "../../../../common/generalRequests";
import swal from "sweetalert";
import "./registerModelPanel.css";
import ModalProcessing from "../../../processFile/components/ModalProcessing";

function RegisterModelPanel({
  modelMenu,
  setIsLoading,
  isLoading,
  stateModel,
}) {
  const [modelName, setModelName] = useState("");
  const [clients, setClients] = useState(null);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [dbName, setDBName] = useState(null); //{ id: 0, name: "", description: "" }
  const [modelColumns, setModelColumns] = useState([]);
  const [allInfereceColumns, setAllInferenceColumns] = useState([]);
  const [clientsByModelToEdit, setClientsByModelToEdit] = useState(null);
  const [scriptName, setScriptName] = useState("");
  const [openModalProcessing, setOpenModalProcessing] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [messageProcessing, setMessageProcessing] = useState("");
  const [columnsToEdit, setColumnsToEdit] = useState([]);
  const [inferenceColumnsToEdit, setInferenceColumnsToEdit] = useState([]);
  const [dbNames, setDbNames] = useState(null);
  const [allScripts, setAllScripts] = useState(null);
  const [loading, setLoading] = useState(true);
  const accountName = process.env.REACT_APP_ACCOUNT_NAME_SCRIPT_STORAGE;
  const containerName = process.env.REACT_APP_CONTAINER_NAME_SCRIPT_STORAGE;

  let user = decodeUser(localStorage.getItem("USER"));

  useEffect(() => {
    _get(
      `/transactionalTables`,
      (res) => {
        if (res.data.ok) {
          setDbNames(res.data.transactionalTables);
        }
      },
      (error) => {
        swal("Error", `${error}`, "error");
      }
    );

    _get(`/clients/${user.uid}`, (res) => {
      if (res.data.ok) {
        setClients(res.data.clients);
      }
    });
  }, []);

  useEffect(() => {
    if (modelMenu == "register") {
      _get(
        `/scripts/${accountName}/${containerName}`,
        (res) => {
          if (res.data.ok) {
            setAllScripts(res.data.scripts);
            setLoading(false);
          }
        },
        (error) => {
          swal("Error", `${error}`, "error");
          setLoading(false);
        }
      );
      if (!stateModel) {
        setDBName({ id: 0, name: "", description: "" });
        setModelName("");
        setAllInferenceColumns([]);
        setInferenceColumnsToEdit([]);
      }
    }
  }, [modelMenu]);

  useEffect(() => {
    if (dbName) {
      setIsLoading(true);
      _get(
        `/columns/${dbName.id}`,
        (res1) => {
          if (res1.data.ok) {
            let columnsArray = res1.data.column.map((column) => {
              column.fileHeader = [];
              column.conditionToColumn = null;
              column.conditionToValue = null;
              column.selected = columnsToEdit.includes(column.columnName)
                ? true
                : false;

              return column;
            });

            setModelColumns(columnsArray);
            setIsLoading(false);
          }
        },
        (error) => {
          swal("Error", `${error}`, "error");
          setIsLoading(false);
        }
      );
      if (dbName.id !== 0) {
        _get(
          `/inferenceColumns/${dbName.id}`,
          (res) => {
            if (res.data.ok) {
              let inferenceColumns = [];

              res.data.inferenceColumns.forEach((inferenceColumn) => {
                inferenceColumns.push({
                  name: inferenceColumn.description,
                  selected: inferenceColumnsToEdit.includes(
                    inferenceColumn.description
                  )
                    ? true
                    : false,
                });
              });
              setAllInferenceColumns(inferenceColumns);
              setIsLoading(false);
            }
          },
          (error) => {
            swal("Error", `${error}`, "error");
            setIsLoading(false);
          }
        );
      } else {
        setAllInferenceColumns([]);
      }
    }
  }, [dbName]);

  useEffect(() => {
    if (stateModel && dbNames && dbNames.length > 0) {
      getClientsByModel(stateModel.modelToEdit.id);
      setModelName(stateModel.modelToEdit.modelName);
      let DBNameToEdit = dbNames.find(
        (db) => db.name == stateModel.modelToEdit.tableName
      );
      setDBName(DBNameToEdit);

      setScriptName(stateModel.modelToEdit.inferenceScriptName);
      let arrayColumns = stateModel.modelToEdit.queryColumns;
      arrayColumns = arrayColumns.replaceAll("[", "");
      arrayColumns = arrayColumns.replaceAll("]", "");
      arrayColumns = arrayColumns.split(",");
      setColumnsToEdit(arrayColumns);

      let arrayInferenceColumns = stateModel.modelToEdit.inferenceColumns;
      arrayInferenceColumns = arrayInferenceColumns.replaceAll("[", "");
      arrayInferenceColumns = arrayInferenceColumns.replaceAll("]", "");
      arrayInferenceColumns = arrayInferenceColumns.split(",");

      setInferenceColumnsToEdit(arrayInferenceColumns);
    }
  }, [dbNames]);

  useEffect(() => {
    let columnsArray = modelColumns.map((column) => {
      column.fileHeader = [];
      column.conditionToColumn = null;
      column.conditionToValue = null;
      column.selected = columnsToEdit.includes(column.columnName)
        ? true
        : false;
      return column;
    });
    setModelColumns(columnsArray);
  }, [columnsToEdit]);

  const handleDeleteColumns = (value) => {
    let columns = modelColumns;
    columns[value].selected = false;
    setModelColumns(columns);
  };

  const handleChangeColumns = (value, columnName) => {
    setIsLoading(true);
    let columns = modelColumns;
    columns[value].selected = true;
    setModelColumns(columns);
    setIsLoading(false);
    if (stateModel) {
      if (!columns[value].required) {
        swal(
          "Info",
          "You must keep in mind that this column must be filled in previously created projects for it to continue working normally, otherwise you must link the column to a field that brings values from the file.",
          "info"
        );
      }
      let arrayNew = columnsToEdit;
      arrayNew.push(columnName);
      setColumnsToEdit(arrayNew);
    }
  };

  const getClientsByModel = (clientID) => {
    _get(
      `/modelsClients/bymodel/${clientID}`,
      (res) => {
        if (res.data.ok) {
          setClientsByModelToEdit(res.data.clients);

          let clientsModel = [];
          res.data.clients.forEach((client) => {
            clientsModel.push(client.id);
          });
          setClientsSelected(clientsModel);
        }
      },
      (error) => {
        swal("Error", `${error}`, "error");
        setIsLoading(false);
      }
    );
  };

  const handleDeleteInferenceColumns = (value) => {
    let infereceColumns = allInfereceColumns;
    infereceColumns[value].selected = false;
    setAllInferenceColumns(infereceColumns);
  };

  const handleChangeInferenceColumns = (value) => {
    let infereceColumns = allInfereceColumns;
    infereceColumns[value].selected = true;
    setAllInferenceColumns(infereceColumns);
  };

  const clearDBName = async (name) => {
    setDBName({ description: name, id: 0 });
    setAllInferenceColumns([]);
    setInferenceColumnsToEdit([]);

    return dbName;
  };

  const handleChangeDBName = async (_event, newValue) => {
    let db = await clearDBName(newValue.description);
    if (newValue) {
      setColumnsToEdit([]);
      setDBName(newValue);
    } else {
      if (stateModel) {
        setColumnsToEdit([]);
      }
      setDBName("");
    }
  };

  const handleChangeClients = (client) => {
    let clientsArray = clientsSelected;
    if (clientsArray.includes(client.id)) {
      clientsArray = clientsArray.filter((clientID) => clientID != client.id);
    } else {
      clientsArray.push(client.id);
    }
    setClientsSelected(clientsArray);
  };

  const renderInputs = () => {
    return (
      <>
        <div className="registeModelContainer">
          <Textinput
            label="Model name"
            onChange={(event) => {
              setModelName(event.target.value);
            }}
            fullWidth
            validate={onlyAlphabet}
            className="inputRegisterModel"
            clearable={true}
            value={modelName}
          />
          {dbNames ? (
            <FormControl className="inputNameModel" required={true}>
              <Autocomplete
                value={dbName}
                onChange={(_event, newValue) =>
                  handleChangeDBName(_event, newValue)
                }
                getOptionLabel={(option) => option.description}
                clearable={false}
                options={dbNames}
                defaultValue={
                  stateModel && dbNames
                    ? dbNames[stateModel.dbModelID - 1]
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Database Name"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          ) : null}
        </div>
      </>
    );
  };

  const clientsList = () => {
    return (
      <>
        <p className="panelTitle">Select Clients</p>

        <table className="columsTable">
          <thead>
            <tr className="tableHeader">
              <td>Client</td>

              <td>Select</td>
            </tr>
          </thead>
          <tbody>
            {clients
              ? clients.map((client, index) => (
                  <tr key={index}>
                    <td>{client.fullname}</td>

                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={
                          stateModel && clientsByModelToEdit
                            ? clientsByModelToEdit.some(
                                (clientSelected) =>
                                  clientSelected.shortname == client.fullname
                              )
                            : false
                        }
                        onChange={(event) => handleChangeClients(client)}
                        value={client}
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </>
    );
  };

  const renderQueryColumns = () => {
    return (
      <>
        <p className="panelTitle">Select query columns</p>

        <table className="columsTable">
          <thead>
            <tr className="tableHeader">
              <td>Columns</td>
              <td>Required</td>
              <td>select</td>
            </tr>
          </thead>
          <tbody>{renderColumnsItem()}</tbody>
        </table>
      </>
    );
  };

  const renderColumnsItem = () => {
    return (
      <>
        {modelColumns.length > 0
          ? modelColumns.map((column, index) => (
              <tr key={index} className={column.required ? "required" : ""}>
                <td>
                  {column.description[0] +
                    column.description.toLowerCase().slice(1)}
                </td>
                <td>{column.required ? "Required" : "Optional"}</td>

                <td>
                  <input
                    type="checkbox"
                    defaultChecked={column.selected}
                    onChange={(event) =>
                      column.selected
                        ? handleDeleteColumns(event.target.value)
                        : handleChangeColumns(
                            event.target.value,
                            column.columnName
                          )
                    }
                    value={index}
                  />
                </td>
              </tr>
            ))
          : null}
      </>
    );
  };

  const scriptList = () => {
    return (
      <>
        <p className="panelTitle">Select Script</p>

        <table className="columsTable">
          <thead>
            <tr className="tableHeader">
              <td>Scripts</td>

              <td>Select</td>
            </tr>
          </thead>
          <tbody>
            {allScripts
              ? allScripts.map((script, index) => (
                  <tr key={index}>
                    <td>{script}</td>

                    <td>
                      <input
                        type="checkbox"
                        checked={script == scriptName}
                        onChange={(event) => setScriptName(script)}
                        value={script}
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </>
    );
  };

  const registerClientsModel = (idModel, clients) => {
    let dataModelClients = {
      idModel: idModel,
      idClient: clients,
    };

    _post(
      "/modelsClients/",
      dataModelClients,
      (res) => {
        if (res.data.ok) {
          setMessageProcessing("registering Model clients in database");
          setPercentage(55);
        } else {
          swal("Error", `${res.data.error}`, "error");

          setOpenModalProcessing(false);
        }
      },
      (error) => {
        swal("Error", `${error}`, "error");
        setOpenModalProcessing(false);
      }
    );
  };

  const removeClientsModel = (idModel, clients) => {
    let dataModelClients = {
      idModel: idModel,
      idClient: clients,
    };

    _deleteMulti(
      "/modelsClients/",
      dataModelClients,
      (res) => {
        if (res.data.ok) {
          setMessageProcessing("deleting Model clients in database");
          setPercentage(8);
        } else {
          swal("Error", `${res.data.error}`, "error");

          setOpenModalProcessing(false);
        }
      },
      (error) => {
        swal("Error", `${error}`, "error");
        setOpenModalProcessing(false);
      }
    );
  };

  const registerModel = () => {
    if (
      /[^ ]/.test(modelName) &&
      modelName != "" &&
      scriptName != "" &&
      dbName != "" &&
      clients &&
      clients.length > 0 &&
      modelColumns.some((column) => column.selected) &&
      allInfereceColumns.some((inferenceColumn) => inferenceColumn.selected)
    ) {
      setOpenModalProcessing(true);
      setMessageProcessing("registering model");
      setPercentage(5);

      let columnsString = "[id],[UploadId],";
      let inferenceColumnsString = "";
      let columnsSelected = modelColumns.filter((column) => column.selected);
      let inferenceColumnsSelected = allInfereceColumns.filter(
        (inferenceColumn) => inferenceColumn.selected
      );
      columnsSelected.forEach((column, index) => {
        columnsString +=
          index === columnsSelected.length - 1
            ? `[${column.columnName}]`
            : `[${column.columnName}],`;
      });
      inferenceColumnsSelected.forEach((inferenceColumn, index) => {
        inferenceColumnsString +=
          index === inferenceColumnsSelected.length - 1
            ? `[${inferenceColumn.name}]`
            : `[${inferenceColumn.name}],`;
      });
      let data = {
        modelName:modelName.trim(),
        tableName: dbName.name,
        queryColumns: columnsString,
        inferenceColumns: inferenceColumnsString,
        inferenceScriptName: scriptName,
        active: 1,
        idProcessType: dbName.id,
      };
      let data3 = {
        name:modelName.trim(),
        properties: {
          description: "Model from the front",
        },
      };

      if (stateModel && clientsByModelToEdit) {
        let clientsToAdd = [];
        let clientsToRemove = [];
        clientsSelected.forEach((clientSelected) => {
          if (
            !clientsByModelToEdit.some(
              (clientToEdit) => clientToEdit.id == clientSelected
            )
          ) {
            clientsToAdd.push(clientSelected);
          }
        });
        clientsByModelToEdit.forEach((clientToEdit) => {
          if (!clientsSelected.some((client) => client === clientToEdit.id)) {
            clientsToRemove.push(clientToEdit.id);
          }
        });
        data.id = stateModel.modelToEdit.id;
        data.idProcesstype = stateModel.modelToEdit.idProcesstype;
        if (clientsToAdd.length > 0) {
          registerClientsModel(stateModel.modelToEdit.id, clientsToAdd);
        }
        if (clientsToRemove.length > 0) {
          removeClientsModel(stateModel.modelToEdit.id, clientsToRemove);
        }
        _put(
          "/models/",
          data,
          (res) => {
            if (res.data.ok) {
              setMessageProcessing("editing Model in database");
              setPercentage(60);
              setOpenModalProcessing(false);
              swal("Info", "Successfully Edited Model", "info");
            } else {
              swal("Error", `${res.data.error}`, "error");

              setOpenModalProcessing(false);
            }
          },
          (error) => {
            swal("Error", `${error}`, "error");
            setOpenModalProcessing(false);
          }
        );
      } else { 
        _post(
          "/models/",
          data,
          (res) => {
            if (res.data.ok) {
              setMessageProcessing("registering Model in database");
              setPercentage(50);
              registerClientsModel(res.data.models.id, clientsSelected);
            } else {
              swal("Error", `${res.data.error}`, "error");

              setOpenModalProcessing(false);
            }
          },
          (error) => {
            swal("Error", `${error}`, "error");
            setOpenModalProcessing(false);
          }
        );
        _post(
          "/modelsContainer",
          data3,
          (res) => {
            if (res.data.ok) {
              setPercentage(100);
              setMessageProcessing("Ready");
              setOpenModalProcessing(false);
              swal("Info", "Successfully Registered Model", "info");
            } else {
              swal("Error", `${res.data.error}`, "error");
              setOpenModalProcessing(false);
            }
          },
          (error) => {
            swal("Error", `${error}`, "error");
            setOpenModalProcessing(false);
          }
        );
      }
    } else {
      swal(
        "Info",
        "You must fill in the name field, choose a database, select at least one column, select at least one inference column and choose a Script for this model.",
        "info"
      );
    }
  };

  return modelMenu == "register" ? (
    <>
      <p className="panelTitle">Register model</p>

      {renderInputs()}

      {clientsList()}

      {renderQueryColumns()}

      <p className="panelTitle">Select Inference Columns</p>

      <table className="columsTable">
        <thead>
          <tr className="tableHeader">
            <td>Columns</td>

            <td>select</td>
          </tr>
        </thead>
        <tbody>
          {allInfereceColumns.length > 0
            ? allInfereceColumns.map((column, index) => (
                <tr key={index}>
                  <td>{column.name[0] + column.name.toLowerCase().slice(1)}</td>

                  <td>
                    <input
                      type="checkbox"
                      defaultChecked={column.selected}
                      onChange={(event) =>
                        column.selected
                          ? handleDeleteInferenceColumns(event.target.value)
                          : handleChangeInferenceColumns(event.target.value)
                      }
                      value={index}
                    />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      {scriptList()}

      <button className="registerModelButton" onClick={() => registerModel()}>
        {stateModel ? "Edit Model" : "Register Model"}
      </button>
      <ModalProcessing
        open={openModalProcessing}
        percentage={percentage}
        message={messageProcessing}
      />
    </>
  ) : null;
}

export default RegisterModelPanel;
