import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import "./modaInteractions.css";
import deployArrow from "../../../../assets/images/deployArrow.png";
import guardarIcono from "../../../../assets/svg/NuevoModeloIcono.svg";
import ModalCreateInteractions from "./modalCreateInteraction/ModalCreateInteractions";
import closeIcon from "../../../../assets/svg/CancelarGris.svg";
import deletePurple from "../../../../assets/svg/DeletePurple.svg";
import editIcon from "../../../../assets/svg/Editar.svg";
import anadirMorado from "../../../../assets/svg/AnadirMorado.svg";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ModalCreateSalto from "./modalCreateSalto/ModalCreateSalto";
import swal from "sweetalert";
import { _get, _put,_delete } from "../../../../common/generalRequests";
import { useParams } from "react-router-dom";
import RenderLoader from "../../../../common/helpers/RenderLoader";
import InteractionFlow from "./interactionFlow/InteractionFlow";

const ModalInteractions = ({ open, close, allChannels }) => {
  const { uid } = useParams();
  const [openCreateInteractions, setOpenCreateInteractions] = useState(false);
  const [openCreateSalto, setOpenCreateSalto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interactionsArray, setInteractionsArray] = useState([]);
  const [interactionsName, setInteractionsName] = useState("");
  const [interactionsData, setInteractionsData] = useState({});
  const [conditions, setConditions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [indexInteraction, setIndexInteraction] = useState(0);
  const [edit, setEdit] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);

  const [rules, setRules] = useState({
    idCondition: "",
    jumpTo: "",
    frecuency: "",
    idTemplate: "",
  });
  let frecuency = ["24h", "48h"];

  useEffect(() => {
    setLoading(true);
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          if (
            res.data.oneProcess.interactions !== null &&
            JSON.parse(res.data.oneProcess.interactions).interactions.length !==
              0
          ) {
            setInteractionsArray(
              JSON.parse(res.data.oneProcess.interactions).interactions
            );
            setInteractionsData(JSON.parse(res.data.oneProcess.interactions));
            setLoading(false);
          } else if (
            res.data.oneProcess.interactions !== null &&
            JSON.parse(res.data.oneProcess.interactions).interactions.length ===
              0
          ) {
            setInteractionsArray(
              JSON.parse(res.data.oneProcess.interactions).interactions
            );
            setLoading(false);
          }
          setLoading(false);
        }
      },
      (error) => swal("Error", error.message, "error")
    );

    _get(
      `/channelConditions/`,
      (res) => {
        if (res.data.ok) {
          setConditions(res.data.conditions);
        }
      },
      (error) => swal("Error", error.message, "error")
    );
    _get(
      `/templateByUID/${uid}`,
      (res) => {
        setTemplates(res.data.templates);
      },
      (error) => swal("Error", error.message, "error")
    );

    interactionsView();
  }, [open, openCreateSalto, openCreateInteractions, deleteToggle]);

  useEffect(() => {
    setIndexInteraction(0);
  }, [deleteToggle]);

  const deployFlow = (index) => {
    let flow = document.querySelectorAll(".interactionFlow");
    let deployIcon = document.querySelectorAll(".deployArrowIcon");
    flow[index].classList.toggle("deployed");
    deployIcon[index].classList.toggle("deployedIcon");
  };

  const cardChange = (rule, value, prop, index) => {
    rule[prop] = value;
    interactionsData.interactions[index].interaction.channels =
      interactionsArray[index].interaction.channels;

    let data = {};
    data = {
      interactions: JSON.stringify(interactionsData),
    };
    _put(
      `/editProcess/${uid}`,
      data,
      (res) => {
        if (res.data.ok) {
        }
      },
      (error) => swal("Error", error.message, "error")
    );
  };

  const editInteraction = (name) => {
    setInteractionsName(name);
    setOpenCreateInteractions(true);
    setEdit(true);
  };

  const deleteInteraction = (indexInteraction) => {
    setLoading(true);
    let data = {};
    let newArrayInteractions = interactionsData;
    newArrayInteractions.interactions = interactionsArray.filter(
      (arr, index) => index != indexInteraction
    );
    data = {
      interactions: JSON.stringify(newArrayInteractions),
    };

    _put(
      `/editProcess/${uid}`,
      data,
      (res) => {
        if (res.data.ok) {
          setDeleteToggle(!deleteToggle);
        }
      },
      (error) => swal("Error", error.message, "error")
    );
  };

  const interactionList = () => {
    return (
      <>
        {interactionsArray.map((inter, index) => (
          <div className="interactionContainer" key={index}>
            <div className="interactionRow">
              <img
                src={deletePurple}
                alt=""
                className="buttonsIcon"
                onClick={() => {
                  deleteInteraction(index);
                }}
              />
              <img
                src={editIcon}
                alt="edit"
                className="buttonsIcon"
                onClick={() => {
                  setIndexInteraction(index);
                  editInteraction(
                    inter.interaction.name ? inter.interaction.name : ""
                  );
                }}
              />
              <div
                className="interactionName"
                onClick={() => {
                  deployFlow(index);
                }}
              >
                <span>
                  {inter.interaction.name ? inter.interaction.name : ""}
                </span>
                <img
                  src={deployArrow}
                  className="deployArrowIcon"
                  alt="editar"
                />
              </div>
            </div>

            <div className="interactionFlow">
              {inter.interaction.channels.map((card, _index) =>
                _index !== inter.interaction.channels.length - 1 ||
                _index === 0 ? (
                  <div key={_index} className="cardInteraction">
                    <span className="titleCard">{card.channel.name}</span>
                    <div className="bodyCardInteraction">
                      <label>Rule</label>

                      <select
                        name="regla"
                        className="selectInteractions"
                        onChange={(_event) => {
                          cardChange(
                            card.channel.rule,
                            _event.target.value,
                            "idCondition",
                            index
                          );
                        }}
                      >
                        <option value="" selected disabled>
                        Select an option
                        </option>
                        {conditions
                          .filter(
                            (condition) =>
                              condition.idChannel === card.channel.idType
                          )
                          .map((option, index2) => (
                            <option
                              key={index2}
                              value={option.id}
                              selected={
                                card.channel.rule.idCondition == option.id
                                  ? true
                                  : false
                              }
                            >
                              {option.description}
                            </option>
                          ))}
                      </select>
                      {card.channel.rule.idCondition == 4 ?<>   <label>Si Código tipificación es</label>  <input type="text" name="condition"  className="inputModalInteractions" value={card.channel.rule.info1} onChange={(_event) => {
                          cardChange(
                            card.channel.rule,
                            _event.target.value,
                            "info1",
                            index
                          );
                        }}/></> : ""}

                      <label>Validation Frequency</label>
                      <select
                        name="frecuency"
                        className="selectInteractions"
                        onChange={(_event) => {
                          cardChange(
                            card.channel.rule,
                            _event.target.value,
                            "frecuency",
                            index
                          );
                        }}
                      >
                        <option value="" selected disabled>
                        Select an option
                        </option>
                        {frecuency.map((option, index2) => (
                          <option
                            key={index2}
                            value={option}
                            selected={
                              card.channel.rule.frecuency == option
                                ? true
                                : false
                            }
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      {inter.interaction.channels.length - 1 !== _index ? (
                        <>
                          <label>Jump</label>
                          <select
                            name="salto"
                            className="selectInteractions"
                            onChange={(_event) => {
                              cardChange(
                                card.channel.rule,
                                _event.target.value,
                                "jumpTo",
                                index
                              );
                            }}
                          >
                            {" "}
                            <option value="" selected disabled>
                            Select a channel
                            </option>
                            {allChannels
                              .filter(
                                (_channels) =>
                                  _channels.channel.id !== card.channel.idType
                              )
                              .map((option, index2) => {
                                console.log(card.channel.rule.jumpTo)
                                return ( <option
                                  key={index2}
                                  value={option.channel.name}
                                  selected={
                                    card.channel.rule.jumpTo ==
                                    option.channel.name
                                      ? true
                                      : false
                                  }
                                >
                                  {option.channel.name}
                                </option>)
                               
                                 } )}
                          </select>
                        </>
                      ) : (
                        ""
                      )}

                      {/*<label>Adicionales</label>
                  <select
                    name="plantillas"
                    className="selectInteractions"
                    onChange={(_event) => {
                      cardChange(
                        card.channel.rule,
                        _event.target.value,
                        "idTemplate",
                        index
                      );
                    }}
                  >
                    {templates
                      .filter(
                        (template) =>
                          template.idChannel === card.channel.idType
                      )
                      .map((option, index2) => (
                        <option
                          key={index2}
                          value={option.id}
                          selected={
                            card.channel.rule.idTemplate == option.id
                              ? true
                              : false
                          }
                        >
                          {option.templateName}
                        </option>
                      ))}
                  </select>*/}
                    </div>
                  </div>
                ) : (
                  " "
                )
              )}

              {inter.interaction.channels.length !== allChannels.length &&
              inter.interaction.name ? (
                <button
                  className="addCard"
                  onClick={() => {
                    setIndexInteraction(index);
                    setOpenCreateSalto(true);
                  }}
                >
                  {" "}
                  <img src={anadirMorado} alt="" />{" "}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  const interactionsView = () => {
    return (
      <div className="containerInteractions">
        {interactionsArray.length > 0 ? interactionList() : ""}
      </div>
    );
  };

  return (
    <div>
      <RenderLoader loading={loading} />
      <Modal open={open} onClose={close} className="modal">
        <div className="paperInteractions">
          <img
            src={closeIcon}
            className="buttonClose"
            alt="close"
            onClick={() => close()}
          />
          <div className="titleModalInteractions">
          Interaction Settings
          </div>
          <button
            className="buttonNuevaInteraccion"
            onClick={() => {
              setInteractionsName("");
              setEdit(false);
              setOpenCreateInteractions(true);
            }}
          >
            <img src={guardarIcono} alt="interacciones" /> New Channel Jump
          </button>

          {interactionsView()}
        </div>
      </Modal>

      <ModalCreateInteractions
        open={openCreateInteractions}
        close={() => {
          setOpenCreateInteractions(false);
        }}
        edit={edit}
        interactionName={interactionsName}
        indexInteraction={indexInteraction}
        interaction={interactionsData ? interactionsData : ""}
      />
      <ModalCreateSalto
        open={openCreateSalto}
        close={() => {
          setOpenCreateSalto(false);
        }}
        allChannels={allChannels}
        interaction={interactionsData ? interactionsData : ""}
        indexInteraction={indexInteraction}
      />
    </div>
  );
};

export default ModalInteractions;
