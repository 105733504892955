import { useEffect } from "react";
import { useNavigate } from "react-router";
import swal from "sweetalert";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    swal("warning", "Upps!, You will be redirect TpReach", "warning").then(
      () => {
        navigate("/auth-session");
      }
    );
  });

  return <div></div>;
};

export default NotFoundPage;
