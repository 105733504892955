import { useEffect, useState } from "react";
import swal from "sweetalert";
import { _get,decodeUser } from "../generalRequests";

export function useClients() {
  const [clientsList, setClientsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let user = decodeUser(localStorage.getItem("USER"));

  useEffect(() => {
    _get(`/clients/${user.uid}`, (res) => {
      if (res.data.ok) {
        setClientsList(res.data.clients);
        setIsLoading(false)
      }
    });
  }, []);
  return { clientsList, isLoading };
 
}






