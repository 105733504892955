import { useState } from "react";
import {
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const alphabet = new RegExp(/([A-Za-z])/g);

export const SelectInput = (props) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    if (typeof props.callback == "function") props.callback(event.target.value);
  };

  return (
    <FormControl {...props.formControlProps}>
      <InputLabel
        id="demo-simple-select-label"
        style={{ marginLeft: "15px", marginTop: "8px" }}
      >
        {props.label ? props.label : "{No Label}"}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        value={value}
        label={props.label ? props.label : "{No Label}"}
        onChange={handleChange}
        {...props.SelectProps}
      >
        {Array.isArray(props.values) &&
          props.values.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.value}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export const Textinput = (props) => {
  const [value, setValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (event) => {
    let localErrorMsg = "";
    if (event.target.value.length === 0) {
      setErrorMsg(localErrorMsg);
      return;
    }

    setValue(event.target.value);
    if (Array.isArray(props.validate)) {
      for (const prop of props.validate) {
        localErrorMsg = validateProp(event, prop);
      }
    } else {
      localErrorMsg = validateProp(event, props.validate);
    }

    if (typeof props.callback === "function" && localErrorMsg === "")
      props.callback(event.target.value);
    setErrorMsg(localErrorMsg);
  };

  const clearValue = () => {
    setValue("");
    setErrorMsg("");

    if (typeof props.callback === "function") props.callback("");
  };

  return (
    <TextField
      label={props.label ? props.label : "{No Label}"}
      variant="outlined"
      onChange={handleChange}
      value={value}
      InputProps={
        props.clearable
          ? {
              endAdornment: (
                <IconButton onClick={clearValue}>
                  <Close />
                </IconButton>
              ),
            }
          : null
      }
      helperText={errorMsg}
      FormHelperTextProps={{ style: { color: "#d50000" } }}
      {...props}
    />
  );
};

//---------------------------------------------------------------
//                         Validaciones
//---------------------------------------------------------------

const validateProp = (event, prop) => {
  if (typeof prop === "function") {
    const message = prop(event.target.value);
    return typeof message === "string" ? message : "";
  }
};

export const onlyNumbers = (value) => {
  if (value && alphabet.test(value)) return "Solo Números";
};

export const onlyAlphabet = (value) => {
  if (value && !alphabet.test(value)) return "Solo Letras";
};
