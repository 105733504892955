import { useState } from "react";
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../assets/svg/CancelarGris.svg";
import { _post } from "../../../../common/generalRequests";
import swal from "sweetalert";
import RenderLoader from "../../../../common/helpers/RenderLoader";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import * as XLSX from "xlsx";
import "./modalReport.css";
import Box from "@mui/material/Box";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalReport = ({ open, handleClose, clientToReport }) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const downloadxls = (data, name) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    let buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
    let str = XLSX.write(wb, { bookType: "xlsx", type: "binary" }); // generate a binary string in web browser
    XLSX.writeFile(wb, `${name}.xlsx`, { FS: ";" });
  };

  const downloadReport = () => {
    if (clientToReport && startDate && endDate) {
      swal({
        title: "Confirm",
        text: `are you sure you want to download the report of ${clientToReport.fullname}?`,
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((download) => {
        let data = {
          idclient: [clientToReport.id],
          fechaIni: startDate,
          fechaEnd: endDate,
        };

        _post("/reports", data, (res) => {
          if (res.data.ok) {
            if (res.data.reports.length > 0) {
              let data = res.data.reports;
              res.data.reports.forEach((report, index) => {
                let projects = "";
                delete report.usage;
                report.project.forEach((project) => {
                  projects +=
                    project.project + ": " + project.totalRecords + ",\n";
                });
                data[index].project = projects;
              });

              downloadxls(
                data,
                `Clients Upload Report - ${clientToReport.fullname}`
              );
            } else {
              swal("Warning", "No records", "warning");
            }
          }
        });
      });
    } else {
      swal("Warning", "fill in all fields", "warning");
    }
  };

  const closeModal = () => {
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  const modalContent = () => {
    return (
      <div className="paperCreateCollection">
        <img src={closeIcon} alt="close" onClick={() => closeModal()} />
        <div className="titleModal titleGenerateReport">
          Generate Report to {clientToReport.fullname}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "10px",
          }}
        >
          <FormControl style={{ width: "100%" }}>
            <CssTextField
              label="Start date"
              value={startDate}
              className="inputCondition inputDateReports"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="startDate"
              id="startDate"
              variant="outlined"
              InputProps={{ inputProps: { max: endDate } }}
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
          </FormControl>
          <FormControl style={{ width: "100%" }}>
            <CssTextField
              label="End date"
              value={endDate}
              className="inputCondition inputDateReports"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="endDate"
              id="endDate"
              variant="outlined"
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
              InputProps={{ inputProps: { min: startDate } }}
            />
          </FormControl>
        </div>

        <button
          className="buttonModal buttonDownloadReport"
          onClick={() => {
            //setLoading(true);
            downloadReport();
          }}
        >
          Download
        </button>
      </div>
    );
  };

  return (
    <div>
      <RenderLoader loading={loading} />
      <Modal open={open} onClose={closeModal} className="modal">
        <Box sx={{ width: "33%" }}>{clientToReport ? modalContent() : ""}</Box>
      </Modal>
    </div>
  );
};

export default ModalReport;
