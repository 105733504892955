import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import "./reportsMenu.css";
import { _post } from "../../../../common/generalRequests";
import { useClients } from "../../../../common/hooks/useClients.js";
import * as XLSX from "xlsx";
import swal from "sweetalert";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

function ReportsMenu({ setIsLoading }) {
  let { clientsList, isLoading } = useClients();
  const [client, setClient] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  setIsLoading(isLoading);

  const downloadxls = (data, name) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet");
    //let buf = XLSX.write(wb, { bookType: "xlsx", type: "buffer" }); // generate a nodejs buffer
    //let str = XLSX.write(wb, { bookType: "xlsx", type: "binary" }); // generate a binary string in web browser
    XLSX.writeFile(wb, `${name}.xlsx`, { FS: ";" });
  };

  const downloadReport = () => {
    if (client.length > 0 && startDate && endDate) {
      swal({
        title: "Confirm",
        text: `are you sure you want to download the report?`,
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((download) => {
        let data = {
          idclient: client,
          fechaIni: startDate,
          fechaEnd: endDate,
        };
        _post("/reports", data, (res) => {
          if (res.data.ok) {
            if (res.data.reports.length > 0) {
              let data = res.data.reports;

              res.data.reports.forEach((report, index) => {
                let projects = "";

                report.project.forEach((project) => {
                  projects +=
                    project.project + ": " + project.totalRecords + ",\n";
                });
                data[index].project = projects;
              });
              downloadxls(data, `Clients Upload Report - ${client}`);
            } else {
              swal("Warning", "No records", "warning");
            }
          }
        });
      });
    } else {
      swal("Warning", "fill in all fields", "warning");
    }
  };

  const addClient = (idClient) => {
    setIsLoading(true);
    let arrayClient = client;
    arrayClient.push(idClient);
    setClient(arrayClient);
    setIsLoading(false);
  };

  const deleteClient = (idClient) => {
    setIsLoading(true);
    let arrayClient = client;
    arrayClient = arrayClient.filter((item) => item !== idClient);
    setClient(arrayClient);
    setIsLoading(false);
  };

  return (
    <div className="reportsMenuContainer">
      <div className="panelTitle marginLeft">Select Clients</div>
      <div className="checkboxClients containerCheckboxReports">
        {clientsList
          ? clientsList.map((clients, index) => (
              <div>
                <input
                  type="checkbox"
                  id={clients.fullname}
                  name={clients.fullname}
                  value={clients.id}
                  //checked={clientsAssigned.includes(clients.id)}
                  onChange={(event) =>
                    client.includes(clients.id)
                      ? deleteClient(event.target.value)
                      : addClient(event.target.value)
                  }
                />
                <label for={clients.fullname}> {clients.fullname}</label>{" "}
              </div>
            ))
          : ""}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "10px",
        }}
      >
        <FormControl style={{ width: "100%" }}>
          <CssTextField
            label="Start date"
            value={startDate}
            className="inputCondition inputDateReports"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name="startDate"
            id="startDate"
            variant="outlined"
            InputProps={{ inputProps: { max: endDate } }}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
          />
        </FormControl>
        <FormControl style={{ width: "100%" }}>
          <CssTextField
            label="End date"
            value={endDate}
            className="inputCondition inputDateReports"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            name="endDate"
            id="endDate"
            variant="outlined"
            onChange={(event) => {
              setEndDate(event.target.value);
            }}
            InputProps={{ inputProps: { min: startDate } }}
          />
        </FormControl>
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <button
          className="registerModelButton"
          onClick={() => downloadReport()}
        >
          Download Report
        </button>
      </div>
    </div>
  );
}

export default ReportsMenu;
