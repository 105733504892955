import { useState, useEffect, Fragment } from "react";
import MainBar from "../../common/components/mainBar/MainBar";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Loader from "../../common/tools/loader/Loader";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import swal from "sweetalert";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  InputAdornment,
  Paper,
} from "@mui/material";
import { grey, purple } from "@mui/material/colors";
import { Textinput, onlyAlphabet } from "../../common/tools";
import {
  Search,
  DeleteForever,
  PersonAdd,
  VpnKey,
  Create,
  Check,
} from "@mui/icons-material";
import {
  _bulk,
  _put,
  _get,
  _post,
  _delete,
  decodeUser,
} from "./../../common/generalRequests";
import "./SuperAdmin.css";
import ModalProcessing from "../processFile/components/ModalProcessing";
import Cookies from "js-cookie";
import { encode } from "js-base64";
import ScriptMenu from "./components/scriptMenu/ScriptMenu";
import RegisterModelPanel from "./components/registerModelPanel/RegisterModelPanel.js";
import VersionModelMenu from "./components/versionModelMenu/VersionModelMenu.js";
import ReportsMenu from "./components/reportsMenu/ReportsMenu";
import { useClients } from "../../common/hooks/useClients";

export default function SuperAdmin() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isValidStepOne, setIsValidStepOne] = useState(false);
  const [rol, setRol] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({ username: "" });

  //stepper props
  const [activeStep, setActiveStep] = useState(0);
  const [userExists, setUserExists] = useState(false);
  const { height, width } = useWindowDimensions();

  // user values
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [userUID, SetUserUID] = useState("");
  const [allClients, setAllClients] = useState([]);
  const [clientsAssigned, setClientsAssigned] = useState([]);
  const [checkToggle, setCheckToggle] = useState(true);
  const [panelActive, setPanelActive] = useState("users");

  // client database
  const [nameNewDB, setNameNewDB] = useState(null);
  const [openModalProcessing, setOpenModalProcessing] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [messageProcessing, setMessageProcessing] = useState("");
  const [countrySelected, setCountrySelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [countries, setCountries] = useState([{ name: "" }]);
  const [regions, setRegions] = useState([{ name: "" }]);

  // models values
  let { clientsList } = useClients();
  const [modelMenu, setModelMenu] = useState("scripts");

  let user = decodeUser(localStorage.getItem("USER"));

  const buttonDeleteUser = {
    backgroundColor: "#FFFFFF",
    margin: "14px",
    color: "#A00095",
    borderRadius: "4px",
    border: "1px solid #A00095",
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "Noto Sans",
    transition: "all 0.3s ease 0s",
    "&:hover": {
      backgroundColor: "#A00095",
      color: "#FFFFFF",
      background: "rgba(122,33,132,255)",
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 4px 12px",
      transform: "translateY(-1px)",
    },
  };

  const buttonChangeRol = {
    color: "#FFFFFF",
    backgroundColor: "#A00095 !important",
  };

  const marginArroundLeft = {
    margin: "5px 5px 5px 0px",
  };

  const staticWidth = {
    width: "180px",
  };

  const paperClients = {
    padding: "0vw",
    width: "90%",
    marginTop: "5vh",
  };

  const input = {
    borderRadius: "4px",
    marginTop: 10,
  };

  const textInput = {
  width: "49.5%",
  };



  useEffect(() => {
    if (state) {
      setPanelActive("models");
      setModelMenu("register");
    }
    _get(
      `/countries/`,
      (res) => {
        if (res.data.ok) {
          setCountries(res.data.countries);
        }
      },
      (error) =>
        swal("Error", "An Error has occurred ", "error").then(() =>
          navigate("/home")
        )
    );
    _get(
      `/regions/`,
      (res) => {
        if (res.data.ok) {
          setRegions(res.data.regions);
        }
      },
      (error) =>
        swal("Error", "An Error has occurred ", "error").then(() =>
          navigate("/home")
        )
    );
    if (user.rolUser == 0) {
      _get(
        `/clients/`,
        (res) => {
          if (res.data.ok) {
            setAllClients(res.data.clients);
          }
        },
        (error) =>
          swal("Error", "An Error has occurred ", "error").then(() =>
            navigate("/home")
          )
      );
    } else if (user.rolUser == 1) {
      _get(`/clients/${user.uid}`, (res) => {
        if (res.data.ok) {
          setAllClients(res.data.clients);
          setIsLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (user.rolUser == 0) {
      _get(
        `/clients/`,
        (res) => {
          if (res.data.ok) {
            setAllClients(res.data.clients);
          }
        },
        (error) =>
          swal("Error", "An Error has occurred ", "error").then(() =>
            navigate("/home")
          )
      );
    }
  }, [checkToggle, panelActive]);

  useEffect(() => {
    if (userUID) {
      _get(
        `/clients/${userUID}`,
        (res) => {
          if (res.data.ok) {
            let clientsId = res.data.clients.map((client) => client.id);
            setClientsAssigned(clientsId);
            setIsLoading(false);
          }
        },
        (error) =>
          swal("Error", "An Error has occurred ", "error").then(() =>
            navigate("/home")
          )
      );
    }
  }, [userUID, checkToggle, panelActive]);

  useEffect(() => {
    getPanel();
    resetAsistant();
    setCountrySelected(null);
    setRegionSelected(null);
    setNameNewDB("");
  }, [panelActive]);

  //step 1
  const handleSubmitSearchUser = () => {
    if (values.username !== "") {
      setIsLoading(true);
      const data = {
        username: values.username,
      };
      _get(
        `/empleados/${values.username}`,
        (res) => {
          if (res.data.empleados.length) {
            setIsValidStepOne(true);
            setIsLoading(false);
            setActiveStep(1);
            setUserExists(true);
            setNombre(res.data.empleados[0].first_name);
            setApellido(res.data.empleados[0].last_name);
            _bulk(
              `/getOneUser`,
              data,
              (res) => {
                if (res.data.ok && res.data.users.length) {
                  setRol(res.data.users[0].rolUser);
                  SetUserUID(res.data.users[0].uid);
                } else {
                  setRol(-1);
                }
              },
              () => setRol(-1)
            );
          } else {
            swal(
              `'${values.username}' Not Found`,
              "Create user to continue",
              "info"
            );
            setIsValidStepOne(true);
            setIsLoading(false);
            setActiveStep(1);
            setUserExists(false);
          }
        },
        (error) => console.log(error)
      );
    } else {
      swal("Warning", "Please fill in the required fields", "warning");
    }
  };
  //step 2 -> case create
  const handleCreateUser = () => {
    if (values.username !== "" && nombre !== "" && apellido !== "") {
      setIsLoading(true);
      const data = {
        idLogin: values.username,
        first_name: nombre,
        last_name: apellido,
      };
      _post(
        `/empleados`,
        data,
        (res) => {
          if (res.data.ok) {
            setActiveStep(2);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        () => {
          swal("Error", "User could not be created", "error").then(() => {
            setIsLoading(false);
          });
        }
      );
    } else {
      swal("Warning", "Please fill in the required fields", "warning");
    }
  };
  //step 2 -> case update
  const handleUpdateUser = () => {
    if (values.username !== "" && nombre !== "" && apellido !== "") {
      setIsLoading(true);
      const data = {
        idLogin: values.username,
        first_name: nombre,
        last_name: apellido,
      };
      _put(
        `/empleados/${values.username}`,
        data,
        (res) => {
          if (res.data.ok) {
            setActiveStep(2);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        () => {
          swal("Error", "Could not update user", "error").then(() => {
            setIsLoading(false);
          });
        }
      );
    } else {
      swal("Warning", "Please fill in the required fields", "warning");
    }
  };
  //step 2 -> case update
  const handleAssingClients = () => {
    if (values.username !== "" && nombre !== "" && apellido !== "") {
      setIsLoading(true);
      const data = {
        idLogin: values.username,
        first_name: nombre,
        last_name: apellido,
      };
      _put(
        `/empleados/${values.username}`,
        data,
        (res) => {
          if (res.data.ok) {
            setActiveStep(3);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        () => {
          swal("Error", "Could not update user", "error").then(() => {
            setIsLoading(false);
          });
        }
      );
    } else {
      swal("Warning", "Please fill in the required fields", "warning");
    }
  };
  //step 2 -> case delete
  const handleDeleteUser = () => {
    swal({
      title: "This action is irreversible",
      text: `Are you sure you want to delete '${values.username}'?`,
      icon: "warning",
      buttons: {
        cancel: "No",
        delete: { text: "Yes", value: true },
      },
    }).then((value) => {
      if (value) {
        _delete(
          `/empleados/${values.username}`,
          (res) => {
            if (res.data.ok) {
              let data = {
                active: "0",
              };
              _put(`/user/${values.username}`, data, (res1) => {
                if (res1.data.ok) {
                  swal("User deleted", "the user has deleted", "success").then(
                    () => {
                      resetAsistant();
                    }
                  );
                }
              });
            } else {
              setIsLoading(false);
            }
          },
          () => {
            swal("Error", "Could not update user", "error").then(() => {
              setIsLoading(false);
            });
          }
        );
      }
    });
  };
  //step 3
  const handleClickChangeRol = () => {
    if (rol !== -1) {
      const data = {
        rolUser: rol,
      };
      // here
      _put(
        "/user/" + values.username,
        data,
        (res) => {
          if (res.data.ok) {
            swal("Good Job", "The role has changed", "success").then(() => {
              resetAsistant();
            });
          } else {
            swal(
              "Warning",
              "Something went wrong modifying the role",
              "warning"
            );
          }
        },
        () => swal("Error", "Something went wrong modifying the role", "error")
      );
    } else {
      swal("Warning", "Please select a role for the user", "warning");
    }
  };

  const handleCreateClient = (name) => {
    if (nameNewDB && countrySelected && regionSelected) {
      let clientsNames = [];
      allClients.forEach((clients) => {
        clientsNames.push(clients.fullname.toLowerCase());
      });
      if (clientsNames.includes(name.toLowerCase())) {
        swal(
          "Warning",
          "You cannot create a user with the same name",
          "warning"
        );
      } else {
        setIsLoading(true);
        setOpenModalProcessing(true);
        setPercentage(5);
        setMessageProcessing("Initiating client DB creation process");
        createClient(name);
      }
    } else {
      swal("Warning", "you must fill in all fields", "warning");
    }
  };

  const createClient = async (name) => {
    let data = {
      name: name,
    };
    _post(
      `/clients/createClient`,
      data,
      (res) => {
        if (res.data.ok) {
          setPercentage(33);
          setMessageProcessing(res.data.msg);
          createMigrations(res.data.config, name);
        } else {
          setIsLoading(false);
          setOpenModalProcessing(false);
          swal("Error", `${res.data.error}`, "error");
        }
      },
      (error) => {
        swal("Error", "An Error has occurred ", "error");
        setIsLoading(false);
        setOpenModalProcessing(false);
      }
    );
  };

  const createMigrations = async (config, name) => {
    let data = config;
    _post(
      `/clients/createMigrations`,
      data,
      (res) => {
        if (res.data.ok) {
          setPercentage(66);
          setMessageProcessing(res.data.msg);
          createStoredProcedures(config, name);
        } else {
          setIsLoading(false);
          setOpenModalProcessing(false);
          swal("Error", `${res.data.error}`, "error");
        }
      },
      (error) => {
        swal("Error", "An Error has occurred ", "error");
        setIsLoading(false);
        setOpenModalProcessing(false);
      }
    );
  };

  const createStoredProcedures = async (config, name) => {
    let data = {
      config,
      name,
      uidCountry: countrySelected.id,
      uidRegion: regionSelected.id,
    };
    _post(
      `/clients/createStoredProcedures`,
      data,
      (res) => {
        if (res.data.ok) {
          setPercentage(0);
          setMessageProcessing("");
          setIsLoading(false);
          setOpenModalProcessing(false);
          swal("Info", "Client created successfully", "info");
        } else {
          swal("Error", `${res.data.error}`, "error");
          setIsLoading(false);
          setOpenModalProcessing(false);
        }
      },
      (error) => {
        swal("Error", "An Error has occurred ", "error");
        setIsLoading(false);
        setOpenModalProcessing(false);
      }
    );
  };

  const handleChangeClients = (client) => {
    setIsLoading(true);
    

    const data = {
      idUser: userUID,
      idClient: client,
    };
    _post(
      `/clientsuser`,
      data,
      (res) => {
        if (res) {
          Cookies.set(
            "XSRF-TOKEN",
            encode(encode(encode(encode(encode(res.data.newToken))))),
            { secure: true, sameSite: "none", expires: 60 }
          );
          localStorage.setItem("TOKEN_KEY", Cookies.get("XSRF-TOKEN"));
       
          setCheckToggle(!checkToggle);
          
          swal("Good Job", "The client has assigned", "success");
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        console.log(error);
        swal("Error", "User client could not be assigned", "error").then(() => {
          setIsLoading(false);
        });
      }
    );
  };

  const handleDeleteClients = (client) => {
    setIsLoading(true);
    

    const data = {
      idUser: userUID,
      idClient: client,
    };
    _post(
      `/deleteClientsUser`,
      data,
      (res) => {
        if (res) {
          setCheckToggle(!checkToggle);
          swal("Good Job", "The client has deassigned", "success");
        } else {
          setIsLoading(false);
        }
      },
      () => {
        swal("Error", "User could not be deleted", "error").then(() => {
          setIsLoading(false);
        });
      }
    );
  };

  const resetAsistant = () => {
    setActiveStep(0);
    setUserExists(false);
    setNombre("");
    setApellido("");
    setValues({ username: "" });
    setRol(-1);
  };

  //interface components
  const renderStepOne = () => {
    return (
      <div
        style={{
          padding: "15px",
        }}
      >
        <OutlinedInput
          value={values.username}
         
          required
          placeholder="Enter a Network User (Ex: user.5)"
          onChange={(e) => setValues({ username: e.target.value })}
          fullWidth
          onKeyPress={(event) =>
            event.code == "Enter" ? handleSubmitSearchUser() : null
          }
          endAdornment={
            <InputAdornment position="end">
              <Button
                variant="contained"
                size="large"
                className="searchUserButton"
                onClick={() => handleSubmitSearchUser()}
              >
                {width < 800 ? <Search /> : "Search user"}
              </Button>
            </InputAdornment>
          }
        />
      </div>
    );
  };
  const renderStepOneAndHalf = () => {
    return (
      <div>
        <OutlinedInput
          label="CCMS"
          value={values.username}
          disabled
          fullWidth
          style={{ marginBottom: "30px", marginTop: "15px" }}
          endAdornment={
            userExists ? (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  size="large"
                  sx={[buttonDeleteUser, buttonChangeRol]}
                  onClick={handleDeleteUser}
                >
                  <DeleteForever />
                </Button>
              </InputAdornment>
            ) : null
          }
        />
        <div className="stepperContainer2">
          
          <Textinput
            label="Name"
            sx={textInput}
            onChange={(event) => {
              setNombre(event.target.value);
            }}
            validate={onlyAlphabet}
            clearable={true}
            value={nombre}
          />

          <Textinput
            label="Last name"
            sx={textInput}
            onChange={(event) => {
              setApellido(event.target.value);
            }}
            validate={onlyAlphabet}
            clearable={true}
            value={apellido}
          />
        </div>
      </div>
    );
  };
  const renderStepTwo = () => {
    return (
      <div
        style={{
          padding: "15px",
        }}
      >
        <Select
          onChange={(e) => setRol(e.target.value)}
          value={rol}
          variant="outlined"
          fullWidth
        >
          <MenuItem value={"-1"} disabled>
            Select a role
          </MenuItem>
          <MenuItem value={"2"}>User</MenuItem>
          <MenuItem value={"1"}>Administrator</MenuItem>
          {user.rolUser === 0 ? (
            <MenuItem value={"0"}>Super Administrator</MenuItem>
          ) : null}
        </Select>
      </div>
    );
  };

  const clientsPanel = () => {
    return panelActive == "clients" ? (
      <>
        <p className="panelTitle">Create New Client</p>
        <div className="superAdminInputsContainer">
          <FormControl required={true}>
            <Autocomplete
              value={countrySelected}
              className="text"
              onChange={(_event, newValue) => {
                setCountrySelected(newValue);
              }}
              getOptionLabel={(option) => option.name}
              options={countries}
              renderInput={(params) => (
                <TextField {...params} label="*Country" variant="outlined" />
              )}
            />
          </FormControl>
          <FormControl required={true}>
            <Autocomplete
              value={regionSelected}
              className="text"
              onChange={(_event, newValue) => {
                setRegionSelected(newValue);
              }}
              getOptionLabel={(option) => option.name}
              options={regions}
              renderInput={(params) => (
                <TextField {...params} label="*Region" variant="outlined" />
              )}
            />
          </FormControl>
        </div>

        <div className="adminPanel">
          <Textinput
            label="Name of new client"
            onChange={(event) => {
              if (event.target.value.length < 2 && event.target.value == "") {
                setNameNewDB(null);
              } else {
                if (
                  event.target.value.indexOf(" ") === -1 &&
                  /([A-Za-z0-9])$/.test(event.target.value) &&
                  event.target.value.length < 23
                ) {
                  setNameNewDB(event.target.value);
                } else {
                  swal(
                    "warning",
                    "Do not type spaces, special characters or exceed 22 characters.",
                    "warning"
                  );
                }
              }
            }}
            fullWidth
            validate={onlyAlphabet}
            sx={[input, marginArroundLeft]}
            clearable={true}
            value={nameNewDB}
          />

          <button
            className="createClientButton"
            onClick={() => handleCreateClient(nameNewDB)}
          >
            Create Client
          </button>
        </div>
      </>
    ) : null;
  };

  const usersPanel = () => {
    return panelActive === "users" ? (
      <div className="stepsContainer">
        {" "}
        <Stepper
          activeStep={activeStep}
          orientation={width < 800 ? "vertical" : "horizontal"}
          style={{ width: "80vw",alignSelf:"center" }}
        >
          <Step active={activeStep === 0 ? true : false}>
            <StepLabel
              icon={
                activeStep >= 1 ? (
                  <Check style={{ color: purple[800] }} />
                ) : (
                  <Search
                    style={{
                      color: activeStep >= 0 ? purple[800] : grey[900],
                    }}
                  />
                )
              }
            >
              Enter a User
            </StepLabel>
            {width < 800 ? <StepContent>{renderStepOne()}</StepContent> : null}
          </Step>
          <Step active={activeStep == 1 ? true : false}>
            <StepLabel
              icon={
                activeStep >= 2 ? (
                  <Check style={{ color: purple[800] }} />
                ) : userExists ? (
                  <Create
                    style={{
                      color: activeStep >= 1 ? purple[800] : grey[900],
                    }}
                  />
                ) : (
                  <PersonAdd
                    style={{
                      color: activeStep >= 1 ? purple[800] : grey[900],
                    }}
                  />
                )
              }
            >
              Register User
            </StepLabel>
            {width < 800 ? (
              <StepContent>{renderStepOneAndHalf()}</StepContent>
            ) : null}
          </Step>
          <Step active={activeStep == 2 ? true : false}>
            <StepLabel
              icon={
                <VpnKey
                  style={{
                    color: activeStep >= 2 ? purple[800] : grey[900],
                  }}
                />
              }
            >
              {userExists ? "Update" : "Assign"} role
            </StepLabel>
            {width < 800 ? <StepContent>{renderStepTwo()}</StepContent> : null}
          </Step>

          <Step active={activeStep === 3 ? true : false}>
            <StepLabel
              icon={
                <VpnKey
                  style={{
                    color: activeStep >= 3 ? purple[800] : grey[900],
                  }}
                />
              }
            >
              assign clients
            </StepLabel>
            {width < 800 ? (
              <StepContent>{renderStepThree()}</StepContent>
            ) : null}
          </Step>
        </Stepper>
        <Fragment>
          <div
            style={{
              display:
                width < 800 ? "none" : activeStep == 0 ? "block" : "none",
            }}
          >
            {renderStepOne()}
          </div>
          <div
            style={{
              display:
                width < 800 ? "none" : activeStep == 1 ? "block" : "none",
              padding: "0 15px",
            }}
          >
            {renderStepOneAndHalf()}
          </div>
          <div
            style={{
              display:
                width < 800 ? "none" : activeStep == 2 ? "block" : "none",
            }}
          >
            {renderStepTwo()}
          </div>
          <div
            style={{
              display:
                width < 800 ? "none" : activeStep == 3 ? "block" : "none",
            }}
          >
            {renderStepThree()}
          </div>
        </Fragment>
        <div
          style={{
            display: width < 800 ? "block" : "flex",
            gap: "10px",
            marginBottom: "14px",
            marginTop: "14px",
            justifyContent: "center",
            flexDirection: width < 800 ? "column" : "row",
          }}
        >
          {activeStep > 0 ? (
            <Button
              variant="contained"
              size="large"
              sx={[
                buttonDeleteUser,
                buttonChangeRol,
                staticWidth,
              ]}
              onClick={resetAsistant}
              fullWidth={width < 800 ? true : false}
            >
              Back
            </Button>
          ) : null}
          {activeStep == 1 && userExists ? (
            <Button
              variant="contained"
              size="large"
              sx={[buttonDeleteUser, buttonChangeRol,staticWidth]}
              onClick={() => setActiveStep(2)}
              fullWidth={width < 800 ? true : false}
            >
              Change role
            </Button>
          ) : null}
          {activeStep == 1 ? (
            <Button
              variant="contained"
              size="large"
              sx={[buttonDeleteUser,buttonChangeRol,staticWidth]}
              onClick={userExists ? handleUpdateUser : handleCreateUser}
              fullWidth={width < 800 ? true : false}
            >
              {userExists ? "Update" : "Create"} User
            </Button>
          ) : null}
          {activeStep == 2 ? (
            <Button
              variant="contained"
              size="large"
              sx={[buttonDeleteUser,buttonChangeRol,staticWidth]}
              onClick={() => handleClickChangeRol()}
              fullWidth={width < 800 ? true : false}
            >
              {userExists ? "Update" : "Assign"} role
            </Button>
          ) : null}
          {activeStep == 1 && userExists ? (
            <Button
              variant="contained"
              size="large"
              sx={[buttonDeleteUser,buttonChangeRol,staticWidth]}
              onClick={() => handleAssingClients()}
              fullWidth={width < 800 ? true : false}
            >
              ASSIGN CLIENTS
            </Button>
          ) : null}
        </div>
      </div>
    ) : null;
  };

  const modelsPanel = () => {
    return panelActive == "models" ? (
      <>
        <div className="adminMenu">
          {user.rolUser == 0 ? (
            <div
              className={`adminMenuItem ${
                modelMenu == "scripts" ? "selectedMenu" : null
              }`}
              onClick={() => {

                setModelMenu("scripts");
              }}
            >
              Upload Model Script
            </div>
          ) : null}
          <div
            className={`adminMenuItem ${
              modelMenu == "register" ? "selectedMenu" : null
            }`}
            onClick={() => {

              setModelMenu("register");
            }}
          >
            Register model
          </div>
          {user.rolUser == 0 ? (
            <div
              className={`adminMenuItem ${
                modelMenu == "modelVersions" ? "selectedMenu" : null
              }`}
              onClick={() => setModelMenu("modelVersions")}
            >
              Model versions
            </div>
          ) : null}
        </div>

        <ScriptMenu
          modelMenu={modelMenu}
          width={width}
          setIsLoading={setIsLoading}
        />
        <RegisterModelPanel
          modelMenu={modelMenu}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          stateModel={state}
        />
        <VersionModelMenu
          modelMenu={modelMenu}
          setIsLoading={setIsLoading}
        />
      </>
    ) : null;
  };

  const reportsPanel = () => {
    return panelActive == "reports" ? (
      <ReportsMenu setIsLoading={setIsLoading} />
    ) : null;
  };

  const getPanel = () => {
    return (
      <Paper elevation={1} sx={paperClients}>
        <div className="adminContainer">
          <div className="adminMenu">
            <div
              className={`adminMenuItem ${
                panelActive == "users" ? "selectedMenu" : null
              }`}
              onClick={() => setPanelActive("users")}
            >
              Users
            </div>
            {user.rolUser === 0 ? (
              <>
                <div
                  className={`adminMenuItem ${
                    panelActive == "clients" ? "selectedMenu" : null
                  }`}
                  onClick={() => setPanelActive("clients")}
                >
                  Clients
                </div>
                <div
                  className={`adminMenuItem ${
                    panelActive == "models" ? "selectedMenu" : null
                  }`}
                  onClick={() => setPanelActive("models")}
                >
                  Models
                </div>
              </>
            ) : null}
            <div
              className={`adminMenuItem ${
                panelActive == "reports" ? "selectedMenu" : null
              }`}
              onClick={() => setPanelActive("reports")}
            >
              Reports
            </div>
          </div>
          {usersPanel()}

          {clientsPanel()}

          {modelsPanel()}

          {reportsPanel()}
        </div>
      </Paper>
    );
  };
  const renderStepThree = () => {
    return (
      <div className="stepperContainer">
        <div className="checkboxClients">
          {allClients.length > 0
            ? allClients.map((clients, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={clients.fullname}
                    name={clients.fullname}
                    value={clients.id}
                    checked={clientsAssigned.includes(clients.id)}
                    onChange={(event) =>
                      clientsAssigned.includes(clients.id)
                        ? handleDeleteClients(event.target.value)
                        : handleChangeClients(event.target.value)
                    }
                  />
                  <label for={clients.fullname}> {clients.fullname}</label>{" "}
                </div>
              ))
            : ""}
        </div>
      </div>
    );
  };
  const renderLoader = () => {
    if (isLoading) {
      return <Loader />;
    } else {
      return null;
    }
  };

  return (
    <>
      <section className="section_sa">
        {renderLoader()}
        <MainBar />

        {user.rolUser == 2 || user.rolUser == 3 ? (
          navigate("/home")
        ) : (
          <>{getPanel()}</>
        )}
      </section>
      <ModalProcessing
        open={openModalProcessing}
        percentage={percentage}
        message={messageProcessing}
      />
    </>
  );
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
