import { useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import { DataGrid } from '@mui/x-data-grid';
import Divider from "@mui/material/Divider";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import "./modalCSV.css";

const ModalCSV = ({
  open,
  handleClose,
  data,
  columns,
  handleApproveFile,
  handleDenyFile,
  setFileUpload
}) => {
  const formRef = useRef();
  const [fileName, setfileName] = useState(null);
  const approveFile = () => {
    formRef.current.reportValidity();
    if (fileName) {
      setFileUpload(true)
      handleApproveFile(fileName);
      setfileName(null);
    }
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      className="modalCsv"
    >
      <div className="paperCsv">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            handleDenyFile();
          }}
          className="closeIcon"
        />
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className="titleModalCsv">Checks Columns</div>
          <div className="subtitleModal">
          Select ID column as Primary Key and define the columns to evaluate
          </div>
        </div>
        <form ref={formRef} className="formCsv">
          <label className="labelModalCsv">File Name*</label>
          <input
            required
            className="inputModalCsv"
            name="name"
            id="name"
            onChange={(event) => {
              setfileName(event.target.value);
            }}
          />
        </form>
        <div style={{  width: "100%",height:"500px" }}>
          <DataGrid
            //className="tableCsv"
            //noHeader
            //pagination
            //highlightOnHover
            sx={{
              width:"100%",
              '& .MuiDataGrid-cell': {
               display:"flex",
               justifyContent: 'center',
              },
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },}}
            columns={columns}
            rows={data}
            //defaultSortFieldId={1}
          />
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <button
            className="buttonModal"
            onClick={() => {
              approveFile();
            }}
          >
            Agregar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCSV;
