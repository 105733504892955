import axios from "axios";
import { decode } from "js-base64";
import { ClearSesion } from "./helpers/ClearSesion";

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const config = {
  headers: {
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
  },
};

const options = {
  withCredentials: true,
  timeout: 36000000,
};

export function decodeUser(user) {
  return JSON.parse(decode(decode(decode(decode(user)))));
}

export const logOut = async (instance, accounts) => {
  await axios.post(baseUrl + "/logout", {}, options).then(() => {
    instance.logoutPopup({
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      mainWindowRedirectUri: process.env.REACT_APP_REDIRECT_LOGOUT,
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_LOGOUT,
    });
  });
};

export const _post = async (end, data, callback, callbackError) => {
  await axios
    .post(`${baseUrl}${end}`, data, options)
    .then((res) => callback(res))
    .catch((e) => {
      if (e.response) {
        callbackError(e.response.data.error);
      } else {
        callbackError(e);
      }
    });
};

export const _bulk = async (end, data, callback, callbackError) => {
  const newConfig = {
    config: { ...config.headers, "Content-type": "multipart/form-data" },
    withCredentials: true,
  };

  await axios
    .post(`${baseUrl}${end}`, data, newConfig)
    .then((res) => callback(res))
    .catch((e) => {
      if (e.response) {
        callbackError(e.response.data.error);
      } else {
        callbackError(e);
      }
    });
};

export const _get = async (end, callback, callbackError) => {
  try {
    await axios
      .get(`${baseUrl}${end}`, options)
      .then((res) => callback(res))
      .catch((e) => {
        if (e.response) {
          callbackError(e.response.data.error);
        } else {
          callbackError(e);
        }
      });
  } catch (e) {
    if (e.response) {
      callbackError(e.response.data.error);
    } else {
      callbackError(e);
    }
  }
};

export const _put = async (end, data, callback, callbackError) => {
  await axios
    .put(`${baseUrl}${end}`, data, options)
    .then((res) => callback(res))
    .catch((e) => {
      if (e.response) {
        callbackError(e.response.data.error);
      } else {
        callbackError(e);
      }
    });
};

export const _delete = async (end, callback, callbackError) => {
  await axios
    .delete(`${baseUrl}${end}`, options)
    .then((res) => callback(res))
    .catch((e) => {
      if (e.response) {
        callbackError(e.response.data.error);
      } else {
        callbackError(e);
      }
    });
};

export const _deleteMulti = async (end, data, callback, callbackError) => {
  options.data = data;
  await axios
    .delete(`${baseUrl}${end}`, options)
    .then((res) => callback(res))
    .catch((e) => {
      if (e.response) {
        callbackError(e.response.data.error);
      } else {
        callbackError(e);
      }
    });
};
