import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../assets/svg/CancelarGris.svg";
import { useState, useEffect } from "react";
import { _put } from "../../../../../common/generalRequests";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import RenderLoader from "../../../../../common/helpers/RenderLoader";

const ModalCreateInteractions = ({
  open,
  close,
  edit,
  interactionName,
  indexInteraction,
  interaction,
}) => {
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const [nameInteraction, setNameInteraction] = useState("");

  useEffect(() => {
    if (open) {
      setNameInteraction(interactionName);
    }
  }, [open]);

  const createInteraction = () => {
    setLoading(true);
    let data = {};
    let interactions = {};
    let newInteraction = {};

    if (edit) {
      interaction.interactions[indexInteraction].interaction.name =
        nameInteraction;
      interactions = interaction;
    } else if (interaction.interactions) {
      newInteraction = {
        interaction: {
          name: nameInteraction,
          selected: true,
          channels: [],
        },
      };

      interaction.interactions.push(newInteraction);
      interactions = interaction;
    } else {
      interactions = {
        interactions: [
          {
            interaction: {
              name: nameInteraction,
              selected: true,
              channels: [],
            },
          },
        ],
      };
    }

    data = {
      interactions: JSON.stringify(interactions),
    };

    _put(
      `/editProcess/${uid}`,
      data,
      (res) => {
        if (res.data.ok) {
          setLoading(false);
          handleClose();
        }
      },
      (error) => swal("Error", error.message, "error")
    );
  };

  const handleClose = () => {
    setNameInteraction("");
    close();
  };

  return (
    <div>
      <RenderLoader loading={loading} />
      <Modal open={open} onClose={handleClose} className="modal">
        <div className="paperCreateCollection">
          <img
            src={closeIcon}
            alt="close"
            onClick={() => handleClose()}
            className="closeIcon"
          />
          <span className="titleModal">Create Interaction</span>
          <label className="labelModal">Name</label>

          <input
            type="text"
            className="inputModal"
            onChange={(event) => {
              setNameInteraction(event.target.value);
            }}
            value={nameInteraction}
          />

          <button
            className="buttonModal"
            onClick={() => {
              if (Object.keys(interaction).length !== 0) {
                if (
                  interaction.interactions.find(
                    (_interaction) =>
                      _interaction.interaction.name === nameInteraction
                  ) &&
                  edit
                ) {
                  swal(
                    "Warning",
                    "You cannot Create two Interactions with the same name",
                    "warning"
                  );
                } else {
                  createInteraction();
                }
              } else {
                createInteraction();
              }
            }}
          >
            {edit ? "Edit" : "Create"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateInteractions;
