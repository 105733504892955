import { useEffect, useState, useReducer } from "react";
import "./InputFiles.css";
import deletePurple from "../../assets/svg/DeletePurple.svg";
import subirIcono from "../../assets/svg/SubirIcono.svg";
import Chip from "@mui/material/Chip";
import MainBar from "../../common/components/mainBar/MainBar";
import Loader from "../../common/tools/loader/Loader";
import guardarIcono from "../../assets/svg/GuardarIcono.svg";
import { useParams } from "react-router-dom";
import { _put, _get, decodeUser } from "../../common/generalRequests";
import { useNavigate } from "react-router";
import TextField from "@mui/material/TextField";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import swal from "sweetalert";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import * as CSVDetectDelimiter from "csv-string";
import ModalCSV from "./components/ModalCSV";
import ModalAddCalculation from "../../pages/calculationsView/components/ModalAddCalculation";
import * as XLSX from 'xlsx/xlsx.mjs';
import ProgressBar from "../../common/components/progressBar/ProgressBar.js";
import { UnauthorizedMessage } from "../../common/helpers/UnauthorizedMessage";

function InputFiles() {
  const navigate = useNavigate();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  let { uid } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [datos, setDatos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [indexFile, setIndexFile] = useState(null);
  const [calculationsOn, setCalculationsOn] = useState(false);
  const [inputHeadersInfo, setInputHeadersInfo] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [openModalAddCalculation, setOpenModalAddCalculation] = useState(false);
  const [calculationOptions, setCalculationOptions] = useState([]);
  const [inputHeaders, setInputHeaders] = useState([]);
  const [nameProcess, setNameProcess] = useState("");
  const [idClient, setIdClient] = useState(null);
  const [idModel, setIdModel] = useState(null);
  const [processType, setProcessType] = useState(null);
  const [modelColumns, setModelColumns] = useState(null);
  const [clientSelected, setClientSelected] = useState("");
  const [modelID, setModelID] = useState(null);
  const [fileUpload, setFileUpload] = useState(false);

  useEffect(() => {
    let user = decodeUser(localStorage.getItem("USER"));

    let roles = [0, 1];
    if (!roles.includes(user.rolUser)) {
      navigate("/home");
      UnauthorizedMessage();
    }
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          setIdClient(res.data.oneProcess.idClient);
          setNameProcess(res.data.oneProcess.processName);
          setModelID(res.data.oneProcess.idModel);
          setProcessType(res.data.oneProcess.processType);
          getIdProcessFromIdModel(res.data.oneProcess.idModel);
          if (res.data.oneProcess.headers) {
            let headersFile = JSON.parse(res.data.oneProcess.headers);
            setInputHeadersInfo(headersFile);
            setCalculationsOn(true);
            //setLoading(false);
          } else {
            setLoading(false);
          }
        }
      },
      (_error) =>
        swal("Error", "An error occurred in the database query", "error").then(
          () => navigate("/home")
        )
    );
    swal(
      "Info",
      "The first file to upload must contain the primary keys, and all the records you want to process.",
      "info"
    );
  }, []);

  useEffect(() => {
    if (idClient) {
      _get(
        `/client/${idClient}`,
        (response) => {
          const clientR = response.data.client[0].fullname;
          setClientSelected(clientR);
          //setLoading(false);
        },
        (error) => {
          setLoading(false);
          swal("Warning", error, "warning");
        }
      );
    }
  }, [idClient]);

  useEffect(() => {
    if (idModel) {
      _get(
        `/columns/${idModel}`,
        (res1) => {
          if (res1.data.ok) {
            let columnsArray = res1.data.column.map((column) => {
              column.fileHeader = [];
              column.conditionToColumn = null;
              column.conditionToValue = null;
              return column;
            });
            _get(
              `/models/${modelID}`,
              (res2) => {
                if (res2.data.ok) {
                  setLoading(true);
                  setModelColumns(
                    columnsArray.filter(
                      (column) =>
                        column.required ||
                        res2.data.queryColumns.includes(column.columnName)
                    )
                  );
                  setLoading(false);
                }
              },
              (error) =>
                swal("Error", `${error}`, "error").then(() => navigate("/home"))
            );
          }
        },
        (error) =>
          swal("Error", `${error}`, "error").then(() => navigate("/home"))
      );
    }
  }, [idModel]);

  useEffect(() => {
    calculations();
  }, [inputHeadersInfo]);

  useEffect(() => {
    _get(
      `/calculation`,
      (response) => {
        if (response.data.ok) {
          setCalculationOptions(response.data.calculations);
        }
      },
      () =>
        swal("Error", "Error getting calculation information", "error").then(
          () => navigate("/home")
        )
    );
    _get(
      `/process/${uid}`,
      (res) => {
        if (res.data.ok) {
          if (res.data.oneProcess.headers) {
            let dataCalculate = JSON.parse(res.data.oneProcess.headers);
            setInputHeadersInfo(dataCalculate);

            let inputHeadersArr = [];
            Object.entries(dataCalculate).forEach(([key, value]) => {
              let obj = {
                name: key,
                headersByFile: value,
              };
              inputHeadersArr.push(obj);
            });
            setInputHeaders(inputHeadersArr);
            //setLoading(false);
          } else {
            setLoading(false);
          }
        }
      },
      () =>
        swal("Error", "Error getting process information", "error").then(() =>
          navigate("/home")
        )
    );
  }, [calculationsOn, uid]);

  const columns = [
    {
      headerName: "Primary Key",
      field: "primaryKey",
      width: 100,
      headerAlign: "center",
      renderCell: (value) => (
        <Checkbox
          checked={value.row.primaryKey}
          disabled={value.row.id === 0}
          onChange={() => handleChangePrimaryKey(value.row)}
        />
      ),
    },
    {
      headerName: "Header",
      field: "Header",
      width: 535,
      headerAlign: "center",
      sortable: true,
    },
    {
      headerName: "Select",
      field: "select",
      width: 100,
      headerAlign: "center",
      renderCell: (value) => (
        <Checkbox
          checked={value.row.select}
          onChange={() => {
            selectHeader(value.row);
          }}
        />
      ),
    },
  ];

  const getIdProcessFromIdModel = (idModel) => {
    _get(
      `/models/${idModel}`,
      (res) => {
        if (res.data.ok) {
          setIdModel(res.data.model.idProcesstype);
          //setLoading(false);
        }
      },
      (_error) =>
        swal("Error", "An error occurred in the database query", "error").then(
          () => navigate("/home")
        )
    );
  };

  const handleFileUpload = async (event, index) => {
    let fileToModal = event.target.files[0];

    if (fileToModal) {
      let newFiles = files;
      setFiles(newFiles);
      setIndexFile(index);
      let data;
      await fileToModal.arrayBuffer().then((res) => {
        if (
          fileToModal.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          data = new Uint8Array(res);
          let workbook = XLSX.read(data, {
            type: "array",
            cellStyles: true,
            sheetRows: 1,
          });
          let firstSheet = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[firstSheet];
          data = XLSX.utils
            .sheet_to_json(worksheet, { range: 0, header: 1 })[0]
            .join(",");
          workbook = null;
          firstSheet = null;
          worksheet = null;
        } else {
          data = new TextDecoder("latin1").decode(new Uint8Array(res));
        }
      });

      processData(data, fileToModal.type);
      setLoading(false);
    }
  };

  const processData = (dataString, fileType) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    let delimiter = CSVDetectDelimiter.detect(dataString);
    let headers =
      fileType === "text/plain"
        ? dataStringLines[0].split("|")
        : dataStringLines[0].split(delimiter);
    let listData = [
      {
        id: 0,
        Header: "select all",
        primaryKey: false,
        select: false,
      },
    ];
    let blankSpaces = false;
    headers.forEach((head) => {
      if (head === "") {
        blankSpaces = true;
      }
    });
    if (!blankSpaces) {
      for (let i in headers) {
        let obj = {};
        obj["primaryKey"] = false;
        obj["id"] = parseInt(i) + 1;
        obj["Header"] = headers[i];
        obj["select"] = false;
        obj["calculation"] = [];
        listData.push(obj);
      }

      setDatos(listData);
      setLoading(false);
      setOpenModal(true);
    } else {
      setLoading(false);
      swal("Error", "The file contains blank headers", "error").then(() => {
        handleDeleteFile(indexFile);
      });
    }
  };

  const approveFile = (fileName) => {
    let selectedData = datos.filter((dato) => dato.select);
    if (selectedData.length === 0) {
      swal(
        "Error",
        "You must select at least one column to continue",
        "error"
      ).then(() => {
        setDatos([]);
        setIndexFile(null);
        setOpenModal(false);
        handleDeleteFile(indexFile);
      });
    } else {
      let fileSameName = files.filter((file) => file.name === fileName);
      if (fileSameName.length > 0) {
        swal("Error", "A file with this name already exists", "error");
      } else {
        let newFile = files;
        let newData = datos
          .filter((dato) => dato.primaryKey || dato.select)
          .filter((dato) => dato.id !== 0);
        newFile[indexFile].file = newData;
        newFile[indexFile].name = fileName;
        setFiles(newFile);
        setDatos([]);
        setIndexFile(null);
        setOpenModal(false);
      }
    }
  };

  const denyFile = () => {
    handleDeleteFile(indexFile);
    setOpenModal(false);
  };

  const handleChangePrimaryKey = (value) => {
    let newData = datos;
    newData.forEach((data) => {
      data === value
        ? (data.primaryKey = !data.primaryKey)
        : (data.primaryKey = false);
    });
    setDatos(newData);
    forceUpdate();
  };

  const selectHeader = (value) => {
    let newData = datos;
    if (value.id === 0) {
      newData.forEach((data) => {
        data.select = !data.select;
      });
      setDatos(newData);
    } else {
      newData.forEach((data) => {
        if (data === value) {
          data.select = !data.select;
        }
      });
      setDatos(newData);
    }
    forceUpdate();
  };

  const handleAddFile = () => {
    let newFiles = files;
    newFiles.push({ name: null, file: null });
    setFiles(newFiles);
    forceUpdate();
  };

  const handleDeleteFile = (index) => {
    let newFiles = files;
    newFiles.splice(index, 1);
    setFiles(newFiles);
    if (newFiles.length === 0) {
      setFileUpload(false);
    }
    forceUpdate();
  };

  const deleteBlankFiles = () => {
    setFiles(files.filter((file) => file.file !== null));
    files.forEach((file, index) => {
      if (!file.file || file.file === null) {
        handleDeleteFile(index);
      }
    });
    saveAndContinue();
  };

  const saveAndContinue = () => {
    if (files.length > 0 && files.length !== 1) {
      let totalPK = true;
      files.forEach((file, index) => {
        if (totalPK) {
          if (file.name && file.file !== null && file) {
            let arrPK = file.file.filter((head) => head.primaryKey);
            if (arrPK.length === 0) {
              swal({
                title: `Please check the information`,
                text: `File ${file.name} does not contain primary key, you need to add it again`,
                icon: "warning",
                dangerMode: true,
              }).then(() => {
                handleDeleteFile(index);
              });
              totalPK = false;
            }
            let arrSelected = file.file.filter((head) => head.select);
            if (arrSelected.length === 0) {
              swal({
                title: `Please check the information`,
                text: `From the file  ${file.name} no column was selected, you need to add it again`,
                icon: "warning",
                dangerMode: true,
              }).then(() => {
                handleDeleteFile(index);
              });
              totalPK = false;
            }
          }
        }
      });
      if (totalPK) {
        swal({
          title: `Please check the information`,
          text: `These files cannot be reconfigured`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((save) => {
          if (save) {
            saveData();
          }
        });
      }
    } else if (files.length === 1) {
      if (files[0].name && files[0].file) {
        let arrSelectedOneFile = files[0].file.filter((file) => file.select);
        if (arrSelectedOneFile.length === 0) {
          swal({
            title: `Please check the information`,
            text: `No column selected, you need to add the file again`,
            icon: "warning",
            dangerMode: true,
          }).then(() => {
            handleDeleteFile(0);
          });
        } else {
          swal({
            title: `Please check the information`,
            text: `These files cannot be reconfigured`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((save) => {
            if (save) {
              saveData();
            }
          });
        }
      }
    } else {
      if (Object.entries(inputHeadersInfo).length !== 0) {
        saveData();
      } else {
        swal("Warning", "At least one file is required to continue", "warning");
      }
    }
  };

  const saveData = () => {
    let filesData = {};
    let data = {};
    files.forEach((file) => {
      if (file.file !== null) {
        filesData[`${file.name}`] = file.file;
      }
    });
    if (Object.entries(inputHeadersInfo).length !== 0) {
      let mergeHeaders = {};
      mergeHeaders = Object.assign(inputHeadersInfo, filesData);
      data = {
        headers: JSON.stringify(mergeHeaders),
        linkedColumns: JSON.stringify([]),
        validationColumn: JSON.stringify([]),
      };
    } else {
      data = {
        headers: JSON.stringify(filesData),
        linkedColumns: JSON.stringify([]),
        validationColumn: JSON.stringify([]),
      };
    }
    let dataCalculate = JSON.parse(data.headers);
    let inputHeadersArr = [];
    Object.entries(dataCalculate).forEach(([key, value]) => {
      let obj = {
        name: key,
        headersByFile: value,
      };
      inputHeadersArr.push(obj);
    });
    setInputHeaders(inputHeadersArr);
    _put(
      `/editProcess/${uid}`,
      data,
      (res) => {
        if (res.data.ok) {
          setInputHeadersInfo(JSON.parse(data.headers));
          setFiles([]);
        }
      },
      () =>
        swal(
          "Error",
          "It was not possible to save the information, please try again",
          "error"
        )
    );
  };

  const saveDeleteUploadedFile = (headers) => {
    let data = {
      headers: JSON.stringify(headers),
      linkedColumns: JSON.stringify([]),
      validationColumn: JSON.stringify([]) /*,
      processType: files.length === 1 ? 3 : 4*/,
    };

    _put(
      `/editProcess/${uid}`,
      data,
      (res) => {
        if (res.data.ok) {
          setInputHeadersInfo(headers);
        }
      },
      () =>
        swal(
          "Error",
          "It was not possible to save the information, please try again",
          "error"
        )
    );
  };

  const deleteUploadedFile = (nameFile) => {
    let newInputHeadersInfo = inputHeadersInfo;
    delete newInputHeadersInfo[nameFile];
    if (Object.entries(newInputHeadersInfo).length === 0) {
      setFileUpload(false);
      setCalculationsOn(false);
    }
    let dataCalculate = newInputHeadersInfo;

    let inputHeadersArr = [];
    Object.entries(dataCalculate).forEach(([key, value]) => {
      let obj = {
        name: key,
        headersByFile: value,
      };
      inputHeadersArr.push(obj);
    });
    setInputHeaders(inputHeadersArr);
    setInputHeadersInfo(newInputHeadersInfo);
    saveDeleteUploadedFile(inputHeadersInfo);
  };

  const fileList = () => {
    if (files.length > 0) {
      return (
        <div className="filesList">
          {files.map((file, index) => (
            <List dense key={index}>
              <Divider component="li" />
              <ListItem>
                <ListItemText>
                  <div className="subtitle fileName">{file.name}</div>
                </ListItemText>
                <ListItemText>
                  <TextField
                    disabled={file.file ? true : false}
                    className="formcontrol"
                    name="file"
                    margin="dense"
                    id="file"
                    type="file"
                    variant="outlined"
                    inputProps={{
                      accept:
                        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={(event) => {
                      setLoading(true);
                      handleFileUpload(event, index);
                    }}
                  />
                </ListItemText>
                <ListItemSecondaryAction>
                  <Tooltip title="Delete" placement="bottom-end">
                    <button
                      className="buttonDeleteFile buttonDeleteFileUploaded"
                      onClick={() => {
                        handleDeleteFile(index);
                      }}
                    >
                      <img
                        src={deletePurple}
                        className="deleteFileIcon"
                        alt="delete icon"
                      />
                    </button>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component="li" />
            </List>
          ))}

          <button
            className={`buttonUploadFile ${
              !fileUpload ? "disabledButton" : ""
            }`}
            disabled={!fileUpload}
            onClick={() => {
              deleteBlankFiles();
              setFileUpload(false);
              setCalculationsOn(true);
            }}
          >
            <img src={guardarIcono} alt="Save File" className="buttonIcon" />
            Save File
          </button>
        </div>
      );
    } else if (calculationsOn && Object.keys(inputHeadersInfo).length !== 0) {
      return <div></div>;
    }
    return <span>No file found</span>;
  };

  const calculations = () => {
    if (calculationsOn && Object.entries(inputHeadersInfo).length !== 0) {
      return (
        <div>
          <div className="innerBoxCalculations">
            {Object.entries(inputHeadersInfo).map(([key, value]) => (
              <div className="listItemCalculation" key={key}>
                <div className="borderCalculation">
                  <div>
                    <div className="subtitleCalculations">
                      {" "}
                      {key}{" "}
                      <Tooltip title="Delete File" placement="bottom-end">
                        <button
                          className="buttonDeleteFileUploaded "
                          onClick={() => {
                            deleteUploadedFile(key);
                          }}
                        >
                          <img
                            src={deletePurple}
                            className="deleteFileIcon"
                            alt="delete icon"
                          />
                        </button>
                      </Tooltip>
                    </div>

                    <div className="colComponentsCalculations">
                      {value?.map((fileHeader, index) =>
                        fileHeader.calculation.length > 0 ? (
                          <div key={index}>
                            <hr />
                            <div className="fieldTitle">
                              {" "}
                              <span>Inputs</span> <span>Calculation</span>{" "}
                            </div>
                            <div className="rowCalculations">
                              <div className="columnCalculations">
                                <Chip
                                  className="chipRegla chipRegla1"
                                  key={index}
                                  label={fileHeader.Header}
                                />
                              </div>
                              <div className="gridCalculations">
                                {fileHeader.calculation.map((cal, _index) => (
                                  <div className="calculationItem" key={index}>
                                    {renderAllAsAColumn(
                                      cal,
                                      _index,
                                      key,
                                      fileHeader
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <button
              className="buttonUploadFile"
              onClick={() => {
                handleAddCalcutation();
              }}
            >
              <PostAddIcon />
              Add calculation
            </button>
          </div>
        </div>
      );
    }
    return <span>You must upload the file first</span>;
  };

  const handleAddCalcutation = () => {
    if (verifyClient(null, uid)) {
      setOpenModalAddCalculation(true);
    } else {
      swal("error", "Client not authorized to perform this action", "error");
      return false;
    }
  };

  const addCalculation = (file, column, calculation) => {
    setDataChanged(true);
    let newInputHeaders = inputHeadersInfo;
    newInputHeaders[`${file}`].forEach((input) => {
      if (input.id === column.id) {
        input.calculation.push(calculation);
      }
    });
    setInputHeadersInfo(newInputHeaders);
    setOpenModalAddCalculation(false);
    forceUpdate();
  };

  const deleteCalculation = (file, column, calculation) => {
    if (verifyClient(null, uid)) {
      setDataChanged(true);
      let newInputHeaders = inputHeadersInfo;
      newInputHeaders[`${file}`].forEach((input) => {
        if (input.id === column.id) {
          input.calculation.splice(input.calculation.indexOf(calculation), 1);
        }
      });
      setInputHeadersInfo(newInputHeaders);
      setOpenModalAddCalculation(false);
      forceUpdate();
    } else {
      swal("error", "Client not authorized to perform this action", "error");
    }
  };

  const saveCalculationsAndContinue = () => {
    if (verifyClient(null, uid)) {
      if (dataChanged) {
        swal({
          title: "Are you sure you want to continue?",
          text: "This section can be configured later",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((save) => {
          if (save) {
            let data = {
              headers: JSON.stringify(inputHeadersInfo),
            };
            _put(
              `/editProcess/${uid}`,
              data,
              (res) => {
                if (res.data.ok) {
                  navigate(`/link/${uid}`);
                }
              },
              () =>
                swal(
                  "Error",
                  "It was not possible to save the information, please try again.",
                  "error"
                )
            );
          }
        });
      } else {
        navigate(`/link/${uid}`);
      }
    } else {
      swal("error", "Client not authorized to perform this action", "error");
    }
  };

  const renderAllAsAColumn = (cal, index, header, fileHeader) => {
    let auxComponent = [];
    auxComponent.push(
      <Chip
        key={index}
        className="chipRegla chipRegla2"
        label={cal.text1 + " " + cal.info1 + " " + cal.text2 + " " + cal.info2}
        onDelete={() => deleteCalculation(header, fileHeader, cal)}
      />
    );
    return auxComponent;
  };

  const verifyClient = async (nav, idProc) => {
    if (nav) {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            navigate(nav);
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            );
          }
          // setLoading(false);
        },
        () =>
          swal("error", "Client not authorized to perform this action", "error")
      );
    } else {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            return true;
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            );
            return false;
          }
        },
        () =>
          swal("error", "Client not authorized to perform this action", "error")
      );
    }
  };

  return (
    <div className="container">
      {isLoading ? <Loader /> : null}
      <div>
        <MainBar clientName={clientSelected} />
        <ProgressBar
          nameProcess={nameProcess}
          nextStep={saveCalculationsAndContinue}
          buttonOn={calculationsOn}
          backStep={`/home`}
          backProcess={navigate}
        />
        <div className="fileconverter">
          <div className="fileConverteContainer">
            <div className="fileUpload">
              <div className="titleFileUpload">
                <span> Input Files</span>
                <button
                  className={
                    (processType === 1 &&
                      ((inputHeadersInfo &&
                        inputHeadersInfo.length !== 0 &&
                        Object.entries(inputHeadersInfo).length !== 0) ||
                        files.length === 1)) ||
                    (processType === 2 &&
                      files.length + Object.entries(inputHeadersInfo).length >=
                        10)
                      ? "buttonAddFile buttonAddFileDisabled"
                      : "buttonAddFile"
                  }
                  onClick={() => {
                    handleAddFile();
                  }}
                  disabled={
                    (processType === 1 &&
                      ((inputHeadersInfo &&
                        inputHeadersInfo.length !== 0 &&
                        Object.entries(inputHeadersInfo).length !== 0) ||
                        files.length === 1)) ||
                    (processType === 2 &&
                      files.length + Object.entries(inputHeadersInfo).length >=
                        10)
                  }
                >
                  <img src={subirIcono} alt="" />
                  Add file
                </button>
              </div>

              <div className="uploadContent">
                {fileList()}
                <div className="columnsTable">
                  <p>The Selected Model requires the following variables</p>
                  <table>
                    <thead>
                      <tr className="tableHeader">
                        <td>Columns</td>
                        <td>Required</td>
                      </tr>
                    </thead>
                    <tbody>
                      {modelColumns
                        ? modelColumns.map((column, index) => (
                            <tr key={index} className="required">
                              <td>
                                {column.description[0] +
                                  column.description.toLowerCase().slice(1)}
                              </td>
                              <td>required</td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="calculations">
              <div className="titleCalculations">
                Calculations and Transformations
              </div>
              <Divider />

              <div className="calculationsContent">{calculations()}</div>
            </div>
          </div>
        </div>

        <ModalCSV
          open={openModal}
          data={datos}
          columns={columns}
          handleApproveFile={approveFile}
          handleDenyFile={denyFile}
          handleClose={denyFile}
          selectHeader={selectHeader}
          setFileUpload={setFileUpload}
        />
        <ModalAddCalculation
          inputHeaders={inputHeaders}
          calOptions={calculationOptions}
          open={openModalAddCalculation}
          addCalculation={addCalculation}
          handleClose={() => {
            setOpenModalAddCalculation(false);
          }}
        />
      </div>
    </div>
  );
}

export default InputFiles;
