import { useState } from "react";
import { useParams } from "react-router-dom";
import { _get } from "../../../common/generalRequests";
import closeIcon from "../../../assets/svg/CancelarGris.svg";
import swal from "sweetalert";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import "./modalAddValidation.css";

const CssTextField = styled(TextField)(({ theme }) => ({
  "& label": {},
  "& label.Mui-focused": {
    color: "#FF0081",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "12px",
      borderColor: "#575756",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #FF0081",
    },
  },
}));

const ModalAddValidation = ({
  open,
  handleClose,
  handleAddValidation,
  validation,
}) => {
  const [textLabel, setTextLabel] = useState("N/A");
  const [addInfo, SetAddInfo] = useState(false);
  const [format, setFormat] = useState(false);
  const [newValidation, setNewValidation] = useState(false);
  const [newValidation2, setNewValidation2] = useState(false);
  const [additional, setAdditional] = useState("");
  const [additional2, setAdditional2] = useState(false);
  let { uid } = useParams();

  const verifyClient = async (idProc) => {
    if (idProc) {
      await _get(
        `/verifyClient/${idProc}`,
        (res) => {
          if (res.data.ok) {
            return true;
          } else {
            swal(
              "error",
              "Client not authorized to perform this action",
              "error"
            ).then(() => {});
            return false;
          }
        },
        (error) =>
          swal("error", "Client not authorized to perform this action", "error")
      ).then(() => {});
    }
  };

  const addValidation = (value) => {
    if (verifyClient(uid)) {
      setAdditional("");
      if (value) {
        setNewValidation(value);
        getTextLabel(value);
      } else {
        setTextLabel("N/A");
        SetAddInfo(false);
        setAdditional("");
      }
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const getTextLabel = (value) => {
    if (value.additionalInfo !== "") {
      setTextLabel(value.additionalInfo);
      SetAddInfo(true);
    } else {
      setTextLabel("N/A");
      setAdditional("");
      SetAddInfo(false);
    }
  };

  const addNewValidation = () => {
    if (verifyClient(uid)) {
      if (newValidation && addInfo && additional) {
        handleAddValidation(
          newValidation,
          additional,
          newValidation2,
          additional2,
          format
        );
        handleClose();
      } else if (newValidation && !addInfo) {
        handleAddValidation(newValidation);
        handleClose();
      }
    } else {
      swal(
        "error",
        "Client not authorized to perform this action",
        "error"
      ).then(() => {});
    }
  };

  const closeModal = () => {
    setNewValidation(false);
    setAdditional("");
    SetAddInfo(false);
    setTextLabel("N/A");
    handleClose();
  };

  return (
    <Modal open={open} onClose={closeModal} className="modalAddValidation">
      <div className="paperAddValidation">
        <img
          src={closeIcon}
          alt="close"
          onClick={() => {
            closeModal();
          }}
          className="closeIcon"
        />
        <div className="titleModal">Add new validation</div>
        <div className="inputContainer">
          <Autocomplete
            className="formControlAddValidation"
            options={validation}
            getOptionLabel={(option) => option.description}
            onChange={(event, value) => addValidation(value)}
            disableClearable={true}
            renderInput={(params) => (
              <CssTextField {...params} label="Validation" variant="outlined" />
            )}
          />
          <CssTextField
            disabled={!addInfo}
            onChange={(event) => {
              setAdditional(event.target.value);
            }}
            type="text"
            defaultValue=" "
            value={additional}
            variant="outlined"
            label={textLabel}
            className="formControlAddValidation"
            InputLabelProps={
              addInfo
                ? {
                    shrink: true,
                  }
                : {
                    shrink: false,
                  }
            }
          />
        </div>

        <div className="buttonsContainer">
          <button className="buttonModal" onClick={() => addNewValidation()}>
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddValidation;
